// CW Colors
$color-black: #000;
$color-white: #FFF;

$color-randolph-burgundy: #740909;
$color-raleigh-red: #A82020;

$color-geddy-blue: #264461;
$color-wythe-green: #4D5944;

$color-ludwell-black: #1D1C1A;
$color-reid-gray: #858178;
$color-nicholson-gray: #C7BFAC;
$color-franklin-white: #EFEBE5;
$color-input-text: #6B675E;

$color-carter-peach: #EBB799;
$color-braken-chartreuse: #B9AF77;
$color-york-yellow: #E3C268;
$color-charlton-robins-egg: #A3CCBD;

$color-focus: $color-ludwell-black;
$color-focus-light: $color-white;

$color-notification-red: #CE0000;

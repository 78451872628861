.cc--donate-promo {
  background-color: $color-ludwell-black;

  .c--donate-promo {
    @include maxwidth;
    color: $color-white;

    .text-image-container {
      display: flex;
      flex-direction: column-reverse;

      @include mq($bp768) {
        flex-direction: row;
      }

      .text-container {
        @include padding(rem(25) cols(4) rem(35));

        @include mq($bp768) {
          @include padding(rem(50) cols(2) rem(50) null);
          flex: 0 0 cols(24);
          align-self: center;
        }

        @include mq($bp1024) {
          @include padding(null cols(4) null null);
        }

        .f--description {
          @include margin(rem(10) null null);

          @include mq($bp768) {
            @include margin(rem(5) null null);
          }

          p {
            @include font-size(15, 25);

            @include mq($bp768) {
              @include font-size(16, 28);
              letter-spacing: rem(0.25);
            }

            a {
              color: $color-white;
            }
          }
        }

        .donate-cta {
          @include margin(rem(15) null null);

          @include mq($bp768) {
            @include margin(rem(20) null null);
          }

          a {
            @include button(
              $outline: true,
              $text-color: $color-white,
              $outline-color: $color-york-yellow,
              $focus-color: $color-focus-light
            );
          }
        }

        .links-container {
          @include margin(rem(15) null null);

          @include mq($bp768) {
            @include margin(rem(30) null null);
          }

          .f--link {
            a {
              @include button-cta(
                $text-color: $color-white,
                $hover-color: $color-york-yellow,
                $outline-color: $color-white,
                $svg-color: $color-york-yellow
              );
            }
          }
        }
      }


      .image-container {
        position: relative;

        @include mq($bp768) {
          flex: 0 0 cols(24);
        }

        .f--image:not(.svg) {
          position: relative;
          height: 100%;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color-ludwell-black, 0.5);
          }

          img {
            height: 100%;
            object-fit: cover;
            aspect-ratio: 3 / 2;
          }
        }

        .f--image.svg {
          @include centered;
          @include padding(null cols(4));
          z-index: 1;
          width: 100%;

          @include mq($bp1024) {
            @include padding(null cols(3, 24));
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }

  // light variation
  &.light {
    background-color: $color-franklin-white;

    .c--donate-promo {
      color: $color-ludwell-black;

      .text-container {

        .f--description {
          p {
            a {
              color: $color-raleigh-red;
            }
          }
        }
        .donate-cta {
          a {
            @include button(
              $outline: true
            );
          }
        }

        .links-container {
          .f--link {
            a {
              @include button-cta;
            }
          }
        }
      }
    }
  }
}

::selection {
  background-color: $color-black;
  color: $color-white;
}

%rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @extend %font-source-serif-regular;
    @include margin(0 null rem(10));
    color: $color-ludwell-black;

    &:not(:first-child) {
      @include margin(rem(30) null null);

      @include mq($bp768) {
        @include margin(rem(50) null null);
      }
    }
  }

  h1 {
    @extend %font-source-serif-semibold;
    @include font-size(40, 44);

    @include mq($bp768) {
      @include font-size(72, 72);
    }
  }

  h2 {
    @include font-size(40, 48);

    @include mq($bp768) {
      @include font-size(60, 70);
    }
  }

  h3 {
    @include font-size(30, 40);

    @include mq($bp768) {
      @include font-size(36, 46);
    }
  }

  h4 {
    @include font-size(22, 32);

    @include mq($bp768) {
      @include font-size(26, 36);
    }
  }

  h5 {
    @include font-size(18, 26);
    color: $color-black;

    @include mq($bp768) {
      @include font-size(22, 32);
    }
  }

  h6 {
    @include font-size(16, 26);
    color: $color-black;

    @include mq($bp768) {
      @include font-size(18, 28);
    }
  }

  p {
    @extend %font-source-sans-regular;
    @include font-size(16, 30);
    color: $color-black;

    @include mq($bp768) {
      @include font-size(18, 34);
    }

    &:not(:last-child) {
      @include margin(null null rem(10));
    }

    strong,
    b {
      @extend %font-source-sans-semibold;
    }

    i,
    em {
      font-style: italic;
    }

    &.large {
      @extend %font-source-serif-regular;
      @include font-size(18, 34);

      @include mq($bp768) {
        @include font-size(22, 44);
      }

      strong,
      b {
        @extend %font-source-sans-semibold;
      }
    }

    &.caption {
      @extend %font-source-sans-regular;
      @include margin(null null rem(40));
      @include font-size(14, 22);

      @include mq($bp1024) {
        @include margin(null null rem(60));
      }
    }
  }

  a {
    color: $color-raleigh-red;
    text-decoration: underline;

    @include hover {
      text-decoration: none;
    }

    &.cta-link {
      @include margin(rem(5) null null);
      @include button($outline: true);
    }
  }

  ul,
  ol {
    @extend %font-source-sans-regular;
    @include font-size(16, 30);
    overflow: hidden;

    @include mq($bp768) {
      @include font-size(18, 34);
    }

    ::marker {
      @extend %font-source-serif-bold;
      color: $color-raleigh-red;
    }

    li {
      @include clearfix;
      @include padding(null null null rem(10));
      color: $color-black;

      &:not(:last-child) {
        @include margin(null null rem(10));
      }
    }
  }

  ul {
    > li {

      > ul {

        ::marker {
          @extend %font-source-serif-bold;
          content: '\0025B8';
          color: $color-raleigh-red;
        }

        > li {
          > ul {
            ::marker {
              content: unset;
            }
          }
        }
      }
    }
  }

  small {
    @extend %font-source-sans-regular;
    @include font-size(14, 24);
    color: $color-black;
  }

  pre {
    white-space: pre-wrap;
  }

  blockquote {
    @extend %font-source-sans-regular;
    @include margin(rem(35) 0);
    @include padding(null null null cols(2, 20));
    @include font-size(16, 30);
    position: relative;
    border-left: rem(1) solid $color-york-yellow;
    color: $color-black;

    @include mq($bp768) {
      @include margin(rem(50) null);
      @include padding(null null null cols(3, 40));
      @include font-size(18, 34);
    }

    @include mq($bp1024) {
      @include padding(null null null cols(2, 30));
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: rem(-4.5);
      width: rem(8);
      height: rem(1);
      background-color: $color-york-yellow;
    }

    &::after {
      top: auto;
      bottom: 0;
    }

    p {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  figure {
    @include margin(rem(35) 0);

    @include mq($bp768) {
      @include margin(rem(50) 0);
    }
  }

  figure,
  img {
    max-width: 100%;
    height: auto;

    figcaption {
      @extend %font-source-sans-regular;
      @include font-size(12, 22);
      @include margin(rem(10) null null);
      color: $color-black;

      @include mq($bp768) {
        @include font-size(14, 24);
      }

      p {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
      }
    }

    &.alignnone {
      @include margin(rem(35) 0);

      @include mq($bp768) {
        @include margin(rem(50) 0);
      }
    }

    &.align-center,
    &.aligncenter {
      @include margin(rem(35) 0);

      @include mq($bp768) {
        @include margin(rem(50) 0);
      }
    }

    &.align-right,
    &.alignright {
      @include margin(rem(35) 0);

      @include mq($bp768) {
        @include margin(rem(50) null rem(50) cols(2, 42));
        float: right;
      }

      @include mq($bp1024) {
        @include margin(null null null cols(2, 30));
      }
    }

    &.align-left,
    &.alignleft {
      @include margin(rem(35) 0);

      @include mq($bp768) {
        @include margin(rem(50) cols(2, 42) rem(50) null);
        float: left;
      }

      @include mq($bp1024) {
        @include margin(null cols(2, 30) null null);
      }
    }

    &.align-right,
    &.alignright,
    &.align-left,
    &.alignleft {
      @include mq($bp768max) {
        width: 100% !important;
      }

      &:not([style*='width']):not([width]) {
        width: 100% !important;

        @include mq($bp768) {
          width: cols(19, 42) !important;
          min-width: rem(260);
        }

        @include mq($bp1024) {
          width: cols(12, 30) !important;
          min-width: rem(300);
        }
      }

      &[width] {
        @include mq($bp768) {
          width: auto;
        }
      }
    }
  }

  .wp-video,
  .video-embed {
    @include margin(rem(35) null);
    line-height: 1.2858;
    aspect-ratio: 16 / 9;

    @include mq($bp768) {
      @include margin(rem(50) 0);
    }

    iframe {
      width: 100%;
      max-width: 100%;
      height: 100%;
      vertical-align: top;
    }

    @supports not (aspect-ratio: 16 / 9) {
      position: relative;
      height: 0;
      padding-bottom: 56.25%;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  table:not(.gsc-search-box):not(.gsc-input):not(.gsc-above-wrapper-area-container) {
    width: 100%;
    overflow: auto;
    table-layout: fixed;
    border-spacing: 0;
    border: 0;
    word-break: normal;

    caption {
      @extend %font-source-sans-regular;
      @include margin(rem(15) null);
      @include font-size(14, 22);
      color: $color-black;
      text-align: left;
    }

    thead {
      background-color: $color-reid-gray;

      th {
        @extend %font-source-sans-regular;
        @include padding(rem(16) rem(15) rem(20));
        @include font-size(12, 14);
        border: 0;
        background-color: $color-nicholson-gray;
        color: $color-ludwell-black;
        letter-spacing: rem(1.75);
        text-align: left;
        text-transform: uppercase;

        &:not(:first-child) {
          border-left: rem(1) solid $color-reid-gray;
        }

        @include mq($bp768) {
          @include font-size(14, 18);
        }

        @include mq($bp1024) {
          @include padding(null rem(25));
        }
      }
    }

    tbody {
      th,
      td {
        @extend %font-source-sans-regular;
        @include padding(rem(14) rem(15));
        @include font-size(14, 22);
        border: 0;

        color: $color-black;
        font-weight: normal;

        &:not(:first-child) {
          border-left: rem(1) solid #CCC;
        }

        @include mq($bp1024) {
          @include padding(null rem(25));
        }

        strong {
          @extend %font-source-sans-semibold;
        }

        a {
          @extend %font-source-sans-regular;
          color: $color-black;
          font-size: inherit;
          line-height: inherit;
          text-decoration: underline;
        }

        p {
          font-size: inherit;
          line-height: inherit;
        }

        ul,
        ol {
          &,
          li {
            font-size: inherit;
            line-height: inherit;
          }
        }
      }

      tr {
        &:nth-child(odd) {
          background: $color-white;
        }

        &:nth-child(even) {
          background: $color-franklin-white;
        }

        &:last-child {
          td,
          th {
            width: 100%;
          }
        }
      }
    }

    tr {
      background: none;
    }
  }

  .responsive-table {
    @include margin(rem(35) 0);
    width: 100%;
    overflow-x: auto;

    @include mq($bp768) {
      @include margin(rem(50) 0);
    }

    &:last-child {
      @include margin(null null 0);
    }

    table {
      width: auto;
      min-width: rem(615);
    }
  }
}

div,
.cc--rich-text {
  .f--wysiwyg {
    @extend %rich-text;
  }
}

.cc--notification-banner {
  display: block;
  background-color: $color-nicholson-gray;
  color: $color-ludwell-black;

  .c--notification-banner {
    @include maxwidth;

    .notification-banner-inner {
      @include padding(rem(25) cols(4));

      @include mq($bp1024) {
        @include padding(rem(30) cols(7) rem(15) cols(2));
        display: flex;
        justify-content: space-between;
      }
    }

    .left {
      @include mq($bp1024) {
        align-content: center;
        width: cols(12, 39);
      }
    }

    .right {
      @include margin(rem(20) null null null);
      @include padding(rem(20) null null null);
      border-top: rem(1) solid rgba($color-ludwell-black, 0.2);

      @include mq($bp768) {
        @include margin(rem(15) null null null);
        @include padding(rem(15) null null null);
      }

      @include mq($bp1024) {
        @include margin(0 null null null);
        @include padding(0 null null null);
        position: relative;
        align-content: center;
        width: cols(24, 39);
        border-top: 0;
      }

      &::before {
        @include mq($bp1024) {
          content: '';
          position: absolute;
          top: 0;
          left: cols(-2, 24);
          width: rem(1);
          height: 100%;
          background-color: rgba($color-ludwell-black, 0.2);
        }
      }
    }

    .notification-title {
      @extend %font-source-serif-regular;
      @include font-size(18, 26);
      color: inherit;

      @include mq($bp768) {
        @include font-size(22, 32);
      }
    }

    .notification-timestamp {
      @extend %font-source-sans-regular;
      @include margin(rem(5) null null);
      @include font-size(12, 16);
      color: inherit;
      letter-spacing: rem(0.25);

      span,
      .f--date {
        display: inline-block;
      }
    }

    .f--wysiwyg {
      @include mq($bp1024) {
        flex-grow: 1;
        margin-top: 0;
      }

      p {
        @include font-size(15, 25);
        color: inherit;

        @include mq($bp768) {
          @include font-size(16, 28);
          letter-spacing: rem(0.25);
        }

        a {
          color: inherit;
          text-decoration: underline;

          @include hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  &.emergency {
    background-color: $color-notification-red;
    color: $color-white;
  }

  body.main-menu-open &,
  body.mobile-menu-open &,
  .scroll-up & {
    display: none;
  }
}

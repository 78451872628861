.f--input-submit {
  button {
    @include button;
    border: 0;
    color: $color-white;
    cursor: pointer;
  }

  &.icon-only {
    span {
      @include visuallyhidden;
    }
  }
}

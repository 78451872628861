.cc--explore-listing-filter {
  @include maxwidth;
  position: relative;

  .c--explore-listing-filter {
    @include padding(rem(35) cols(4) null);

    @include mq($bp768) {
      @include padding(rem(50) null null);
    }

    @include mq($bp1024) {
      @include padding(null cols(2) null);
    }

    .text-image-container {
      @include margin(null null rem(25));

      @include mq($bp768) {
        @include margin(null null rem(35));
      }

      @include mq($bp1024) {
        @include padding(null cols(7, 44) null)
      }

      .f--description {
        text-align: center;

        p {
          @include font-size(18, 34);
          letter-spacing: rem(0.25);

          a {
            color: $color-raleigh-red;

            @include hover {
              text-decoration: underline;
            }
          }
        }
      }

      .f--image {
        position: absolute;
        z-index: -1;
        top: rem(10);
        right: cols(-4);
        width: rem(200);
        height: rem(200);
  
        @include mq($bp768) {
          top: rem(25);
          right: cols(-7);
          width: rem(400);
          height: rem(400);
        }
  
        @include mq($bp1024) {
          right: cols(-3);
        }
      }
    }

    form {
      position: relative;

      @include mq($bp1024) {
        @include padding(null null rem(25));
        display: flex;
        column-gap: cols(4, 44);
      }

      &::before {
        @include mq($bp1024) {
          @include centered;
          content: '';
          width: rem(1);
          height: 100%;
          background-color: $color-nicholson-gray;
        }
      }

      fieldset {
        appearance: none;
        position: relative;
        margin: 0;
        padding: 0;
        border: 0;

        @include mq($bp768) {
          @include margin(rem(25) null null);
          @include padding(rem(25) null);
          display: flex;
          column-gap: cols(4, 44);
          border-top: rem(1) solid $color-nicholson-gray;
        }

        @include mq($bp1024) {
          @include margin(0 null null);
          @include padding(null null 0);
          flex: 0 0 cols(20, 44);
          border-top: 0;
        }

        &::before {
          @include mq($bp768) {
            @include centered;
            content: '';
            width: rem(1);
            height: 100%;
            background-color: $color-nicholson-gray;
          }

          @include mq($bp1024) {
            top: 0;
            height: calc(100% + #{rem(25)});
            transform: translate(-50%, 0);
          }
        }
      }

      .f--input-select {
        @include mq($bp1024) {
          display: block;
          position: relative;
          flex: 0 0 cols(20, 44);
        }

        label {
          @extend %font-source-sans-regular;
          @include font-size(12, 14);
          @include margin(null null rem(5));
          display: block;
          letter-spacing: rem(1.5);
          text-transform: uppercase;

          @include mq($bp768) {
            @include font-size(14, 18);
          }
        }

        select {
          height: rem(50);
          color: $color-ludwell-black;

          &:focus {
            + svg {
              z-index: 99;
            }
          }
        }
      }

      .f--input-checkbox {
        display: flex;
        align-items: center;

        @include mq($bp768) {
          flex: 0 0 calc(50% - #{cols(2, 40)});
        }

        &:first-child {
          @include margin(rem(20) null null);

          @include mq($bp768) {
            @include margin(0 null null);
          }

          label {
            display: inline-flex;
            align-items: center;
            column-gap: rem(10);

            &::before {
              content: '';
              display: inline-block;
              width: rem(25);
              height: rem(25);
              background-image: url('../images/icons/icon-accessible.svg');
              background-size: cover;
            }
          }
        }

        &:not(:last-child) {
          @include margin(null null rem(15));

          @include mq($bp768) {
            @include margin(null null rem(0));
          }
        }

        input:not(:checked) {
          border-color: $color-nicholson-gray;
          background-color: $color-franklin-white;

          &::after {
            opacity: 0;
          }
        }
      }
    }

    .results-header {
      @include margin(rem(20) null null);
      @include padding(rem(15) null null);
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      border-top: solid rem(1) $color-nicholson-gray;

      @include mq($bp768) {
        @include margin(0 null null);
        display: flex;
        align-items: center;
      }

      .results-count {
        @extend %font-source-sans-bold;
        @include font-size(14, 22);
      }

      > button {
        @extend %font-source-sans-regular;
        @include font-size(14, 22);
        @include padding(0);
        border: 0;
        border-radius: 0;
        background: none;
        color: $color-black;
        text-decoration: underline;

        @include mq($bp1024) {
          @include line-height(14, 14);
        }

        @include hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

.cc--events-listing-filter {
  @include maxwidth;
  position: relative;

  .c--events-listing-filter {
    form {
      fieldset {
        @include padding(0 cols(4) rem(25));
        margin: 0;
        border: 0;

        @include mq($bp768) {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: cols(2, 40);
        }

        @include mq($bp1024) {
          @include padding(null cols(3) null);
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          column-gap: unset;
        }

        &:first-child {
          @include margin(null null rem(25));
          @include padding(rem(35) null rem(25));
          background-color: $color-franklin-white;

          @include mq($bp1024) {
            @include margin(null null rem(20));
            @include padding(rem(25) null rem(40));
            display: grid;
            grid-template-columns: cols(17, 42) cols(12, 42) cols(12, 42);
            column-gap: rem(10);
          }

          .fi--form-item {
            &:not(:last-child) {
              @include mq($bp768max) {
                @include margin(null null rem(15));
              }
            }

            label {
              @extend %font-source-sans-regular;
              @include font-size(12, 14);
              @include margin(null null rem(5));
              display: block;
              letter-spacing: rem(1.5);
              text-transform: uppercase;

              @include mq($bp768) {
                @include font-size(14, 18);
              }
            }

            input {
              background-color: $color-white;
            }
          }
        }

        h3 {
          @extend %font-source-serif-regular-italic;
          @include font-size(22, 26);
          @include margin(null null rem(15));
          letter-spacing: rem(8.5);
          text-transform: uppercase;

          @include mq($bp768) {
            @include font-size(26, 30);
            grid-column: span 2;
            letter-spacing: rem(10);
          }

          @include mq($bp1024) {
            @include margin(null cols(1, 17) 0 null);
            @include padding(null null rem(10));
            grid-column: unset;
          }
        }

        .f--input-select {
          display: none;

          @include mq($bp1024) {
            @include margin(null calc(#{cols(1, 42)} + #{rem(6)}) null null);
            @include padding(null cols(1, 42) null null);
            display: block;
            position: relative;
            flex: 0 0 cols(13, 42);
          }

          &::before {
            content: "";
            position: absolute;
            right: 0;
            bottom: rem(-25);
            width: rem(1);
            height: calc(100% + #{rem(30)});
            background-color: $color-nicholson-gray;
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            z-index: 1;
            right: rem(-11);
            bottom: rem(-8);
            width: rem(12);
            height: rem(65);
            background-image: url("../images/interface/interface-insetarrow-right.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            filter: drop-shadow(rem(1) 0 0 $color-nicholson-gray);
          }

          label {
            @extend %font-source-sans-regular;
            @include font-size(14, 18);
            @include margin(null null rem(5));
            display: block;
            letter-spacing: rem(1.5);
            text-transform: uppercase;
          }

          select {
            height: rem(50);
            background-color: $color-york-yellow;

            &:focus + svg {
              z-index: 99;
            }
          }
        }

        .cc--multiselect-dropdown {
          @include mq($bp1024) {
            flex: 0 0 cols(9, 42);
          }

          &:not(:last-child) {
            @include margin(null null rem(15));

            @include mq($bp768) {
              @include margin(null null rem(25));
            }

            @include mq($bp1024) {
              @include margin(null rem(10) 0 null);
            }
          }

          .filter-option {
            background-color: $color-franklin-white;

            span {
              @extend %font-source-sans-bold;
              color: $color-randolph-burgundy;
            }
          }
        }
      }
    }

    .results-header {
      @include margin(null cols(4) null);
      @include padding(rem(15) null null);
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      border-top: solid rem(1) $color-nicholson-gray;

      @include mq($bp768) {
        display: flex;
        align-items: center;
        column-gap: rem(35);
      }

      @include mq($bp1024) {
        @include margin(null cols(3) null);
      }

      .results-count {
        @extend %font-source-sans-bold;
        @include font-size(14, 22);
      }

      > button {
        @extend %font-source-sans-regular;
        @include font-size(14, 22);
        @include padding(0);
        border: 0;
        border-radius: 0;
        background: none;
        color: $color-black;
        text-decoration: underline;

        @include mq($bp1024) {
          @include line-height(14, 14);
        }

        @include hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

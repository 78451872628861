.cc--featured-block-split-carousel {
  @include maxwidth;
  position: relative;
  overflow: hidden;

  .c--featured-block-split-carousel {
    @include padding(null null rem(35));

    @include mq($bp768) {
      @include padding(null cols(4) rem(50));
    }

    @include mq($bp1024) {
      @include padding(null 0 0 null);
    }

    .mySwiper {
      overflow: visible;

      .swiper-wrapper {
        align-items: stretch;

        .swiper-slide {
          position: relative;
          align-self: stretch;
          height: auto;
          transition: opacity $transition-duration-default $transition-easing-default;
          opacity: 0 !important;

          .inner-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }

          .decoration-figure {
            display: none;
          }

          .svg-woodcut {
            position: absolute;
            z-index: -1;
            top: calc(100vw - #{rem(40)});
            left: 0;
            width: rem(200);
            height: rem(200);

            @include mq($bp768) {
              top: calc(83.333vw - #{rem(85)});
              left: rem(40);
              width: rem(350);
              height: rem(350);
            }

            @include mq($bp1024) {
              top: rem(-50);
              left: cols(-4);
            }
          }

          &.swiper-slide-active {
            opacity: 1 !important;

            .decoration-figure {
              @extend %font-source-serif-semibold-italic;
              @include font-size(250, 313);
              display: flex;
              position: absolute;
              z-index: -1;
              top: calc(100vw - #{rem(40)});
              left: 0;
              align-items: center;
              color: $color-franklin-white;

              @include mq($bp768) {
                @include font-size(350, 438);
                top: calc(83.333vw - #{rem(85)});
                left: rem(40);
              }

              @include mq($bp1024) {
                top: rem(-50);
                left: cols(-4);
                align-items: flex-start;
              }

              &::before {
                @extend %font-source-serif-semibold-italic;
                @include font-size(80, 100);
                content: "fig.";

                @include mq($bp768) {
                  display: none;
                }

                @include mq($bp1024) {
                  @include font-size(100, 126);
                  display: block;
                  position: relative;
                  top: rem(85);
                }
              }
            }
          }

          &.has-link {
            .image-container {
              @include mq($bp1024) {
                &::after {
                  @include centered(x);
                  content: "";
                  bottom: 0;
                  width: 100%;
                  height: rem(225);
                  background: linear-gradient(0deg, rgba($color-black, 1) 0%, rgba($color-black, 0) 50%);
                }
              }
            }
          }
        }

        &:not(:has(.f--link)) {
          .swiper-slide {
            @include padding(null null rem(55));

            @include mq($bp1024) {
              @include padding(null null 0);
            }

            + .navigation-wrapper {
              bottom: 0;
            }
          }
        }

        &:has(.f--link) {
          .swiper-slide:not(.has-link) {
            @include padding(null null rem(160));

            @include mq($bp768) {
              @include padding(null null rem(175));
            }

            @include mq($bp1024) {
              @include padding(null null 0);
            }
          }
        }
      }

      .text-image-container {
        @include mq($bp1024) {
          display: flex;
          flex-direction: row-reverse;
          align-items: flex-start;
        }
      }

      .text-container {
        @include padding(rem(25) cols(4) null);

        @include mq($bp768) {
          @include padding(rem(40) 0 null);
        }

        @include mq($bp1024) {
          @include padding(rem(75) cols(4, 44) 0 null);
          position: relative;
          flex: 0 0 #{cols(20, 44)};
        }

        &::before {
          @include mq($bp1024) {
            content: "";
            position: absolute;
            top: rem(142);
            left: cols(-4, 20);
            width: cols(3, 20);
            height: rem(1);
            background-color: $color-york-yellow;
          }
        }

        &::after {
          @include mq($bp1024) {
            content: "";
            position: absolute;
            top: rem(142);
            right: cols(1, 20);
            width: cols(2, 20);
            height: rem(1);
            background-color: $color-york-yellow;
          }
        }

        .f--eyebrow {
          span {
            display: block
          }
        }

        .f--cta-title,
        .f--section-title {
          @include mq($bp768) {
            @include margin(null null rem(5));
          }

          h2,
          h3 {
            @extend %font-source-serif-regular;
            @include font-size(40, 48);
            color: $color-ludwell-black;

            @include mq($bp768) {
              @include font-size(60, 70);
            }
          }
        }

        .f--description {
          p {
            @include font-size(15, 25);

            @include mq($bp768) {
              @include font-size(16, 28);
              letter-spacing: rem(0.25);
            }

            a {
              color: $color-raleigh-red;
              font-weight: inherit;
              text-decoration: underline;

              @include hover {
                color: $color-ludwell-black;
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .f--link {
      @include margin(rem(80) auto null);
      @include padding(rem(45) null null);
      position: relative;
      width: calc(100% - #{cols(8)});
      height: rem(80);

      @include mq($bp768) {
        @include margin(rem(95) 0 null);
        @include padding(rem(60) null null);
        width: 100%;
      }

      @include mq($bp1024) {
        @include margin(0 null null);
        @include padding(0 null null);
        position: absolute;
        bottom: rem(20);
        left: cols(21, 44);
        height: auto;
      }

      &::before {
        @extend %font-source-sans-regular;
        @include font-size(12, 16);
        @include padding(rem(14.5) null null);
        content: "More to Explore:";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: rem(1) solid $color-nicholson-gray;
        color: $color-ludwell-black;
        letter-spacing: rem(1.5);
        text-decoration: none;
        text-transform: uppercase;

        @include mq($bp768) {
          @include font-size(14, 18);
          letter-spacing: rem(1.75);
        }

        @include mq($bp1024) {
          display: none;
        }
      }

      a {
        @include button-cta;
        @include line-height(14, 16);

        @include mq($bp1024) {
          @include button-cta($color-white, $color-york-yellow, $color-white, $color-york-yellow);
        }
      }
    }

    .image-container {
      position: relative;

      @include mq($bp1024) {
        flex: 0 0 #{cols(24, 44)};
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: cols(2);
        width: rem(65);
        height: rem(12);
        background-image: url("/images/interface/interface-insetarrow-up.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include mq($bp768) {
          bottom: 0;
          left: 0;
          width: rem(135);
          height: rem(25);
        }

        @include mq($bp1024) {
          top: rem(75);
          bottom: auto;
          left: 0;
          width: rem(25);
          height: rem(135);
          background-image: url("/images/interface/interface-insetarrow-right.svg");
        }
      }
    }

    .navigation-wrapper {
      @include centered(x);
      @include padding(null cols(4));
      display: flex;
      z-index: 1;
      bottom: rem(105);
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @include mq($bp768) {
        @include padding(null 0);
        bottom: rem(111.5);
      }

      @include mq($bp1024) {
        bottom: rem(50);
        left: 0;
        width: cols(16, 44);
        transform: none;
      }

      .swiper-buttons {
        @include margin(null null null cols(-0.5, 40));
        display: flex;
        position: relative;
        z-index: 1;
        align-items: center;
        justify-content: space-between;
        width: rem(90);

        &::before {
          @include centered(x);
          content: "";
          top: rem(-0.5);
          width: rem(1);
          height: calc(100% + #{rem(1)});
          background-color: $color-nicholson-gray;
        }

        .swiper-button-prev,
        .swiper-button-next {
          display: block;
          position: relative;
          top: 0;
          right: auto;
          left: auto;
          width: rem(30);
          height: rem(30);
          margin: 0;
          padding: 0;
          border: 0;
          background: transparent;

          @include mq($bp768) {
            width: rem(35);
            height: rem(35);
          }

          &::after {
            content: "";
            display: block;
          }

          &.swiper-button-disabled {
            opacity: 1;

            svg path {
              fill: $color-nicholson-gray;
            }
          }

          svg path {
            transition: fill $transition-duration-default $transition-easing-default;
            fill: $color-raleigh-red;
          }

          &:hover,
          &:focus-visible {
            svg path {
              fill: $color-randolph-burgundy;
            }
          }
        }

        &.is-first {
          @include margin(null null null 0);
          width: auto;

          &::before {
            display: none;
          }

          .swiper-button-prev {
            display: none;
          }

          .swiper-button-next {
            display: flex;
            align-items: center;
            width: auto;

            svg {
              width: rem(15);
              height: rem(15);

              @include mq($bp768) {
                width: rem(35);
                height: rem(35);
              }
            }

            &::before {
              @extend %font-source-serif-semibold-italic;
              @include font-size(14, 16);
              @include margin(null rem(5) null null);
              content: "Keep Going";
              color: $color-ludwell-black;
              letter-spacing: rem(1.75);
              text-transform: uppercase !important;

              @include mq($bp768) {
                @include font-size(18, 22);
                letter-spacing: rem(2.5);
              }
            }

            @include hover {
              &::before {
                color: $color-raleigh-red;
                text-decoration: underline;
              }
            }
          }
        }
      }

      .swiper-pagination {
        display: flex;
        position: relative;
        bottom: auto;
        align-items: center;
        width: auto;
        column-gap: rem(15);

        .swiper-pagination-bullet {
          width: rem(5);
          height: rem(5);
          margin: 0;
          opacity: 1;
          background: $color-nicholson-gray;

          &.swiper-pagination-bullet-active {
            background: $color-raleigh-red;
          }
        }
      }
    }
  }
}

.cc--footer {
  background-color: $color-ludwell-black;
  color: $color-white;

  .c--footer {
    @include maxwidth;

    .inner-wrapper {
      @include padding(null cols(4));
      @include component-padding;

      @include mq($bp768) {
        @include padding(null null);
      }

      @include mq($bp1024) {
        @include padding(null cols(2));
      }

      .footer-logo {
        .f--image {
          max-width: rem(300);

          @include mq($bp768) {
            max-width: rem(350);
          }
        }

        svg {
          max-width: 100%;
        }
      }

      .footer-menu-sections {
        @include margin(rem(25) null null null);

        @include mq($bp768) {
          @include margin(rem(25) null null null);
          display: flex;
          flex-wrap: wrap;
        }

        @include mq($bp1024) {
          @include margin(rem(25) null null null);
        }

        > div {
          @include mq($bp768) {
            flex: 0 0 cols(20, 40);
          }

          @include mq($bp768-1024) {
            @include padding(rem(10) null);
          }

          @include mq($bp1024) {
            @include padding(rem(10) null rem(35) null);
            flex: 0 0 cols(10, 44);
          }

          &:not(:last-child) {

            @include mq($bp768max) {
              @include margin(null null rem(25) null);
              @include padding(null null rem(25) null);
              border-bottom: rem(1) solid rgba($color-white, 0.3);
            }

            @include mq($bp1024) {
              border-right: rem(1) solid rgba($color-white, 0.3);
            }
          }

          &:not(:nth-child(2n+2)) {
            @include mq($bp768-1024) {
              border-right: rem(1) solid rgba($color-white, 0.3);
            }
          }

          &:nth-child(n+3) {
            @include mq($bp768-1024) {
              border-top: rem(1) solid rgba($color-white, 0.3);
            }
          }

          &.footer-menu {
            @include mq($bp768) {
              @include padding(null cols(2, 40) rem(35) null);
            }

            @include mq($bp1024) {
              @include padding(null cols(1, 44) null null);
            }
          }

          &.footer-contact {
            @include mq($bp768) {
              @include padding(rem(16) null rem(35) cols(2, 40));
            }

            span {
              @extend %font-source-serif-semibold-italic;
              @include margin(null null rem(15) null);
              @include font-size(14, 18);
              display: block;
              letter-spacing: rem(3);
              text-transform: uppercase;
            }

            .f--description {
              p {
                @include font-size(14, 22);
              }
            }
          }

          &.footer-quick-links {
            @include mq($bp768) {
              @include padding(rem(35) cols(2, 40) rem(35) null);
            }

            @include mq($bp1024) {
              @include padding(rem(16) null null null);
            }

            span {
              @extend %font-source-serif-semibold-italic;
              @include margin(null null rem(15) null);
              @include font-size(14, 18);
              display: block;
              letter-spacing: rem(3);
              text-transform: uppercase;
            }
          }

          &.footer-contact,
          &.footer-quick-links {
            @include mq($bp1024) {
              @include padding(null cols(2, 44));
            }
          }

          &.footer-connect {
            @include mq($bp768) {
              @include padding(rem(35) null null cols(2, 40));
            }

            @include mq($bp1024) {
              @include padding(rem(16) 0 null cols(2, 44));
              flex: 0 0 cols(14, 44);
            }

            span {
              @extend %font-source-serif-semibold-italic;
              @include margin(null null rem(15) null);
              @include font-size(14, 18);
              display: block;
              letter-spacing: rem(3);
              text-transform: uppercase;
            }

            .links-container {
              @include margin(rem(15) null null);

              a {
                width: 100%;

                @include button(
                  $outline: true,
                  $text-color: $color-white,
                  $outline-color: $color-york-yellow,
                  $focus-color: $color-focus-light
                );
              }
            }
          }
        }
      }

      .footer-utility {
        @include margin(rem(35) null null null);
        @include padding(rem(25) null null null);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-top: rem(1) solid rgba($color-white, 0.3);

        @include mq($bp768) {
          @include margin(0 null null null);
        }

        @include mq($bp1024) {
          @include margin(0 null null null);
        }

        .footer-copyright {

          @include mq($bp768max) {
            @include margin(null null rem(15) null);
            flex: 0 0 100%;
          }

          @include mq($bp768) {
            @include margin(null rem(20) null null);
          }

          p {
            @extend %font-source-sans-regular;
            @include font-size(14, 20);
            color: $color-white;
          }
        }

        .footer-utility-menu {
          @include mq($bp768max) {
            flex: 0 0 50%;
          }

          @include mq($bp768) {
            @include margin(null rem(50) null null);
          }
        }

        .footer-social {
          @include mq($bp768max) {
            flex: 0 0 50%;
          }

          ul {
            @include no-bullet;
            display: flex;
            column-gap: rem(15);

            @include mq($bp768max) {
              justify-content: flex-end;
            }

            li {
              svg {
                width: rem(25);
                height: rem(25);

                path {
                  fill: $color-white;
                }
              }

              @include hover {
                svg path {
                  fill: $color-york-yellow;
                }
              }

              a {
                @include vertical-align-flex;

                &:focus {
                  @include outline($color: $color-focus-light);
                }
              }
            }
          }
        }

        .footer-logos {
          display: flex;
          column-gap: rem(30);

          @include mq($bp768max) {
            @include margin(rem(35) null null null);
          }

          @include mq($bp768-1024) {
            @include margin(rem(25) null null null);
            flex: 0 0 100%;
          }

          @include mq($bp768) {
            column-gap: rem(25);
          }

          @include mq($bp1024) {
            @include margin(null null null auto);
          }

          a {
            &:focus {
              @include outline($color: $color-focus-light);
            }
          }
        }
      }
    }
  }
}

.cc--cascading-features-short {
  @include maxwidth;
  position: relative;
  overflow: hidden;

  .c--cascading-features-short {
    @include padding(rem(35) null);

    @include mq($bp768) {
      @include padding(rem(75) null);
    }

    @include mq($bp1024) {
      @include padding(null cols(4) rem(75));
    }

    .title-container {
      @include padding(null cols(4) rem(25));
      position: relative;
      z-index: 3;
      text-align: center;

      @include mq($bp768) {
        @include padding(null 0 rem(35));
      }

      @include mq($bp1024) {
        @include padding(null cols(5, 40) rem(35));
      }

      .f--section-title {
        h2 {
          @include font-size(40, 48);
          color: $color-ludwell-black;

          @include mq($bp768) {
            @include font-size(60, 70);
          }
        }
      }
    }
  }
}

.cc--featured-block-flipping-images-accordions {
  @include maxwidth;
  position: relative;
  overflow: hidden;

  .c--featured-block-flipping-images-accordions {
    @include padding(rem(35) cols(4));

    @include mq($bp768) {
      @include padding(rem(50) null);
    }

    @include mq($bp1024) {
      @include padding(null cols(3));
    }

    .flex-inner-wrapper {
      position: relative;

      @include mq($bp1024) {
        display: flex;
        align-items: flex-start;
        column-gap: cols(2, 42);
      }
    }

    .mySwiper {
      overflow: visible;

      @include mq($bp1024) {
        @include padding(null cols(3, 42) null null);
        flex: 0 0 cols(21, 42);
        aspect-ratio: 1 / 1;
      }

      .swiper-wrapper {
        position: relative;
        transform: none !important;

        .swiper-slide {
          position: relative;
          width: cols(32, 40) !important;
          height: auto;
          transform: none !important;
          transition: top $transition-duration-default $transition-easing-default, left $transition-duration-default $transition-easing-default;
          opacity: 1 !important;

          @include mq($bp768) {
            width: cols(36, 40) !important;
          }

          @include mq($bp1024) {
            position: absolute;
            width: cols(16, 18) !important;
          }

          &.swiper-slide-active {
            z-index: 3;
          }

          &:nth-child(3) {
            &.swiper-slide-active {
              top: 0;
              left: cols(-64, 40);

              @include mq($bp768) {
                top: 0;
                left: cols(-72, 40);
              }

              @include mq($bp1024) {
                top: 0;
                left: 0;
              }
            }

            &:not(.swiper-slide-active) {
              z-index: 1;
              top: rem(60);
              left: calc(#{cols(-64, 40)} + #{rem(60)});

              @include mq($bp768) {
                top: rem(64);
                left: calc(#{cols(-72, 40)} + #{rem(64)});
              }

              @include mq($bp1024) {
                top: rem(50);
                left: rem(50);
              }
            }

            &.swiper-slide-next {
              z-index: 2;
              top: rem(30);
              left: calc(#{cols(-64, 40)} + #{rem(30)});

              @include mq($bp768) {
                top: rem(32);
                left: calc(#{cols(-72, 40)} + #{rem(32)});
              }

              @include mq($bp1024) {
                top: rem(25);
                left: rem(25);
              }
            }
          }

          &:nth-last-of-type(3) {
            z-index: 1;

            &.swiper-slide-active {
              z-index: 3;
              top: rem(1);
              left: rem(1);
            }

            &:not(.swiper-slide-active) {
              top: rem(60);
              left: rem(60);

              @include mq($bp768) {
                top: rem(64);
                left: rem(64);
              }

              @include mq($bp1024) {
                top: rem(50);
                left: rem(50);
              }
            }
          }

          &:nth-last-of-type(2) {
            z-index: 2;

            &:not(.swiper-slide-active) {
              top: rem(30);
              left: rem(30);

              @include mq($bp768) {
                top: rem(32);
                left: rem(32);
              }

              @include mq($bp1024) {
                top: rem(25);
                left: rem(25);
              }
            }

            &:not(:first-child) {
              &.swiper-slide-active {
                z-index: 3;
                top: 0;
                left: cols(-32, 40);

                @include mq($bp768) {
                  left: cols(-36, 40);
                }

                @include mq($bp1024) {
                  top: 0;
                  left: 0;
                }
              }

              &:not(.swiper-slide-active) {
                top: rem(30);
                left: calc(#{cols(-32, 40)} + #{rem(30)});

                @include mq($bp768) {
                  top: rem(32);
                  left: calc(#{cols(-36, 40)} + #{rem(32)});
                }

                @include mq($bp1024) {
                  top: rem(25);
                  left: rem(25);
                }
              }
            }
          }

          &:nth-last-of-type(1):not(:nth-child(3)) {
            &:not(.swiper-slide-active) {
              top: rem(30);
              left: calc(#{cols(-32, 40)} + #{rem(30)});

              @include mq($bp768) {
                top: rem(32);
                left: calc(#{cols(-36, 40)} + #{rem(32)});
              }

              @include mq($bp1024) {
                top: rem(25);
                left: rem(25);
              }
            }

            &.swiper-slide-active {
              top: 0;
              left: cols(-32, 40);

              @include mq($bp768) {
                left: cols(-36, 40);
              }

              @include mq($bp1024) {
                top: 0;
                left: 0;
              }

              &:only-child {
                top: 0;
                left: 0;
                width: 100% !important;

                .f--image {
                  figcaption {
                    @include margin(rem(15) 0 null null);
                  }
                }
              }
            }
          }

          .image-container {
            .f--image {
              figcaption {
                @extend %font-source-sans-regular;
                @include font-size(12, 22);
                @include margin(rem(75) cols(-8, 32) null null);
                transition: opacity $transition-duration-fast $transition-easing-default $transition-duration-default;
                color: $color-black;

                @include mq($bp768) {
                  @include font-size(14, 24);
                  @include margin(rem(80) cols(-4, 36) null null);
                }

                @include mq($bp1024) {
                  @include margin(rem(65) cols(-2, 16) null null);
                }
              }
            }
          }

          &:not(.swiper-slide-active) {
            .image-container {
              .f--image {
                figcaption {
                  transition: opacity 0s $transition-easing-default 0s;
                  opacity: 0;
                }
              }
            }
          }
        }
      }

      .navigation-wrapper {
        @include margin(rem(15) null null);
        display: flex;
        position: static;
        align-items: center;
        justify-content: space-between;

        @include mq($bp1024) {
          @include margin(0 null null);
          aspect-ratio: 1 / 1;
          position: absolute;
          top: rem(50);
          right: 0;
          flex-direction: column;
          align-items: flex-end;
          width: cols(16, 21);
        }

        .swiper-buttons {
          @include margin(null null null cols(-0.5, 40));
          display: flex;
          position: relative;
          z-index: 1;
          align-items: center;
          justify-content: space-between;
          width: rem(90);

          @include mq($bp768) {
            width: rem(97.13);
          }

          @include mq($bp1024) {
            @include margin(null null null 0);
            flex-direction: column;
            width: rem(50);
            height: rem(90);
            row-gap: rem(30);
          }

          &::before {
            @include centered(x);
            content: "";
            top: rem(-0.5);
            width: rem(1);
            height: calc(100% + #{rem(1)});
            background-color: $color-nicholson-gray;

            @include mq($bp1024) {
              top: 50%;
              width: rem(40);
              height: rem(1);
            }
          }

          .swiper-button-prev,
          .swiper-button-next {
            appearance: none;
            display: block;
            position: relative;
            top: 0;
            right: auto;
            left: auto;
            width: rem(30);
            height: rem(30);
            margin: 0;
            padding: 0;
            border: 0;
            background: transparent;

            &::after {
              content: "";
              display: block;
              width: rem(30);
              height: rem(30);
            }

            &.swiper-button-disabled {
              opacity: 1;

              svg path {
                fill: $color-nicholson-gray;
              }
            }

            svg path {
              transition: fill $transition-duration-default $transition-easing-default;
              fill: $color-raleigh-red;
            }

            &:hover,
            &:focus-visible {
              svg path {
                fill: $color-randolph-burgundy;
              }
            }
          }
        }

        .swiper-pagination {
          display: flex;
          position: relative;
          bottom: auto;
          align-items: center;
          width: auto;
          column-gap: rem(15);

          @include mq($bp1024) {
            flex-direction: column;
            row-gap: rem(15);
            align-items: center;
            width: rem(50);
          }

          .swiper-pagination-bullet {
            width: rem(5);
            height: rem(5);
            margin: 0;
            opacity: 1;
            background: $color-nicholson-gray;

            &.swiper-pagination-bullet-active {
              background: $color-raleigh-red;
            }
          }
        }
      }
    }

    .text-container {
      @include margin(rem(25) null null);

      @include mq($bp768) {
        @include margin(rem(35) null null);
      }

      @include mq($bp1024) {
        @include margin(0 null null );
        width: cols(19, 42);
      }

      .f--section-title {
        h2 {
          @extend %font-source-serif-regular;
          @include font-size(40, 48);
          color: $color-ludwell-black;

          @include mq($bp768) {
            @include font-size(60, 70);
          }
        }
      }

      .cc--rich-text {
        @include margin(0);

        .c--rich-text {
          @include padding(0);

          .inner-wrapper {
            @include padding(0);
          }

          iframe,
          .video-embed,
          img,
          .align-center {
            @include margin(null auto);
            max-width: 100%;
          }

          .align-left {
            @include margin(null null null 0);
          }

          .align-right {
            @include margin(null 0 null null);
          }

          ul {
            list-style-type: disc;

            ul {
              list-style-type: circle;

              ul {
                list-style-type: square;
              }
            }

            ol {
              ul {
                list-style-type: square;
              }
            }
          }

          ol {
            ul {
              list-style-type: circle;

              ul {
                list-style-type: square;
              }
            }
          }
        }
      }

      > ul {
        @include no-bullet;
        @include margin(rem(25) null null);

        > li {
          @include margin(null null rem(15));

          @include mq($bp768) {
            @include margin(null null rem(25));
          }

          &:last-child {
            @include margin(null null 0);
          }

          a.accordion-trigger {
            @include padding(null cols(4, 20) rem(13) 0);
            display: block;
            position: relative;
            width: 100%;
            border: 0;
            border-bottom: rem(1) solid $color-nicholson-gray;
            border-radius: 0;
            background: none;
            color: $color-black;
            text-align: left;
            cursor: pointer;

            @include mq($bp768) {
              @include padding(null cols(4, 40) rem(20) 0);
            }

            @include mq($bp1024) {
              @include padding(null cols(2, 32) null null);
            }

            .item-title {
              @extend %font-source-serif-regular;
              @include font-size(22, 32);

              @include mq($bp768) {
                @include font-size(26, 36);
              }
            }

            .offset {
              content: '';
              display: block;
              position: absolute;
              top: rem(-75);
            }

            &::after {
              content: '';
              display: block;
              position: absolute;
              top: rem(10);
              right: 0;
              width: rem(25);
              height: rem(25);
              background-image: url('../images/icons/icon-arrow-down.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;


              @include mq($bp768) {
                top: rem(5);
                width: rem(30);
                height: rem(30);
              }
            }

            &.open {
              @include padding(null null 0 null);
              border-bottom: 0;

              &::after {
                background-image: url('../images/icons/icon-arrow-up.svg');
              }
            }
          }

          .f--wysiwyg {
            p {
              &:last-child {
                @include margin(null null 0);
              }
            }
          }
        }
      }

      .accordion-panel {
        display: none;

        .cc--rich-text {
          @include margin(rem(5) null null);
          @include padding(null null rem(25));
          border-bottom: rem(1) solid $color-nicholson-gray;

          @include mq($bp768) {
            @include margin(rem(15) null null);
          }
        }
      }
    }
  }

  &.image-right {
    .c--featured-block-flipping-images-accordions {
      .flex-inner-wrapper {
        flex-direction: row-reverse;

        .mySwiper {
          @include mq($bp1024) {
            @include padding(null 0 null cols(3, 42));
          }

          .navigation-wrapper {
            @include mq($bp1024) {
              right: auto;
              left: 0;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
}

//  Remove List Styles
//
//   No Params
//
//   Example Usage:
//
//     ul {
//       @include no-bullet;
//     }
//
//
@mixin no-bullet {
  list-style: none;
  padding: 0;
  margin: 0;
}

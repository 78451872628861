.cc--booking-widget-hotel {
  background-color: $color-white;
  box-shadow: 0 rem(10) rem(10) 0 rgba($color-black, 0.2);

  @include mq($bp1024) {
    position: sticky;
    z-index: 999;
    top: 0;
  }

  .c--booking-widget-hotel {
    @include maxwidth;

    .inner-wrapper {
      @include padding(rem(15) cols(4) rem(25));

      @include mq($bp768) {
        @include padding(null null rem(15));
      }

      @include mq($bp1024) {
        @include padding(rem(25) cols(3) rem(28));
      }
    }

    form {
      .form-inner-wrapper {
        @include mq($bp768) {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          column-gap: cols(2, 40);
        }

        @include mq($bp1024) {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          column-gap: rem(10);
        }

        .fi--form-item {
          @include mq($bp768) {
            grid-column: span 2;
          }

          &:not(:last-of-type) {
            @include margin(null null rem(15));

            @include mq($bp768) {
              @include margin(null null rem(20));
            }

            @include mq($bp1024) {
              @include margin(null null 0);
            }
          }

          &:nth-child(1) {
            @include mq($bp1024) {
              flex: 0 0 cols(10, 42);
            }
          }

          &:nth-child(2) {
            @include mq($bp1024) {
              flex: 0 0 cols(10, 42);
            }
          }

          &.f--input-submit {
            @include mq($bp1024) {
              flex: 0 0 cols(10, 42);
            }

            button {
              @include padding(0);
              align-items: center;
              width: 100%;

              @include mq($bp768) {
                min-height: rem(50);
              }
            }
          }

          &.f--form-item-date {
            input {
              background-color: $color-franklin-white;

              @include placeholder {
                color: $color-ludwell-black;
              }
            }

            svg {
              display: none;
            }
          }

          input,
          select {
            color: $color-ludwell-black;

            @include placeholder {
              color: $color-ludwell-black;
            }

            &:focus + svg {
              z-index: 99;
            }
          }

          label {
            @extend %font-source-sans-regular;
            @include font-size(12, 14);
            @include margin(null null rem(5));
            display: block;
            letter-spacing: rem(1.5);
            text-transform: uppercase;

            @include mq($bp768) {
              @include font-size(14, 18);
            }
          }
        }

        .cc--multi-number-input-dropdown {
          @include margin(null null rem(10));

          @include mq($bp768) {
            @include margin(null null rem(20));
            grid-column: span 2;
          }

          @include mq($bp1024) {
            @include margin(null null 0);
            flex: 0 0 cols(10, 42);
            width: cols(10, 42);
          }

          .items {
            @include padding(null null rem(15));

            .counter-button {
              display: none !important;
            }
          }
        }
      }
    }

    .f--link {
      @include margin(rem(10) null null);

      @include mq($bp768) {
        @include margin(0 null null);
      }

      a {
        @include button(
          $outline: true,
          $btn-height-desktop: rem(50),
          $btn-height-tablet: rem(50)
        );
        @include line-height(14, 16);
        width: 100%;

        @include mq($bp768) {
          @include line-height(16, 20);
        }

        @include mq($bp1024) {
          @include padding(null rem(20));
        }
      }
    }
  }

  &.has-link {
    form {
      .form-inner-wrapper {
        .fi--form-item {
          &:nth-child(1) {
            @include mq($bp1024) {
              flex: 0 0 calc(#{cols(10, 42)} + #{rem(4)});
            }
          }

          &.f--input-submit {
            @include mq($bp768) {
              grid-column: span 1;
            }

            @include mq($bp1024) {
              flex: 0 0 calc(#{cols(10, 42)} + #{rem(5)});
            }
          }
        }

        .cc--multi-number-input-dropdown {
          @include mq($bp1024) {
            @include margin(null null 0);
            flex: 0 0 cols(5, 42);
            width: cols(5, 42);
          }
        }
      }
    }
  }
}

.block-booking_widget_hotel {
  @include mq($bp1024) {
    position: sticky;
    z-index: 999;
    top: 0;
  }
}

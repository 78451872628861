.cc--search-facets {
  @include maxwidth;

  .c--search-facets {
    @include padding(rem(35) cols(4) rem(20));

    @include mq($bp1024) {
      @include padding(null cols(9));
    }

    .f--sub-title {
      @include margin(null null rem(25));
    }

    ul {
      @include no-bullet;
      display: flex;
      flex-wrap: wrap;
      column-gap: rem(20);
      row-gap: rem(10);

      @include mq($bp768) {
        column-gap: rem(25);
      }

      li {
        a {
          @extend %font-source-sans-semibold;
          @include font-size(15, 25);
          color: $color-black;
          text-decoration: none;

          @include mq($bp768) {
            @include font-size(16, 30);
            letter-spacing: rem(0.22);
          }

          @include hover {
            text-decoration: underline;
          }

          &.active-trail {
            color: $color-raleigh-red;
            text-decoration: underline;

            @include hover {
              text-decoration: none;
            }
          }
        }

        span {
          @extend %font-source-sans-regular;
          @include font-size(15, 25);
          color: $color-black;

          @include mq($bp768) {
            @include font-size(16, 30);
            letter-spacing: rem(0.22);
          }
        }
      }
    }
  }
}

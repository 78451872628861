.cc--hero-historic-details {
  @include maxwidth;

  .c--hero-historic-details {
    @include padding(rem(25) cols(4));

    @include mq($bp768) {
      @include padding(rem(50) cols(4) rem(25));
    }

    @include mq($bp1024) {
      @include padding(rem(50) cols(3) rem(25));
      display: flex;
      column-gap: cols(4, 42);
    }

    .text-container {

      @include mq($bp1024) {
        flex: 0 0 cols(20, 42);
      }

      .f--eyebrow {
        @include margin(null null rem(5) null);

        @include mq($bp768) {
          @include margin(null null rem(10) null);
        }
      }

      .f--description {
        @include mq($bp768) {
          @include margin(rem(10) null null null);
        }

        @include mq($bp1024) {
          @include margin(rem(10) null null null);
        }
      }

      .event-meta {
        @include margin(rem(15) null null null);
        display: flex;
        flex-wrap: wrap;
        column-gap: rem(20);
        row-gap: rem(15);

        @include mq($bp768) {
          @include margin(rem(25) null null null);
        }

        > div {
          @extend %font-source-sans-semibold;
          @include font-size(14, 16);
          color: $color-ludwell-black;

          svg {
            @include margin(null rem(5) null null);
            width: rem(25);
            height: rem(25);
            vertical-align: middle;
          }
        }
      }

      .links-container {
        @include margin(rem(25) null null null);

        @include mq($bp768) {
          @include margin(rem(35) null null null);
          display: flex;
          column-gap: rem(10);
        }

        .f--link {
          &:first-child {
            width: 100%;

            @include mq($bp768) {
              flex: 1 1 50%;
            }

            a {
              width: 100%;

              @include button(
                $outline: false,
                $btn-height-mobile: rem(50),
                $btn-height-desktop: rem(60),
                $btn-height-tablet: rem(60)
                );
              }
            }

            &:last-child {
              width: 100%;

              @include mq($bp768max) {
                @include margin(rem(10) null null);
              }

              @include mq($bp768) {
                flex: 1 1 50%;
              }

              a {
                width: 100%;

              @include button(
                $outline: true,
                $btn-height-mobile: rem(50),
                $btn-height-desktop: rem(60),
                $btn-height-tablet: rem(60)
              );
            }
          }

        }
      }

      .hours-location {
        @include margin(rem(35) null null null);

        .hours-location-title {
          position: relative;

          &::after {
            @include centered('y');
            content: "";
            right: 0;
            width: 100%;
            height: rem(1);
            background-color: $color-york-yellow;
          }

          &::before {
            @include centered('y');
            content: "";
            right: rem(-0.5);
            width: rem(1);
            height: rem(8);
            background-color: $color-york-yellow;
          }

          span {
            @extend %font-source-serif-regular;
            @include font-size(22, 32);
            @include padding(null rem(15) null null);
            display: inline-block;
            position: relative;
            z-index: 1;
            background-color: $color-white;
            color: $color-ludwell-black;

            @include mq($bp768) {
              @include font-size(26, 36);
              @include padding(null rem(25) null null);
            }
          }
        }

        .hours-location-container {

          @include mq($bp768) {
            @include margin(rem(15) null null null);
            display: flex;
            column-gap: cols(2, 20);
          }

          .f--description {
            @include mq($bp768max) {
              @include margin(rem(15) null null null);
            }

            @include mq($bp768) {
              @include margin(0 null null null);
              flex: 0 0 cols(9, 20);
            }
          }

          .location-container {
            @include mq($bp768max) {
              @include margin(rem(15) null null null);
            }

            @include mq($bp768) {
              @include margin(0 null null null);
              flex: 0 0 cols(9, 20);
            }

            a {
              @extend %font-source-sans-semibold;
              @include margin(null null null rem(-5));
              @include font-size(14);
              color: $color-ludwell-black;
              text-decoration: none;

              @include hover {
                text-decoration: underline;
              }

              @include mq($bp1024) {
                @include font-size(16);
              }

              svg {
                @include margin(null rem(5) null null);
                vertical-align: middle;
              }
            }
          }

          &:has(.hours-location-desc) {
            .location-container a {
              @include margin(null null null rem(-35));
            }
          }
        }
      }
    }

    .image-container {
      @include margin(rem(25) null null null);
      position: relative;

      @include mq($bp768) {
        @include margin(rem(35) null null null);
      }

      @include mq($bp1024) {
        @include margin(0 null null null);
        flex: 0 0 cols(18, 42);
        align-self: flex-start;
      }

      .f--image {
        img {
          aspect-ratio: 2 / 3;
        }
      }

      .image-overlay {
        @include component-box-shadow;
        position: relative;
        z-index: 1;
        background-color: $color-white;

        @include mq($bp768max) {
          @include margin(rem(-100) cols(2, 40) null);
          @include padding(rem(25) cols(4, 40) rem(30) cols(4, 40));
        }

        @include mq($bp768-1024) {
          @include margin(rem(-100) cols(2, 40) null);
          @include padding(rem(25) cols(2, 40) rem(25) cols(2, 40));
        }

        @include mq($bp1024) {
          @include padding(rem(25) cols(1, 18));
          position: absolute;
          bottom: rem(25);
          left: cols(-1, 18);
          width: cols(18, 18);
        }

        .image-overlay-subtitle {
          @extend %font-source-sans-regular;
          @include font-size(12, 14);
          display: block;
          color: $color-ludwell-black;
          letter-spacing: rem(1.5);
          text-transform: uppercase;

          @include mq($bp768) {
            @include font-size(14, 18);
            letter-spacing: rem(1.75);
          }


          & + .f--description {
            @include margin(rem(10) null null null);
          }
        }

        .f--description {
          p {
            @include font-size(14, 22);
            color: $color-ludwell-black;
          }
        }
      }
    }
  }
}

.cc--quote-with-attribution {
  @include maxwidth;

  .c--quote-with-attribution {
    @include padding(null cols(4));
    @include component-padding;
    position: relative;

    > .f--image {
      position: absolute;
      right: 0;
      bottom: 0;
      width: rem(200);
      height: rem(200);

      @include mq($bp768) {
        top: 0;
        right: cols(3);
        width: rem(350);
        height: rem(350);
      }
    }

    .inner-wrapper {
      position: relative;
      z-index: 1;

      @include mq($bp1024) {
        @include margin(null auto);
        max-width: cols(30, 40);
      }
    }

    blockquote {
      @include margin(0);
      @include padding(0);

      p {
      @extend %font-source-serif-regular-italic;
        @include font-size(22, 40);
        quotes: "“" "”" "‘" "’";
        color: $color-raleigh-red;

        @include mq($bp768) {
          @include font-size(32, 50);
        }

        &::before {
          content: open-quote;
          display: inline-block;
          text-indent: rem(-15);
        }

        &::after {
          content: close-quote;
        }
      }
    }

    p.attribution {
      @extend %font-source-sans-regular;
      @include margin(rem(15) null null);
      @include font-size(12, 14);
      color: $color-ludwell-black;
      letter-spacing: rem(1.5);
      text-transform: uppercase;

      @include mq($bp768) {
        @include margin(rem(20) null null);
        @include font-size(14, 18);
        letter-spacing: rem(1.75);
      }
    }
  }
}

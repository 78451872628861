.cc--hero-landing-business-details {
  .c--hero-landing-business-details {

    .cc--hero-section-landing {
      .c--hero-section-landing {
        .image-container {
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.85;
            background: linear-gradient(to bottom, transparent 0%, $color-black 100%);
          }
        }
      }
    }

    .cc--contact-info-cards-grid {
      .c--contact-info-cards-grid {
        @include padding(0 null);


        .cards-container {
          @include mq($bp768max) {
            @include margin(0 null);
          }

          @include mq($bp768-1024) {
            @include margin(0 null null null);
            display: block;
          }

          .card {

            @include mq($bp1024max) {
              @include padding(null 0);
              border-top: rem(1) solid $color-nicholson-gray;
              border-right: 0;
              text-align: left;
            }

            &:first-child {
              @include mq($bp1024max) {
                @include padding(rem(20) null null null);
                border-top: rem(1) solid $color-nicholson-gray;
              }
            }

            &:last-child {
              @include mq($bp1024max) {
                @include padding(null null 0 null);
              }
            }


            .f--description {
              @include mq($bp1024max) {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

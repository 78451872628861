.cc--featured-video {
  @include maxwidth;
  overflow: hidden;

  .c--featured-video {
    @include padding(rem(35) null);

    @include mq($bp768) {
      @include padding(rem(50) cols(4));
    }

    .inner-wrapper {
      @include mq($bp768) {
        display: flex;
        flex-wrap: wrap;
      }

      .card {
        &.title-card {
          @include padding(null cols(4));

          @include mq($bp768) {
            @include padding(null 0);
          }

          @include mq($bp1024) {
            display: flex;
            position: relative;
            flex: 0 0 100%;
            align-items: center;
            justify-content: space-between;
          }

          &::after {
            @include mq($bp1024) {
              content: '';
              position: absolute;
              top: 0;
              left: 50%;
              width: rem(1);
              height: 100%;
              background-color: $color-york-yellow;
            }
          }

          &::before {
            @include mq($bp1024) {
              content: '';
              position: absolute;
              top: 0;
              left: calc(50% - #{rem(4)});
              width: rem(9);
              height: rem(1);
              background-color: $color-york-yellow;
            }
          }

          .title-subtitle-container {
            position: relative;

            @include mq($bp1024) {
              @include padding(rem(10) null rem(25));
              flex: 0 0 cols(18, 40);
            }

            > .decoration-image {
              position: absolute;
              z-index: -1;
              top: rem(-15);
              right: cols(-8, 40);
              width: rem(200);
              height: rem(200);

              @include mq($bp768) {
                top: 0;
                right: cols(-8, 40);
                width: rem(350);
                height: rem(350);
              }

              @include mq($bp1024) {
                top: rem(-15);
                right: cols(-6, 18);
              }
            }
          }

          .description-link-container {
            @include margin(rem(10) null null null);

            @include mq($bp768) {
              @include margin(rem(15) null null null);
            }

            @include mq($bp1024) {
              @include margin(0 null null null);
              @include padding(rem(10) null rem(25));
            }
          }

          .title-subtitle-container + .description-link-container {
            @include mq($bp1024) {
              flex: 0 0 cols(17, 40);
            }
          }

          .f--sub-title {
            @include margin(rem(5) null null null);
          }

          .f--description {
            @include margin(rem(15) null null null);

            @include mq($bp768) {
              @include margin(0 null null null);
            }

            p {
              @include font-size(15, 25);

              @include mq($bp768) {
                @include font-size(16, 28);
              }
            }
          }

          .f--link {
            @include margin(rem(15) null null null);

            @include mq($bp768) {
              @include margin(rem(20) null null null);
            }

            a {
              @include button(
                $outline: true,
                $hover-bg-color: $color-randolph-burgundy
              );

              @include mq($bp768) {
                @include button(
                  $outline: true,
                  $text-color: $color-white,
                  $outline-color: $color-york-yellow,
                  $focus-color: $color-focus-light
                );
              }

              @include mq($bp1024) {
                @include button(
                  $outline: true,
                  $hover-bg-color: $color-randolph-burgundy
                );
              }
            }
          }
        } // .title-card

        &.no-divider {
          &::before,
          &::after {
            display: none;
          }

          .title-subtitle-container {
            @include mq($bp1024) {
              flex: 0 0 100%;
            }

            .decoration-image {
              @include mq($bp1024) {
                right: cols(16, 40);
              }
            }
          }
        }
      }

      .video-poster-container {
        @include margin(rem(20) null null);
        position: relative;
        width: 100%;
        
        @include mq($bp768) {
          @include margin(rem(25) null null);

          &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
            height: rem(300);
            background-image: linear-gradient(
              180deg,
              rgba($color-black, 0) 0%,
              rgba($color-black, 0.65) 100%
            );
          }
        }

        @include mq($bp1024) {
          @include margin(0 null null);
        }
      }

      .f--video-embed {
        width: 100%;
        height: 0;
    
        &.is-active {
          position: relative;
          z-index: 1;
        }
      }
    
      .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-bottom: 56.25%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    
      .video-controls-info {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: rem(35);
        height: rem(35);

        @include mq($bp768) {
          width: 100%;
          height: rem(100);
        }
      }
    
      .play-video-btn {
        height: rem(35);
        padding: 0;
        border: 0;
        background-color: transparent;
        cursor: pointer;

        @include mq($bp768) {
          height: rem(100);
        }
  
        svg {
          width: rem(35);
          height: rem(35);

          @include mq($bp768) {
            width: rem(100);
            height: rem(100);
          }

          rect {
            fill: $color-york-yellow;
          }

          polygon {
            fill: $color-ludwell-black;
          }
        }
    
        rect,
        polygon {
          transition: fill 0.3s ease;
        }
    
        @include hover {
          rect {
            fill: $color-raleigh-red;
          }

          polygon {
            fill: $color-white;
          }
        }
      }
    
      .video-title {
        @include padding(rem(15) cols(2, 24) null);
        @extend %font-source-serif-regular;
        @include font-size(18, 26);

        @include mq($bp768) {
          @include font-size(22, 32);
          color: $color-white;
        }

        &.mobile {
          @include mq($bp768) {
            display: none;
          }
        }
    
        &.desktop {
          display: none;
    
          @include mq($bp768) {
            @include centered('y');
            @include padding(0);
            @include margin(0);
            display: block;
            position: absolute;
            z-index: 2;
            left: rem(125);
            max-width: cols(31, 40);
          }
        }
      }
    }
  }
}

.lc--two-column {
  .l--two-column {
    @include maxwidth;

    .l--two-column-inner {
      @include padding(rem(30) null rem(35));

      @include mq($bp768) {
        @include padding(rem(34) null rem(75));
        display: flex;
        flex-wrap: wrap;
      }

      .lr--sidebar {
        @include padding(rem(22) cols(2, 24) rem(10));
        width: 100%;
        background: $color-black;

        @include mq($bp1024) {
          @include padding(0 cols(1, 48));
          flex-basis: cols(13, 48);
        }
      }

      .lr--main {
        @include padding(rem(20) cols(2, 24) null);

        @include mq($bp1024) {
          @include padding(0 cols(3, 48));
          flex-basis: cols(35, 48);
        }
      }
    }
  }
}

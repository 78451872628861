.cc--minisite-navigation {
  position: relative;
  background-color: $color-white;
  box-shadow: 0 rem(10) rem(20) 0 rgba($color-black, 0.2);

  .c--minisite-navigation {
    @include maxwidth;

    .menu-container {
      @include padding(rem(20) cols(2) rem(20) cols(4));

      @include mq($bp768) {
        @include padding(rem(25) cols(3) null null);
      }

      @include mq($bp1024) {
        @include padding(null cols(2) rem(25));
        display: flex;
        align-items: center;
      }

      .menu-container-inner {
        @extend %font-source-serif-semibold;
        @include padding(0);
        @include font-size(18, 23);
        display: flex;
        align-items: center;
        width: 100%;
        border: 0;
        background: transparent;
        color: $color-ludwell-black;
        letter-spacing: rem(-0.25);
        text-align: left;

        @include mq($bp1024) {
          flex: 0 1 auto;
          width: auto;
        }

        span.title,
        a {
          @extend %font-source-serif-semibold;
          @include padding(0);
          @include font-size(18, 23);
          display: flex;
          align-items: center;
          width: 100%;
          border: 0;
          background: transparent;
          color: $color-ludwell-black;
          letter-spacing: rem(-0.25);
          text-align: left;

          @include mq($bp1024) {
            @include margin(null rem(35) null null);
            flex: 0 1 auto;
            width: auto;
          }
        }


        a {
          color: inherit;
          text-decoration: none;

          @include hover {
            text-decoration: underline;
          }
        }
      }

      button {

        @extend %font-source-serif-semibold;
        @include padding(0);
        @include font-size(18, 23);
        display: flex;
        align-items: center;
        width: 100%;
        border: 0;
        background: transparent;
        color: $color-ludwell-black;
        letter-spacing: rem(-0.25);
        text-align: left;
        cursor: pointer;

        @include mq($bp1024) {
          display: none;
        }

        svg {
          @include margin(null 0 null auto);
          width: rem(30);
          height: rem(30);
          transition: transform $transition-duration-fast $transition-easing-default;

          @include mq($bp768) {
            width: rem(35);
            height: rem(35);
          }

          @include mq($bp1024) {
            display: none;
          }
        }

        .close {
          display: none;
        }

        &.is-disabled {
          pointer-events: none;

          svg {
            display: none;
          }
        }
      }

      &.is-visible {
        @include padding(null null rem(10));

        nav {
          display: block;
        }

        > button {
          svg.menu {
            display: none;
          }

          svg.close {
            @include mq($bp1024max) {
              display: block;
            }
          }
        }
      }

      nav {
        @include mq($bp1024max) {
          @include padding(null cols(4));
          display: none;
          position: absolute;
          z-index: 1;
          top: 100%;
          left: 0;
          width: 100%;
          background-color: $color-white;
          box-shadow: 0 rem(10) rem(10) rgba($color-black, 0.2);
        }

        ul {
          @include no-bullet;
          @include padding(null null rem(15) null);

          @include mq($bp1024) {
            @include padding(rem(2.5) null 0);
            display: flex;
            height: 100%;
            column-gap: rem(25);
          }

          .menu-item {
            @include mq($bp1024) {
              position: relative;
            }

            &:not(:last-child) {
              @include margin(null null rem(20));

              @include mq($bp768) {
                @include margin(null null rem(20));
              }

              @include mq($bp1024) {
                @include margin(null null 0);
              }
            }

            &--active-trail {
              @include mq($bp1024) {
                &::after {
                  @include centered(x);
                  content: '';
                  display: block;
                  bottom: rem(-26);
                  width: rem(65);
                  height: rem(12);
                  mask-image: url('../images/interface/interface-insetarrow-up.svg');
                  background-color: $color-ludwell-black;
                }
              }
            }

            a {
              @extend %font-source-sans-regular;
              @include font-size(16, 26);
              display: block;
              color: $color-ludwell-black;
              letter-spacing: rem(3);
              text-decoration: none;
              text-transform: uppercase;

              @include mq($bp768) {
                @include font-size(14, 18);
                letter-spacing: rem(1.75);
              }

              @include mq($bp1024) {
                @include line-height(14, 23);
              }

              @include hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .main-menu-open & {
    display: none;
  }
}

.cc--multi-number-input-dropdown {
  .c--multi-number-input-dropdown {
    span.counter-label {
      @extend %font-source-sans-regular;
      @include font-size(12, 14);
      @include margin(null null rem(5));
      display: block;
      letter-spacing: rem(1.5);
      text-transform: uppercase;

      @include mq($bp768) {
        @include font-size(14, 18);
      }
    }

    .counter-wrapper {
      @include no-bullet;
      position: relative;
      z-index: 1;

      &.is-open {
        z-index: 999;

        svg {
          transform: rotate(180deg);
        }
      }

      .counter-item {
        @extend %font-source-sans-regular;
        @include font-size(14, 22);
        @include padding(rem(14) rem(50) rem(14) rem(15));
        height: rem(50);
        overflow: hidden;
        background-color: $color-franklin-white;
        color: $color-ludwell-black;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;

        &:focus-visible {
          z-index: 1;
        }
      }

      .items-container {
        .items {
          @include centered(x);
          @include no-bullet;
          @include padding(rem(15) rem(15) null);
          display: none;
          z-index: 2;
          top: 0;
          width: 100%;
          border: rem(1) solid $color-nicholson-gray;
          background-color: $color-franklin-white;

          &.is-open {
            display: block;
          }

          li {
            @include vertical-align-flex;
            align-items: center;

            label {
              letter-spacing: unset;
              text-transform: none;
            }

            &:not(:nth-child(2)) {
              @include margin(null null rem(15));
              @include padding(null null rem(15));
              border-bottom: solid rem(1) $color-nicholson-gray;
            }
          }

          .counter-button {
            @include margin(rem(15) rem(-16) rem(-1));
            @include button(
              $btn-height-desktop: rem(50),
              $btn-height-tablet: rem(50)
            );
            appearance: none;
            flex-direction: row;
            align-items: center;
            width: calc(100% + #{rem(32)});
            border: 0;
            cursor: pointer;
          }
        }
      }

      svg {
        position: absolute;
        z-index: 1;
        top: rem(15);
        right: rem(15);
        width: rem(20);
        height: rem(20);
        pointer-events: none;
      }
    }
  }
}
.cc--interactive-cards-collection-pane {
  @include maxwidth;
  position: relative;
  overflow: hidden;

  .c--interactive-cards-collection-pane {
    @include padding(rem(25) cols(4));

    @include mq($bp768) {
      @include padding(rem(50) null);
    }

    @include mq($bp1024) {
      @include padding(null cols(3));
    }

    .inner-wrapper {
      @include mq($bp1024) {
        display: flex;
        justify-content: space-between;
        column-gap: cols(3, 42);
      }
    }

    .f--section-title {
      @include margin(null null rem(25));
      @include padding(null rem(15) null null);
      position: relative;

      @include mq($bp768) {
        @include margin(null null rem(35));
        @include padding(null rem(25) null null);
      }

      @include mq($bp1024) {
        @include margin(null null rem(50));
      }

      &::after {
        content: "";
        position: absolute;
        top: rem(15);
        right: 0;
        width: 100%;
        height: rem(1);
        background-color: $color-york-yellow;
      }

      &::before {
        content: "";
        position: absolute;
        top: rem(11.5);
        right: rem(-0.5);
        width: rem(1);
        height: rem(8);
        background-color: $color-york-yellow;
      }

      h2 {
        @extend %font-source-serif-regular-italic;
        @include font-size(22, 26);
        @include padding(null rem(15) null null);
        display: inline-block;
        position: relative;
        z-index: 1;
        background-color: $color-white;
        color: $color-ludwell-black;
        letter-spacing: rem(8.5);
        text-transform: uppercase;

        @include mq($bp768) {
          @include font-size(26, 30);
          @include padding(null rem(25) null null);
          letter-spacing: rem(10);
        }
      }
    }

    .left {
      position: relative;
      z-index: 2;

      @include mq($bp1024) {
        display: flex;
        flex: 0 0 cols(9, 42);
        flex-direction: column;
        width: cols(9, 42);
        border-right: rem(1) solid $color-raleigh-red;
      }

      &::after {
        @include mq($bp1024) {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: rem(-4.5);
          width: rem(8);
          height: rem(1);
          background-color: $color-raleigh-red;
        }
      }

      &::before {
        @include mq($bp1024) {
          content: '';
          display: block;
          position: absolute;
          right: rem(-4.5);
          bottom: 0;
          width: rem(8);
          height: rem(1);
          background-color: $color-raleigh-red;
        }
      }

      .f--eyebrow {
        @include margin(null null rem(5));

        @include mq($bp1024) {
          @include margin(null null rem(30));
        }
      }

      .select-container {
        @include margin(null null rem(25));
        position: relative;
        background-color: $color-franklin-white;

        @include mq($bp768) {
          @include margin(null null rem(35));
        }

        @include mq($bp1024) {
          display: none;
        }

        select {
          @extend %font-source-sans-regular;
          @include padding(null rem(50) null rem(15));
          @include font-size(14, 22);
          width: 100%;
          height: rem(50);
          border: 0;
          background: transparent;
          background-image: none;
          box-shadow: none;
          color: $color-ludwell-black;
          text-overflow: ellipsis;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          @include mq($bp768) {
            @include font-size(18, 24);
            height: rem(65);
          }

          &::-ms-expand {
            display: none;
          }
        }

        svg {
          @include centered('y');
          right: rem(15);
          width: rem(20);
          height: rem(20);
          pointer-events: none;
        }
      }

      .pagination-wrapper {
        @include mq($bp1024max) {
          display: none;
        }
      }

      .interactive-cards-collection-pane-pagination {
        @include no-bullet;
        position: relative;

        li {
          @include mq($bp1024) {
            @include padding(null cols(1, 9) null null);
            position: relative;
          }

          &:not(:last-child) {
            @include margin(null 0 rem(25) null);
          }

          span {
            @extend %font-source-serif-regular;
            @include font-size(18, 28);
            display: inline-block;
            position: relative;
            border: 0;
            color: $color-black;
            cursor: pointer;
          }

          .description {
            @include margin(rem(5) null null);
            display: none;

            p {
              @extend %font-source-sans-regular;
              @include font-size(15, 25);

              @include mq($bp768) {
                @include font-size(16, 28);
              }
            }
          }

          &.active {
            &::after {
              @include mq($bp1024) {
                @include centered(y);
                content: '';
                display: block;
                z-index: 4;
                left: calc(100% - #{rem(11)});
                width: rem(12);
                height: rem(65);
                background-image: url('../images/interface/interface-insetarrow-left.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                filter: drop-shadow(rem(-1) 0 0 $color-raleigh-red);
              }
            }

            span {
              @extend %font-source-serif-semibold;
              background-color: $color-white;
              color: $color-raleigh-red;
            }
          }
        }
      }
    }

    .right {
      position: relative;
      z-index: 1;

      @include mq($bp1024) {
        position: relative;
        flex: 0 0 cols(30, 42);
        width: cols(30, 42);
        overflow: visible;
      }

      .swiper {
        display: none;
        z-index: 3;
        overflow: visible;

        &.swiper-initialized {
          display: block;
        }

        .swiper-wrapper {
          .swiper-slide {
            > span {
              @include visuallyhidden;
            }

            .f--cta-title {
              @include margin(null null rem(5));

              h3 {
                @extend %font-source-serif-regular;
                @include font-size(30, 40);
                color: $color-ludwell-black;

                @include mq($bp768) {
                  @include font-size(36, 46);
                }
              }
            }

            .f--description {
              p {
                @include font-size(16, 30);
                letter-spacing: rem(0.2);

                @include mq($bp768) {
                  @include font-size(18, 34);
                  letter-spacing: rem(0.25);
                }

                &:not(:last-child) {
                  @include margin(null null rem(10));
                }
              }
            }

            .cards-container {
              @include margin(rem(25) null null);

              @include mq($bp768) {
                @include margin(rem(35) null null);
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                column-gap: cols(2, 40);
                row-gap: rem(25);
              }

              @include mq($bp1024) {
                column-gap: cols(2, 30);
                row-gap: rem(50);
              }

              .card {
                aspect-ratio: 1 / 1;
                position: relative;
                overflow: hidden;
                background-color: $color-ludwell-black;

                @include mq($bp1024) {
                  html[data-whatinput='touch'] &,
                  &:focus-within {
                    .text-container {
                      .description-link-container {
                        max-height: rem(525);
                        transition: max-height $transition-duration-default $transition-easing-default, opacity $transition-duration-default $transition-easing-default;
                        opacity: 1;
                      }
                    }
                  }
                }

                &:not(:last-child) {
                  @include margin(null null rem(20));

                  @include mq($bp768) {
                    @include margin(null null 0);
                  }
                }

                &::after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: rem(150);
                  opacity: 0.6;
                  background: linear-gradient(to bottom, transparent 0%, $color-black 100%);
                }

                .text-container {
                  @include padding(null cols(4) rem(30));
                  position: absolute;
                  z-index: 1;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  color: $color-white;
                  text-align: center;

                  @include mq($bp768) {
                    @include padding(null cols(2, 19) null);
                  }

                  @include mq($bp1024) {
                    @include padding(null cols(1, 14) rem(25));
                  }

                  .description-link-container {
                    @include mq($bp1024) {
                      max-height: 0;
                      transition: max-height $transition-duration-default $transition-easing-default, opacity $transition-duration-default $transition-easing-default;
                      opacity: 0;
                    }
                  }

                  .f--cta-title {
                    h3 {
                      @include font-size(22, 32);
                      color: $color-white;

                      @include mq($bp768) {
                        @include font-size(26, 36);
                      }
                    }
                  }

                  .f--description {
                    p {
                      @include font-size(15, 25);
                      color: $color-white;

                      @include mq($bp768) {
                        @include font-size(16, 28);
                        letter-spacing: rem(0.25);
                      }

                      a {
                        color: $color-white;
                      }
                    }
                  }

                  .f--link {
                    @include margin(rem(15) null null);

                    a {
                      @include button-cta(
                        $text-color: $color-white,
                        $hover-color: $color-york-yellow,
                        $outline-color: $color-white,
                        $svg-color: $color-york-yellow
                      );
                    }
                  }
                }

                .image-container {
                  height: 100%;
                  background-color: $color-york-yellow;

                  .f--image {
                    height: 100%;

                    img {
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }

                @include mq($bp1024) {
                  &:focus-within {
                    &::after {
                      height: rem(525);
                      transition: height $transition-duration-default $transition-easing-default;
                    }

                    .text-container {
                      .description-link-container {
                        max-height: rem(525);
                        transition: max-height $transition-duration-default $transition-easing-default, opacity $transition-duration-default $transition-easing-default;
                        opacity: 1;
                      }
                    }
                  }

                  @include hover {
                    &::after {
                      height: rem(525);
                      transition: height $transition-duration-default $transition-easing-default;
                    }

                    .text-container {
                      .description-link-container {
                        max-height: rem(525);
                        transition: max-height $transition-duration-default $transition-easing-default, opacity $transition-duration-default $transition-easing-default;
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cc--explore-flat-menu {
  @include component-box-shadow;
  position: relative;
  background-color: $color-white;

  &::before {
    @include centered(x);
    content: '';
    display: block;
    bottom: calc(100% - #{rem(1)});
    width: rem(65);
    height: rem(12);
    background-image: url('../images/interface/interface-insetarrow-up.svg');
  }

  .c--explore-flat-menu {
    .menu-container {
      @include maxwidth;
      @include padding(rem(35) cols(4));

      @include mq($bp768) {
        @include padding(rem(25) cols(4) null);
      }

      @include mq($bp1024) {
        @include padding(null cols(2) rem(25));
      }

      nav {
        position: relative;

        &::before {
          @include centered;
          content: '';
          width: rem(1);
          height: calc(100% + #{rem(20)});
          background-color: $color-nicholson-gray;

          @include mq($bp1024) {
            display: none;
          }
        }

        ul {
          @include no-bullet;
          column-gap: cols(4, 40);
          column-count: 2;
          row-gap: rem(15);

          @include mq($bp1024) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: rem(10);
          }
  
          .menu-item {
            text-align: center;

            &:not(:last-child) {
              @include margin(null null rem(15));

              @include mq($bp768) {
                @include margin(null null rem(20));
              }

              @include mq($bp1024) {
                @include margin(null null 0);
              }
            }

            a {
              @extend %font-source-serif-semibold;
              @include font-size(18, 26);
              display: block;
              color: $color-ludwell-black;
              text-decoration: none;

              @include hover {
                text-decoration: underline;
              }
            }

            &--active-trail {
              a {
                color: $color-raleigh-red;
              }
            }
          }
        }
      }
    }
  }
}
.cc--feature-promo-cards {
  @include maxwidth;
  position: relative;

  .c--feature-promo-cards {
    @include padding(rem(35) cols(4) null);

    @include mq($bp768) {
      @include padding(rem(50) 0 null);
    }

    @include mq($bp1024) {
      @include padding(rem(25) cols(2) null cols(4));
    }

    .inner-wrapper {
      @include mq($bp768) {
        display: flex;
        align-items: flex-start;
        column-gap: cols(2, 40);
      }

      @include mq($bp1024) {
        column-gap: cols(1, 42);
      }
    }

    .title-link-container {
      @include margin(null null rem(20));

      @include mq($bp768) {
        @include margin(null null 0);
        @include padding(rem(75) null null);
        flex: 0 0 cols(13, 40);
      }

      @include mq($bp1024) {
        @include padding(rem(47) null null);
        flex: 0 0 cols(8, 42);
      }

      .f--section-title {
        h2 {
          @extend %font-source-serif-regular-italic;
          @include font-size(22, 26);
          letter-spacing: rem(8.5);
          text-transform: uppercase;

          @include mq($bp768) {
            @include font-size(26, 30);
            letter-spacing: rem(10);
          }
        }
      }

      .f--link {
        @include margin(rem(15) null null);

        @include mq($bp768) {
          @include margin(rem(25) null null);
        }

        a {
          @include button-cta(
            $text-color: $color-black,
            $hover-color: $color-raleigh-red,
            $outline-color: $color-black,
            $svg-color: $color-raleigh-red
          );
        }
      }
    }

    .cards-container {
      display: grid;
      grid-auto-rows: minmax(rem(158), 1fr);
      grid-template-columns: 1fr;
      row-gap: rem(10);

      @include mq($bp768) {
        grid-auto-rows: minmax(rem(188), 1fr);
        row-gap: rem(20);
        flex: 0 0 cols(25, 40);
      }

      @include mq($bp1024) {
        grid-template-columns: repeat(2, minmax(0, cols(16, 33)));
        flex: 0 0 cols(33, 42);
        column-gap: cols(1, 33);
        row-gap: rem(25);
      }

      .card {
        display: flex;

        .f--image {
          flex: 0 0 cols(14, 40);

          @include mq($bp768) {
            flex: 0 0 cols(8, 25);
          }

          @include mq($bp1024) {
            flex: 0 0 cols(5, 16);
          }

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .text-container {
          @include padding(rem(20) cols(2, 40));
          flex: 0 0 cols(26, 40);
          background-color: $color-york-yellow;

          @include mq($bp768) {
            @include padding(rem(25));
            flex: 0 0 cols(17, 25);
          }

          @include mq($bp1024) {
            @include padding(rem(25) cols(1, 11));
            flex: 0 0 cols(11, 16);
          }

          .f--eyebrow {
            @include margin(null null rem(5));
          }

          .f--cta-title {
            h3 {
              @extend %font-source-serif-regular;
              @include font-size(16, 26);

              @include mq($bp768) {
                @include font-size(18, 28);
              }
            }

            a {
              color: inherit;
            }
          }
        }
      }
    }

    .decorative-image {
      position: absolute;
      z-index: -1;
      top: rem(-15);
      left: cols(-4, 40);
      width: rem(200);
      height: rem(200);

      @include mq($bp768) {
        top: rem(25);
        width: rem(288);
        height: rem(288);
      }

      @include mq($bp1024) {
        top: rem(-35);
        left: cols(2);
        width: rem(300);
        height: rem(300);
      }
    }
  }
}

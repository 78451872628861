.cc--hero-event {
  @include maxwidth;

  .c--hero-event {
    @include padding(rem(25) cols(4));

    @include mq($bp768) {
      @include padding(rem(50) cols(4) rem(25));
    }

    @include mq($bp1024) {
      @include padding(rem(50) cols(3) rem(25));
      display: flex;
      column-gap: cols(4, 42);
    }

    .text-container {

      @include mq($bp1024) {
        flex: 0 0 cols(20, 42);
      }

      .f--eyebrow {
        @include margin(null null rem(5) null);

        @include mq($bp768) {
          @include margin(null null rem(10) null);
        }
      }

      .f--description {
        @include mq($bp768) {
          @include margin(rem(10) null null null);
        }

        @include mq($bp1024) {
          @include margin(rem(10) null null null);
        }
      }

      .event-meta {
        @include margin(rem(15) null null null);
        display: flex;
        flex-wrap: wrap;
        column-gap: rem(20);
        row-gap: rem(15);

        @include mq($bp768) {
          @include margin(rem(25) null null null);
        }

        > div {
          @extend %font-source-sans-semibold;
          @include font-size(14, 16);
          color: $color-ludwell-black;

          svg {
            @include margin(null rem(5) null null);
            width: rem(25);
            height: rem(25);
            vertical-align: middle;
          }
        }
      }

      .links-container {
        @include margin(rem(25) null null null);

        @include mq($bp768) {
          @include margin(rem(35) null null null);
          display: flex;
          column-gap: rem(10);
        }

        .f--link:not(.tickets-select-widget) {
          &:nth-child(1) {
            width: 100%;

            @include mq($bp768) {
              flex: 0 0 50%;
            }

            a {
              width: 100%;

              @include button(
                $outline: false,
                $btn-height-mobile: rem(50),
                $btn-height-desktop: rem(60),
                $btn-height-tablet: rem(60)
                );
              }
            }

            &:nth-child(2) {
              width: 100%;

              @include mq($bp768max) {
                @include margin(rem(10) null null);
              }

              @include mq($bp768) {
                flex: 0 0 50%;
              }

              a {
                width: 100%;

              @include button(
                $outline: false,
                $background-color-default: $color-randolph-burgundy,
                $btn-height-mobile: rem(50),
                $btn-height-desktop: rem(60),
                $btn-height-tablet: rem(60)
              );
            }
          }

        }
      }
    }

    .f--image {
      @include margin(rem(25) null null null);

      @include mq($bp768) {
        @include margin(rem(35) null null null);
      }

      @include mq($bp1024) {
        @include margin(0 null null null);
        flex: 0 0 cols(18, 42);
      }

      img {
        aspect-ratio: 2 / 3;
      }
    }
  }
}

.cc--modal-donation-form {
  @include component-box-shadow;
  @include margin(null auto);
  @include padding(0);
  z-index: 9999;
  width: calc(100% - #{cols(8)});
  border: 0;
  background-color: $color-white;

  @include mq($bp1024) {
    width: calc(100% - #{cols(20)});
  }

  &::backdrop {
    background-color: rgba($color-black, 0.5);
  }

  .c--modal-donation-form {
    @include padding(rem(45) null);

    @include mq($bp768) {
      @include padding(rem(75) null);
    }
    
    .title-container {
      @include margin(null null rem(20));
      @include padding(null cols(4, 40));
      text-align: center;

      @include mq($bp768) {
        @include padding(null cols(10, 40));
      }

      @include mq($bp1024) {
        @include padding(null cols(2, 28));
      }

      .f--eyebrow {
        @include margin(null null 0);

        span {
          @include font-size(18, 28);
          display: block;
          letter-spacing: rem(4);

          @include mq($bp768) {
            @include font-size(22, 32);
            letter-spacing: rem(5);
          }
        }
      }

      .f--cta-title {
        @include margin(rem(15) null null);

        @include mq($bp768) {
          @include margin(rem(20) null null);
        }

        h3 {
          @extend %font-source-serif-regular;
          @include font-size(22, 32);

          @include mq($bp768) {
            @include font-size(26, 36);
          }
        }
      }

      .f--sub-title {
        @include margin(rem(10) null null);

        h3 {
          @extend %font-source-serif-regular-italic;
          @include font-size(22, 32);
          color: $color-ludwell-black;
          text-transform: none;
        }
      }

      .f--description {
        @include margin(rem(25) null null);

        p {
          @include font-size(16, 30);
          color: $color-black;
          letter-spacing: rem(-0.2);
          text-decoration: none;

          @include mq($bp768) {
            @include font-size(18, 34);
            letter-spacing: rem(0.25);
          }
        }
      }
    }

    .form-wrapper {
      iframe {
        width: 100%;
        min-height: rem(300);
        border: 0;
      }
    }

    .text-container {
      @include margin(rem(20) null null);
      @include padding(rem(20) cols(4, 40) null);

      @include mq($bp768) {
        @include margin(rem(25) null null);
        @include padding(rem(25) cols(10, 40) null);
      }

      @include mq($bp1024) {
        @include margin(rem(35) null null);
        @include padding(rem(35) cols(2, 28) null);
      }

      p {
        @extend %font-source-sans-regular;
        @include font-size(14, 22);
        text-align: center;

        a {
          color: $color-raleigh-red;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .modal-close {
      @include vertical-align-flex;
      @include padding(0);
      position: absolute;
      top: rem(10);
      right: rem(10);
      border: 0;
      background: transparent;
      cursor: pointer;
      @include mq($bp768) {
        top: rem(15);
        right: rem(15);
      }
      svg {
        width: rem(30);
        height: rem(30);
        @include mq($bp768) {
          width: rem(35);
          height: rem(35);
        }
      }
    }
  }
}

body.modal-open {
  overflow: hidden;
}
.cc--booking-widget-restaurant {
  background-color: $color-white;
  box-shadow: 0 rem(10) rem(10) 0 rgba($color-black, 0.2);

  @include mq($bp1024) {
    position: sticky;
    z-index: 99;
    top: rem(15);
  }

  .c--booking-widget-restaurant {
    @include maxwidth;

    .inner-wrapper {
      @include padding(rem(15) cols(4) rem(25));

      @include mq($bp768) {
        @include padding(null null rem(35));
      }

      @include mq($bp1024) {
        @include padding(rem(25) cols(3) rem(28));
      }
    }

    .ot-dtp-picker {
      &.wide {
        @include padding(0);
        width: 100%;
      }

      .ot-dtp-picker-form {
        height: auto;
        border: 0;

        @include mq($bp1024) {
          display: flex;
          align-items: flex-end;
          column-gap: rem(10);
        }

        .ot-dtp-picker-selector {
          @include margin(null null rem(10));
          display: block;
          position: relative;
          height: auto !important;
          border-left: 0 !important;

          @include mq($bp768) {
            @include margin(null null rem(20));
            grid-column: span 2;
          }

          @include mq($bp1024) {
            @include margin(null null 0);
            height: rem(50);
          }

          &:focus-within {
            z-index: 999;
          }

          &:nth-child(2) {
            width: 100%;

            @include mq($bp1024) {
              flex: 0 0 cols(11, 42);
              width: cols(11, 42);
            }
          }

          &:nth-child(3) {
            width: 100%;

            @include mq($bp1024) {
              flex: 0 0 cols(11, 42);
              width: cols(11, 42);
            }
          }

          &:nth-child(4) {
            width: 100%;

            @include mq($bp1024) {
              flex: 0 0 calc(#{cols(10, 42)} - #{rem(5)});
              width: calc(#{cols(10, 42)} - #{rem(5)});
            }
          }

          &::before {
            @extend %font-source-sans-regular;
            @include font-size(12, 14);
            @include margin(null null rem(5));
            display: block;
            text-transform: uppercase;

            @include mq($bp1024) {
              @include font-size(14, 18);
            }
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            z-index: 99;
            right: rem(15);
            bottom: rem(15);
            width: rem(20);
            height: rem(20);
            background-image: url('../images/icons/icon-arrow-down.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            pointer-events: none;
          }

          &.ot-party-size-picker::before {
            content: '# of People';
          }

          &.ot-date-picker::before {
            content: 'Date';
          }

          &.ot-time-picker::before {
            content: 'Time';
          }

          input,
          select {
            @extend %input-style;
            @include padding(null 0 null null);
            position: relative;
            border: 0;
            opacity: 1;
            background-color: $color-franklin-white;

            @include mq($bp768) {
              height: rem(50);
            }
          }

          .ot-dtp-picker-selector-link {
            display: none;
          }
        }

        .ot-dtp-picker-button {
          @include button(
            $btn-height-desktop: rem(50),
            $btn-height-tablet: rem(50),
          );
          @include margin(rem(10) null null);
          display: flex;
          align-items: center;
          width: 100%;
          float: none;
          border: 0;
          border-radius: 0;
          font-family: "Source Serif 4", serif !important;
  
          @include mq($bp1024) {
            flex: 0 0 cols(9, 42);
            width: cols(9, 42);
          }
        }
      }

      .ot-powered-by,
      h1.ot-title {
        display: none;
      }

      .picker__nav--next,
      .picker__nav--prev {
        &:focus-visible {
          position: absolute;
        }
      }

      .picker__table {
        td {
          @include margin(0);
          @include padding(0);
        }

        .picker__day {
          @include margin(0);
          @include padding(0);
          width: rem(25);
          height: rem(25);
          border: 2px solid transparent;

          &--infocus:hover,
          &--highlighted {
            border-color: $color-raleigh-red;
          }
        }
      }
    }
  }
}

.cc--ctas-block {
  @include maxwidth;
  @include component-box-shadow;

  .c--ctas-block {
    @include padding(rem(15) cols(4) rem(25) cols(4));

    @include mq($bp1024) {
      display: flex;
    }

    @include mq($bp1024) {
      @include padding(rem(38) cols(3));
    }

    .f--section-title {
      display: flex;
      align-items: center;
    }

    .links-container {

      @include mq($bp1024max) {
        @include margin(rem(10) null null null);
      }

      @include mq($bp768) {
        display: flex;
        flex: 0 0 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: rem(10);
        row-gap: rem(10);
      }

      @include mq($bp1024) {
        @include margin(null null null cols(1, 42));
        flex: 0 0 cols(35, 42);
      }

      .f--link {

        &:not(:last-child) {
          @include mq($bp768max) {
            @include margin(null null rem(10) null);
          }
        }

        a {
          @include mq($bp768max) {
            width: 100%;
          }

          @include button(
            $outline: true,
            $hover-bg-color: null,
            $btn-height-desktop: rem(50)
          );
        }
      }
    }
  }

  &.red {
    .c--ctas-block {
      .links-container {
        .f--link {

          a {
            @include button(
              $outline: false,
              $btn-height-desktop: rem(50)
            );
          }
        }
      }
    }
  }
}

.cc--embed-qualtrics {
  @include maxwidth;

  .c--embed-qualtrics {
    @include component-padding;
    @include padding(null cols(4));

    @include mq($bp1024) {
      @include padding(null cols(3));
    }
  }
}

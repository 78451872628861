@mixin outline($color: $color-focus) {
  html[data-whatinput='keyboard'] & {
    position: relative;
    z-index: 99;
    outline: rem(3) solid $color;
  }

  html[data-whatinput='mouse'] & {
    outline: none;
  }
}

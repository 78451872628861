.cc--search-results {
  @include maxwidth;

  .c--search-results {
    @include padding(null cols(4));

    @include mq($bp1024) {
      @include padding(null cols(9));
    }

    .search-items-wrapper {
      @include no-bullet;
      @include padding(rem(25) null null);
      border-top: rem(1) solid $color-nicholson-gray;

      @include mq($bp768) {
        @include padding(rem(35) null null);
      }

      .search-item {
        @include padding(null null rem(25));
        border-bottom: rem(1) solid $color-nicholson-gray;

        &:not(:last-child) {
          @include margin(null null rem(25));
        }

        .f--cta-title {
          h3 {
            @extend %font-source-serif-regular;
            @include font-size(22, 32);
            color: $color-ludwell-black;

            @include mq($bp768) {
              @include font-size(26, 36);
            }

            a {
              color: inherit;
              text-decoration: none;

              @include hover {
                text-decoration: underline;
              }
            }
          }
        }

        .f--description {
          @include margin(rem(5) null null);

          p {
            @extend %font-source-sans-regular;
            @include font-size(15, 25);
            
            @include mq($bp768) {
              @include font-size(16, 26);
            }
          }
        }
      }
    }

    .search-results-data {
      &:not(:first-child) {
        @include margin(rem(25) null null);
      }

      &[data-show-more="False"] {
        @include padding(null null rem(50));

        @include mq($bp768) {
          @include padding(null null rem(100));
        }
      }
    }
  }
}
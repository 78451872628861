.cc--hero-home {
  position: relative;
  overflow: hidden;
  background-color: $color-black;

  @include mq($bp1024) {
    height: 100vh;
    background-color: transparent;
  }

  .c--hero-home {
    .inner-wrapper {
      @include mq($bp1024) {
        @include centered(x);
        @include maxwidth(calc(100% - #{cols(5)}));
        z-index: 1;
        bottom: 0;
        width: 100%;
      }

      @include mq($bp1800) {
        @include maxwidth;
      }

      .flex-inner-wrapper {
        @include padding(null cols(4) rem(25));

        @include mq($bp1024) {
          @include padding(null 0 rem(50));
          display: grid;
          z-index: 1;
          bottom: 0;
          grid-template-columns: cols(20, 43) cols(21, 43);
          align-items: end;
          column-gap: cols(2, 43);
        }

        @include mq($bp1800) {
          @include padding(null cols(2) null cols(3));
        }
      }
    }

    .mySwiper {
      aspect-ratio: 1 / 1;
      position: absolute;
      z-index: 1;
      width: 100%;

      @include mq($bp1024) {
        aspect-ratio: auto;
        height: 100%;
      }

      .image-container {
        position: relative;
        height: 100%;

        &::before {
          @include centered(x);
          content: "";
          bottom: 0;
          width: 100%;
          height: rem(100);
          background: linear-gradient(180deg, rgba($color-black, 0) 0%, rgba($color-black, 1) 100%);

          @include mq($bp768) {
            height: rem(500);
          }

          @include mq($bp1024) {
            height: rem(525);
          }
        }

        .f--image {
          height: 100%;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .f--ambient-video {
          display: none;

          @include mq($bp1024) {
            display: block;
            height: 100%;

            + .f--image {
              display: none;
            }
          }

          video {
            @include mq($bp1024) {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .video-controls {
          bottom: rem(15);

          button {
            background-color: $color-black;

            &:hover {
              background-color: $color-raleigh-red;
            }

            &:focus {
              @include outline($color: $color-focus-light);
              background-color: $color-raleigh-red;
            }

            svg {
              background-color: transparent;
            }
          }
        }
      }
    }

    .left {
      @include padding(calc(100vw - #{rem(50)}) null null);
      position: relative;
      z-index: 2;

      @include mq($bp768) {
        @include padding(calc(100% - #{rem(25)}) null null);
      }

      @include mq($bp1024) {
        @include padding(0 null null);
      }

      .f--page-title {
        @include mq($bp1024) {
          @include margin(null null rem(25));
          animation: fadeInUp 0.5s ease-out 0.5s forwards;
          opacity: 0;
        }

        &.has-link {
          @include margin(null null 0);
        }

        h1 {
          @extend %font-source-serif-semibold;
          @include font-size(40, 48);
          color: $color-white;

          @include mq($bp768) {
            @include font-size(64, 70);
          }
        }
      }

      .f--link {
        @include margin(rem(15) null null);

        @include mq($bp1024) {
          @include margin(rem(20) null null);
          animation: fadeInUp 0.5s ease-out 0.65s forwards;
          opacity: 0;
        }

        a {
          @include button(
            $outline: true,
            $text-color: $color-white,
            $outline-color: $color-york-yellow,
            $focus-color: $color-focus-light
          );
        }
      }
    }

    .right {
      @include padding(rem(25) null null);
      position: relative;
      z-index: 2;

      @include mq($bp768) {
        @include padding(rem(50) null null);
      }

      .pagination-wrapper {
        display: none;

        @include mq($bp768) {
          @include padding(null null rem(30));
          display: block;
          position: relative;
        }

        @include mq($bp1024) {
          @include padding(rem(4) rem(4) rem(34));
          transition: background-position $transition-duration-default $transition-easing-default;
          animation: leftToRight 1s ease-out 0.5s forwards;
          background-image: url('../images/interface/line-with-inset-arrow-left.svg');
          background-repeat: repeat-x;
          background-position-y: calc(100% - #{rem(4)});
          mask-size: 0%;
          mask-repeat: no-repeat;
          mask-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 1) 100%,
          rgba(0, 0, 0, 0)
          );
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          bottom: rem(-4);
          left: 0;
          width: 100%;
          height: rem(8);
          border-right: rem(1) solid $color-york-yellow;
          border-left: rem(1) solid $color-york-yellow;

          @include mq($bp1024) {
            bottom: rem(0.5);
          }
        }

        &::after {
          @include centered(x);
          content: '';
          display: block;
          bottom: 0;
          width: 100%;
          height: rem(1);
          background-color: $color-york-yellow;

          @include mq($bp1024) {
            display: none;
          }
        }

        .hero-pagination {
          @include no-bullet;
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          column-gap: cols(2, 40);

          li {
            position: relative;
            text-align: center;

            @include mq($bp1024) {
              &:first-child {
                animation: fadeInUp 0.5s ease-out 0.5s forwards;
                opacity: 0;
              }

              &:nth-child(2) {
                animation: fadeInUp 0.5s ease-out 0.65s forwards;
                opacity: 0;
              }

              &:last-child {
                animation: fadeInUp 0.5s ease-out 0.8s forwards;
                opacity: 0;
              }
            }

            &:focus {
              @include outline($color: $color-focus-light);
            }

            &.active {
              z-index: 1;

              &::after {
                @include centered(x);
                content: '';
                display: block;
                bottom: rem(-31);
                width: rem(65);
                height: rem(12);
                background-image: url('../images/interface/interface-insetarrow-up-dark.svg');
                background-size: contain;
                filter: drop-shadow(0 rem(-1) 0 $color-york-yellow);

                @include mq($bp1024) {
                  display: none;
                }
              }

              span {
                @extend %font-source-sans-semibold;
              }
            }

            span {
              @extend %font-source-sans-regular;
              @include font-size(14, 18);
              color: $color-white;
              letter-spacing: rem(1.75);
              text-transform: uppercase;
              cursor: pointer;
            }
          }
        }
      }

      .swiper-buttons {
        display: flex;
        position: absolute;
        z-index: 1;
        top: rem(28);
        right: cols(-0.5);
        align-items: center;
        justify-content: space-between;
        width: rem(90);

        @include mq($bp768) {
          display: none;
        }

        &::before {
          @include centered(x);
          content: "";
          top: rem(-0.5);
          width: rem(1);
          height: calc(100% + #{rem(1)});
          background-color: rgba($color-white, 0.3);
        }

        .swiper-button-prev,
        .swiper-button-next {
          display: block;
          position: relative;
          top: 0;
          right: auto;
          left: auto;
          width: rem(30);
          height: rem(30);
          margin: 0;
          padding: 0;
          border: 0;
          background: transparent;

          &::after {
            content: "";
            display: block;
          }

          &.swiper-button-disabled {
            opacity: 1;

            svg path {
              fill: $color-nicholson-gray;
            }
          }

          svg path {
            transition: fill $transition-duration-default $transition-easing-default;
            fill: $color-york-yellow;
          }
        }
      }

      .mySwiper2 {
        position: static;
        overflow: visible;

        .swiper-wrapper {
          position: static;
          overflow: visible;

          .swiper-slide {
            position: static;
            width: 100%;
            height: 100%;
            overflow: visible;

            @include mq($bp1024) {
              @include padding(null null null rem(4));
            }

            .image-container {
              display: none;
              z-index: -1;
              left: 0;

              .f--image {
                aspect-ratio: 1 / 1;

                img {
                  height: 100%;
                  object-fit: cover;
                }
              }
            }

            .pane-title {
              @extend %font-source-sans-semibold;
              @include font-size(13, 13);
              @include padding(rem(12) rem(90) rem(25) null);
              display: block;
              position: relative;
              border-bottom: rem(1) solid $color-york-yellow;
              color: $color-white;
              letter-spacing: rem(1.25);
              text-transform: uppercase;

              @include mq($bp768) {
                display: none;
              }

              &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: rem(-4.5);
                left: 0;
                width: 100%;
                height: rem(8);
                border-right: rem(1) solid $color-york-yellow;
                border-left: rem(1) solid $color-york-yellow;
              }

              &::after {
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                bottom: rem(-1);
                left: rem(40);
                width: rem(44);
                height: rem(8);
                background-image: url('../images/interface/interface-insetarrow-up-dark.svg');
                background-size: contain;
                filter: drop-shadow(0 rem(-1) 0 $color-york-yellow);
              }
            }

            .f--description {
              @include margin(rem(15) null null);

              @include mq($bp768) {
                @include margin(rem(20) null null);
              }

              @include mq($bp1024) {
                opacity: 0;
              }

              &.animate {
                @include mq($bp1024) {
                  animation: fadeInUp 0.5s ease-out 0s forwards;
                }
              }

              p {
                @extend %font-source-sans-regular;
                @include font-size(14, 22);
                color: $color-white;

                a {
                  color: inherit;
                  text-decoration: underline;

                  @include hover {
                    text-decoration: none;
                  }
                }
              }
            }

            .f--link {
              @include margin(rem(5) null null);

              @include mq($bp768) {
                @include margin(rem(10) null null);
              }

              @include mq($bp1024) {
                @include margin(rem(20) null null);
              }

              @include mq($bp1024) {
                opacity: 0;
              }

              &.animate {
                @include mq($bp1024) {
                  animation: fadeInUp 0.5s ease-out 0s forwards;
                }
              }

              a {
                @include button-cta(
                  $text-color: $color-white,
                  $hover-color: $color-york-yellow,
                  $outline-color: $color-white,
                  $svg-color: $color-york-yellow
                );
              }
            }

            &:first-of-type {
              .f--link,
              .f--description {
                @include mq($bp1024) {
                  animation: fadeInUp 0.5s ease-out 0.825s forwards;
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(rem(50));
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes leftToRight {
  from {
    mask-size: 0%;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 70%,
      rgba(0, 0, 0, 0)
    );
  }

  to {
    mask-size: 100%;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 100%,
      rgba(0, 0, 0, 0)
    );
  }
}

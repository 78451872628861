.lc--two-column-reverse {
  .l--two-column-reverse {
    @include component-padding;
    @include maxwidth;

    .l--two-column-reverse-inner {
      @include mq($bp1024) {
        @include padding(null cols(3));
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }

      .lr--sidebar {
        @include mq($bp1024) {
          @include margin(null null null cols(2, 42));
          flex: 0 0 cols(14, 42);
        }
      }

      .lr--main {
        @include padding(null cols(4));

        @include mq($bp1024) {
          @include padding(null 0);
          flex: 0 0 cols(26, 42);
          width: cols(26, 42);
        }
      }
    }
  }
}

.cc--two-column-rich-text-with-ctas {
  @include maxwidth;

  .c--two-column-rich-text-with-ctas {
    @include padding(null cols(4));
    @include component-padding;

    @include mq($bp1024) {
      @include padding(null cols(3));
    }

    .inner-wrapper {
      display: flex;
      flex-direction: column-reverse;
      row-gap: rem(35);

      @include mq($bp1024) {
        flex-direction: row;
        column-gap: cols(2, 42);
      }

      .text-container {

        @include mq($bp1024) {
          flex: 0 0 cols(26, 42);
        }
      }

      .ctas-container {
        @include mq($bp1024) {
          flex: 0 0 cols(14, 42);
        }

        .ctas-inner {
          @include component-box-shadow;
          @include padding(rem(25) cols(4, 40) rem(25) cols(4, 40));

          @include mq($bp768) {
            @include padding(rem(35) null rem(35) null);
          }

          @include mq($bp1024) {
            @include padding(null cols(2, 14) null cols(2, 14));
          }

          .ctas-title {
            @extend %font-source-sans-regular;
            @include margin(null null rem(15) null);
            @include font-size(18, 28);
            display: block;
            color: $color-ludwell-black;
            letter-spacing: rem(4);
            text-transform: uppercase;

            @include mq($bp1024max) {
              text-align: center;
            }

            @include mq($bp768) {
              @include margin(null null rem(20) null);
              @include font-size(22, 32);
              letter-spacing: rem(5);
            }
          }

          .links-container {
            .f--link {

              &:not(:last-child) {
                @include margin(null null rem(15) null);
              }

              a {
                @include button(
                  $btn-height-mobile: rem(60)
                ) {
                  width: 100%;
                };
              }
            }
          }
        }
      }
    }
  }
}

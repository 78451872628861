.tc--packages-detail {
  .content-main {
    .cc--rich-text {
      @include margin(0);
      @include padding(0);

      .c--rich-text {
        @include padding(0);

        .inner-wrapper {
          @include padding(0);
        }
      }
    }
  }

  .content-bottom {
    @include padding(null null rem(50));

    @include mq($bp768) {
      @include padding(null null rem(100));
    }

    .cc--component-container {
      &:not(.cc--multi-number-input-dropdown):not(.cc--booking-widget-hotel):not(.cc--ctas-block) {
        @include padding(0 null rem(35));

        @include mq($bp768) {
          @include padding(0 null rem(50));
        }
      }

      .c--component:not(.c--ctas-block):not(.c--contact-info-cards-grid) {
        @include padding(0 null);

        &.c--interactive-content-pane,
        &.c--related-content {
          .inner-wrapper {
            @include padding(0 null);
          }
        }
      }
    }
  }
}

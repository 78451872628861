.cc--article-resource-list {
  @include maxwidth;
  position: relative;
  overflow: hidden;

  .c--article-resource-list {
    @include padding(rem(35) cols(4));

    @include mq($bp768) {
      @include padding(rem(50) null);
    }

    @include mq($bp1024) {
      @include padding(null cols(3));
    }

    .header-container {
      .f--section-title {
        h2 {
          text-align: center;
        }
      }

      .f--description {
        p {
          text-align: center;

          @include mq($bp768) {
            @include font-size(18, 34);
          }
        }
      }
    }

    .cards-container {
      @include margin(rem(25) null null);

      @include mq($bp768) {
        @include margin(rem(35) null null);
      }
    }

    .article-card {
      @include padding(null null rem(25));
      position: relative;
      border-bottom: rem(1) solid $color-york-yellow;

      &:not(:last-child) {
        @include margin(null null rem(25));
      }

      @include mq($bp768) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      @include mq($bp1024) {
        @include padding(null null rem(35));
        border-left: rem(1) solid $color-york-yellow;

        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: $color-york-yellow;
        }

        &::before {
          top: 0;
          left: rem(-4.5);
          width: rem(8);
          height: rem(1);
        }

        &::after {
          right: 0;
          bottom: rem(-4.5);
          width: rem(1);
          height: rem(8);
        }
      }

      .image-container {
        @include margin(null null rem(20));
        position: relative;

        @include mq($bp768) {
          @include margin(null null 0);
          flex: 0 0 cols(19, 40);
        }

        @include mq($bp1024) {
          flex: 0 0 cols(12, 42);
        }
      }

      .text-container {
        @include mq($bp768) {
          flex: 0 0 cols(19, 40);
        }

        @include mq($bp1024) {
          @include padding(rem(25) cols(5, 42) null cols(2, 42));
          flex: 0 0 cols(30, 42);
        }

        .eyebrow-container {
          @include margin(null null rem(10));
          display: flex;
          flex-wrap: wrap;

          .f--eyebrow {
            @include margin(null null 0);
            position: relative;

            &:not(:last-child) {
              &::after {
                @extend %font-source-sans-regular;
                @include padding(null rem(7.5) null rem(5));
                @include font-size(12, 22);
                content: "|";
                display: inline-flex;
                color: $color-ludwell-black;
              }
            }

            span {
              color: $color-ludwell-black;
            }
          }
        }

        .f--cta-title {
          @include mq($bp768) {
            flex: 0 0 cols(25, 40);
          }

          h3 {
            @extend %font-source-serif-regular;
            @include font-size(22, 32);
            color: $color-ludwell-black;

            @include mq($bp768) {
              @include font-size(26, 36);
            }

            a {
              color: inherit;
              text-decoration: none;

              @include hover {
                text-decoration: underline;
              }
            }
          }
        }

        .f--description {
          @include margin(rem(5) null null);

          p {
            @include font-size(15, 25);

            @include mq($bp768) {
              @include font-size(16, 28);
            }
          }
        }

        .date {
          @extend %font-source-sans-regular;
          @include font-size(14, 22);
          @include margin(rem(20) null null);
          display: block;
          color: $color-black;
        }
      }
    }
  }
}

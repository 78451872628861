.cc--branding-header {
  .c--branding-header {
    .logo-wrapper {
      @include vertical-align-flex;
      min-height: rem(80);

      @include mq($bp768) {
        min-height: rem(115);
      }

      @include mq($bp1024) {
        @include padding(null rem(4) null);
        min-height: rem(120);
      }

      a {
        display: block;
        font-size: 0;
        line-height: 1;
        text-decoration: none;

        .transparent-header & {
          &:focus {
            @include outline($color: $color-focus-light);
          }
        }

        .main-menu-open & {
          &:focus {
            @include outline($color: $color-focus);
          }
        }

        svg {
          width: 100%;
          max-width: rem(260);
          height: auto;

          @include mq($bp768) {
            max-width: rem(320);
          }

          @include mq($bp1024) {
            max-width: rem(350);
          }

          .transparent-header & path {
            fill: $color-white;
          }

          .mobile-menu-open & path {
            @include mq($bp1024max) {
              fill: $color-raleigh-red;
            }
          }

          .main-menu-open & path {
            @include mq($bp1024) {
              fill: $color-raleigh-red;
            }
          }
        }
      }
    }
  }

  .home & {
    @include mq($bp1024) {
      animation: leftToRight 1s ease-out 0.5s forwards;
      mask-size: 0%;
      mask-repeat: no-repeat;
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 1) 70%,
        rgba(0, 0, 0, 0)
      );
    }
  }
}

@keyframes leftToRight {
  from {
    mask-size: 0%;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 70%,
      rgba(0, 0, 0, 0)
    );
  }

  to {
    mask-size: 100%;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 100%,
      rgba(0, 0, 0, 0)
    );
  }
}

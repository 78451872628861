.cc--spotlight {
  @include maxwidth;

  .c--spotlight {
    @include padding(rem(25) cols(4));

    @include mq($bp768) {
      @include padding(rem(50) cols(3));
    }

    @include mq($bp1024) {
      @include padding(null cols(2));
    }

    .inner-wrapper {
      @include component-box-shadow;
      display: flex;
      flex-direction: column-reverse;


      @include mq($bp1024) {
        flex-direction: row;
        align-items: center;
      }

      .text-container {
        @include padding(rem(25) cols(4, 40));

        @include mq($bp768) {
          @include padding(null cols(3, 42));
        }

        @include mq($bp1024) {
          @include padding(rem(50) cols(4, 44));
          flex: 0 0 cols(22, 44);
        }

        .f--eyebrow {
          @include margin(null null rem(10) null);
        }

        .f--description {
          @include margin(rem(5) null null null);

          p {
            @include font-size(14, 22);
          }
        }

        .links-container {
          @include margin(rem(15) null null null);

          .f--link {
            a {
              @include button-cta;
            }
          }
        }
      }

      .image-container {

        @include mq($bp1024) {
          flex: 0 0 cols(22, 44);
          align-self: stretch;
        }

        .f--image {
          height: 100%;

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }


  &.image-left {
    .c--spotlight {
      .inner-wrapper {
        @include mq($bp1024) {
          flex-direction: row-reverse;
        }
      }
    }
  }
}

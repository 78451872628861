.tc--event-detail {
  .content-main {
    @include padding(null null rem(50));

    @include mq($bp768) {
      @include padding(null null rem(100));
    }

    .cc--rich-text {
      @include margin(null null 0);
      @include padding(0 null);

      .c--rich-text {
        @include padding(0 null);

        .inner-wrapper {
          @include padding(0 null);
        }
      }
    }

    .cc--component-container {
      &:not(:last-child) {
        @include padding(0 null rem(35));

        @include mq($bp768) {
          @include padding(0 null rem(50));
        }
      }

      &.cc--accordions {
        @include margin(0 null);
      }

      .c--component {
        @include padding(0 null);
      }
    }
  }
}

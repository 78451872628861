.cc--cascading-features {
  @include maxwidth;
  position: relative;
  overflow: hidden;

  .c--cascading-features {
    @include padding(rem(35) null);

    @include mq($bp768) {
      @include padding(rem(50) cols(4));
    }

    @include mq($bp1024) {
      @include padding(rem(75) 0);
    }
  }

  &.short {
    .c--cascading-features {
      @include mq($bp768) {
        @include padding(rem(75) 0);
      }
    }
  }
}

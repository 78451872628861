.cc--card {
  flex: 0 0 cols(14, 44);

  .c--card {
    height: 100%;

    .card {
      height: 100%;

      &.links-2 {
        .card-inner {
          height: calc(100% - #{rem(100)});
        }
      }

      &.links-zero {
        .card-inner {
          height: 100%;
        }
      }

      &:has(.bottom-link:nth-child(2)) {
        .card-inner {
          height: calc(100% - #{rem(100)});
        }
      }

      &:not(:has(.bottom-links-container)) {
        .card-inner {
          height: 100%;
        }
      }

      .card-inner {
        position: relative;
        height: calc(100% - #{rem(50)});
        overflow: hidden;
      }

      .f--image {
        position: relative;
        width: 100%;
        height: 100%;

        &::before {
          content: '';
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 50%;
          opacity: 0.85;
          background: linear-gradient(
            to bottom,
            $color-black 0%,
            transparent 100%
          );
        }

        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 40%;
          opacity: 0.85;
          background: linear-gradient(
            to top,
            $color-black 0%,
            transparent 100%
          );
        }

        img {
          aspect-ratio: 2 / 3;
          object-fit: cover;
          height: 100%;
        }
      }

      &.is-closed {
        .f--image {
          img {
            filter: grayscale(1);
          }
        }
      }

      .tabs {
        @include margin(null cols(1, 14));
        @include padding(rem(25) null rem(16) null);
        display: flex;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        justify-content: space-between;
        width: calc(100% - #{cols(2, 14)});
        background-image: url('../images/interface/line-with-inset-arrows.svg');
        background-repeat: repeat-x;
        background-position: center bottom;

        &.first {
          background-image: url('../images/interface/line-with-inset-arrows-yellow.svg');
          background-position: left bottom;
        }

        &.last {
          background-image: url('../images/interface/line-with-inset-arrows.svg');
          background-position: right bottom;
        }

        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          background-color: $color-york-yellow;
        }

        &::before {
          bottom: rem(-3);
          left: 0;
          width: rem(1);
          height: rem(8);
        }

        &::after {
          right: 0;
          bottom: rem(-3);
          width: rem(1);
          height: rem(8);
        }

        &.tab-2,
        &.tab-3 {
          .tab {
            &:focus {
              @include outline($color: $color-focus);
            }
          }
        }
      }

      .tab {
        @extend %font-source-serif-semibold-italic;
        @include padding(0);
        @include font-size(14);
        position: relative;
        border: 0;
        outline: none;
        background: transparent;
        color: $color-white;
        letter-spacing: rem(1.75);
        text-transform: uppercase;
        cursor: pointer;

        // &[aria-selected='true'] {}

        // &[aria-selected='false'] {}

        // @include hover {}

        &:focus {
          @include outline($color: $color-focus-light);
        }
      }

      .card-meta {
        @include padding(rem(10) cols(1, 14) rem(15));
        display: flex;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        justify-content: center;
        width: 100%;
        border-top: rem(1) solid rgba($color-white, 0.3);

        > div {
          @extend %font-source-sans-semibold;
          @include font-size(12);
          display: inline-block;
          color: $color-white;

          &:not(:last-child) {
            @include margin(null rem(20) null null);
          }

          svg {
            @include margin(null rem(5) null null);
            width: rem(20);
            height: rem(20);
            vertical-align: middle;
          }
        }
      }

      .bottom-links-container {
        .f--link {
          a {
            @extend %font-source-serif-semibold-italic;
            @include padding(rem(13) cols(1, 14) rem(13));
            @include font-size(14);
            display: block;
            max-height: rem(50);
            background-color: $color-randolph-burgundy;
            color: $color-white;
            letter-spacing: rem(2);
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;

            @include mq($bp1024) {
              @include font-size(16);
            }

            @include hover {
              background-color: $color-geddy-blue;
            }
          }

          &:last-child {
            a {
              background-color: $color-raleigh-red;
            }
          }
        }
      }

      // add class to card for not first card
      &.not-first {
        .tabs {
          &::before,
          &::after {
            background-color: $color-raleigh-red;
          }

          .tab {
            color: $color-ludwell-black;

            &[aria-selected='true'] {
              color: $color-raleigh-red;
            }
          }
        }

        .tabpanels {
          background-color: $color-franklin-white;
        }

        .card-meta {
          border-color: $color-nicholson-gray;

          > div {
            color: $color-ludwell-black;

            svg {
              path {
                fill: $color-ludwell-black;
              }
            }
          }
        }
      }

      .tabpanels {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes fade-out {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }

        .tabpanel {
          @include padding(rem(65) cols(1, 14) rem(55));
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow: auto;

          @include mq($bp768) {
            @include padding(rem(65) cols(1, 14) rem(65));
          }

          &.is-active {
            animation: fade-in 1s;
          }

          &.is-hidden {
            display: none;
            animation: fade-out 1s;
          }

          &:not(:first-child) {
            background-color: $color-franklin-white;
          }

          .status {
            @extend %font-source-serif-bold-italic;
            @include margin(auto null null null);
            @include font-size(16);
            color: $color-ludwell-black;
            letter-spacing: rem(2);
            text-align: center;
            text-transform: uppercase;
          }

          .f--eyebrow {
            @include margin(null null rem(5) null);
            text-align: center;
          }

          .f--sub-title {
            text-align: center;
          }

          .spacer-icon {
            @include margin(rem(25) null null null);
            text-align: center;
          }

          .text-sections {
            @include margin(rem(20) null null null);
            text-align: center;

            .text-section {
              &:not(:last-child) {
                @include margin(null null rem(20) null);
              }

              h4 {
                @extend %font-source-serif-semibold-italic;
                @include font-size(14, 16);
                letter-spacing: rem(1.75);
                text-transform: uppercase;

                @include mq($bp1024) {
                  @include font-size(16, 20);
                  letter-spacing: rem(2);
                }
              }

              .f--description {
                @include margin(rem(5) null null null);
                @extend %font-source-sans-regular;
                @include font-size(14, 22);
                color: $color-ludwell-black;
              }
            }
          }

          .location-container {
            @include margin(rem(25) null null null);
            text-align: center;

            a {
              @extend %font-source-sans-semibold;
              @include margin(null null null rem(-35));
              @include font-size(14);
              color: $color-raleigh-red;
              text-decoration: none;

              @include hover {
                text-decoration: underline;
              }

              @include mq($bp1024) {
                @include font-size(16);
              }

              svg {
                @include margin(null rem(5) null null);
                vertical-align: middle;
              }
            }
          }

          .f--description {
            @include padding(null cols(1, 12));

            &.card-description {
              @include margin(rem(15) null null null);
              overflow: auto;

              @include mq($bp1024) {
                @include margin(rem(25) null null null);
              }
            }
          }

          .links-container {
            @include margin(rem(15) null null null);
            @include padding(null cols(1, 12));

            .f--link {
              &:not(:last-child) {
                @include margin(null null rem(15) null);
              }

              a {
                @include button-cta;
              }
            }
          }

          &:first-child {
            .f--eyebrow,
            .f--sub-title,
            .status {
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

.cc--interactive-content-pane {
  position: relative;
  overflow: hidden;

  .c--interactive-content-pane {
    @include maxwidth;

    .background-image {
      position: absolute;
      top: rem(35);
      right: rem(-35);
      width: rem(200);

      @include mq($bp768) {
        top: rem(50);
        right: rem(-65);
        width: rem(350);
      }

      @include mq($bp1024) {
        top: auto;
        right: auto;
        bottom: rem(75);
        left: cols(14);
      }
    }

    .inner-wrapper {
      @include padding(rem(35) cols(4));

      @include mq($bp768) {
        @include padding(rem(50) null);
      }

      @include mq($bp1024) {
        @include padding(null cols(3) null cols(4));
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    .left {
      position: relative;
      z-index: 2;

      @include mq($bp1024) {
        display: flex;
        flex: 0 0 cols(20, 41);
        flex-direction: column;
        width: cols(20, 41);
      }

      .f--section-title {
        h2 {
          color: $color-black;
        }
      }

      .select-container {
        @include margin(rem(15) null);
        position: relative;
        background-color: $color-franklin-white;

        @include mq($bp768) {
          @include margin(rem(10) null rem(20));
        }

        @include mq($bp1024) {
          display: none;
        }

        select {
          @extend %font-source-sans-regular;
          @include padding(null rem(50) null rem(15));
          @include font-size(14, 22);
          width: 100%;
          height: rem(50);
          border: 0;
          background: transparent;
          background-image: none;
          box-shadow: none;
          color: $color-ludwell-black;
          text-overflow: ellipsis;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          @include mq($bp768) {
            height: rem(65);
          }

          @include mq($bp1024) {
            @include font-size(18, 24);
          }

          &::-ms-expand {
            display: none;
          }
        }

        svg {
          @include centered('y');
          right: rem(15);
          width: rem(20);
          height: rem(20);
          pointer-events: none;
        }
      }

      .pagination-wrapper {
        @include margin(rem(25) null null);

        @include mq($bp1024max) {
          display: none;
        }
      }

      .interactive-content-pane-pagination {
        @include no-bullet;
        position: relative;

        li {
          @include mq($bp1024) {
            @include padding(null cols(3, 20) null null);
            position: relative;
          }

          &:not(:last-child) {
            @include margin(null 0 rem(25) null);
          }

          span {
            @extend %font-source-sans-regular;
            @include margin(null null null rem(-20));
            @include padding(null rem(20) 0);
            @include font-size(22, 32);
            display: inline-block;
            position: relative;
            border: 0;

            color: $color-black;
            letter-spacing: rem(5);
            text-transform: uppercase;
            cursor: pointer;
          }

          .description {
            @include margin(rem(5) null null);
            display: none;

            p {
              @extend %font-source-sans-regular;
              @include font-size(15, 25);

              @include mq($bp768) {
                @include font-size(16, 28);
              }

              a {
                color: $color-raleigh-red;

                @include hover {
                  text-decoration: none;
                }
              }
            }
          }

          &.active {
            &::before {
              @include mq($bp1024) {
                content: '';
                position: absolute;
                top: rem(15);
                left: cols(-11);
                width: calc(100% + #{cols(8)});
                height: rem(1);
                background-color: $color-york-yellow;
              }
            }

            &::after {
              @include mq($bp1024) {
                content: '';
                display: block;
                position: absolute;
                z-index: 4;
                top: rem(-25);
                right: rem(-14);
                width: rem(15);
                height: rem(81);
                background-image: url('../images/interface/interface-insetarrow-right.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
              }
            }

            span {
              @extend %font-source-sans-semibold;
              background-color: $color-white;
            }

            .description {
              @include mq($bp1024) {
                display: block
              }
            }
          }
        }
      }
    }

    .right {
      position: relative;
      z-index: 1;

      @include mq($bp1024) {
        @include padding(0 0 null);
        @include margin(0 null null);
        position: relative;
        flex: 0 0 cols(21, 41);
        width: cols(21, 41);
        overflow: visible;
      }

      .swiper {
        display: none;
        z-index: 3;
        overflow: visible;

        &.swiper-initialized {
          display: block;
        }

        .swiper-wrapper {
          .swiper-slide {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            h3 {
              @include visuallyhidden;
            }

            .image-container {
              @include margin(rem(15) null null);
              position: relative;
              order: 2;

              .f--image {
                position: relative;

                &::after {
                  @include centered('x');
                  content: '';
                  display: block;
                  position: absolute;
                  z-index: 5;
                  bottom: rem(-1);
                  width: rem(65);
                  height: rem(12);
                  background-image: url('../images/interface/interface-insetarrow-up.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: cover;

                  @include mq($bp768) {
                    display: none;
                  }
                }
              }

              @include mq($bp768) {
                @include margin(rem(25) null null);

                &::after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: rem(150);
                  background-image: linear-gradient(180deg, rgba($color-black,0.00) 0%, rgba($color-black,0.95) 100%);
                }
              }

              @include mq($bp1024) {
                @include margin(0 null null);
              }
            }

            .f--description {
              @include margin(0 null null);
              order: 1;

              @include mq($bp1024) {
                @include visuallyhidden;
              }

              p {
                @include font-size(15, 25);

                @include mq($bp768) {
                  @include font-size(16, 28);
                }
              }
            }

            .links-container {
              @include margin(rem(15) null null null);

              @include mq($bp768) {
                display: flex;
                position: absolute;
                z-index: 1;
                bottom: rem(25);
                left: cols(2, 40);
                align-items: center;
                justify-content: space-between;
                width: calc(100% - #{cols(4, 40)});
              }

              .f--link {
                @include margin(0 null null);

                @include mq($bp768) {
                  width: cols(17, 36);
                }

                a {
                  @include button($outline: true);
                  width: 100%;

                  @include mq($bp768) {
                    box-shadow: 0 0 0 rem(1.5) inset $color-york-yellow;
                    color: $color-white;

                    @include hover {
                      box-shadow: 0 0 0 rem(3) inset $color-york-yellow;
                      color: $color-york-yellow;
                    }

                    &:focus {
                      outline-color: $color-white;
                      box-shadow: 0 0 0 rem(3) inset $color-york-yellow;
                      color: $color-york-yellow;
                    }
                  }
                }

                &:first-child {
                  a {
                    @include button;

                    &:focus {
                      outline-color: $color-white;
                    }
                  }
                }

                &:not(:first-child) {
                  @include margin(rem(10) null null);

                  @include mq($bp768) {
                    @include margin(0 null null);
                  }
                }
              }
            }
          }
        }
      }
    }

    .mobile-cta {
      @include mq($bp1024) {
        display: none;
      }

      &::before {
        @include margin(rem(20) null null);
        content: '';
        display: block;
        position: relative;
        width: 100%;
        height: rem(1);
        background-color: $color-nicholson-gray;

        @include mq($bp768) {
          display: none;
        }
      }
    }

    .desktop-cta {
      display: none;

      @include mq($bp1024) {
        display: block;
      }
    }

    .global-cta {
      @include margin(rem(20) null null);

      @include mq($bp768) {
        @include margin(rem(25) null null);
      }

      @include mq($bp1024) {
        @include margin(rem(20) null null);
      }

      a {
        @include button($outline: true);
        width: 100%;

        @include mq($bp768) {
          width: auto;
          min-width: rem(272);
        }

        @include mq($bp1024) {
          min-width: rem(225);
        }
      }
    }
  }
}

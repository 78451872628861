.cc--feature-image-carousel {
  @include maxwidth;
  position: relative;
  z-index: 1;

  .c--feature-image-carousel {
    @include padding(rem(35) cols(4) null);

    @include mq($bp768) {
      @include padding(rem(50) 0 null);
    }

    @include mq($bp1024) {
      @include padding(0 cols(2) null cols(4));
    }

    .inner-wrapper {
      @include mq($bp1024) {
        display: flex;
        align-items: flex-start;
        column-gap: cols(3, 42);
      }
    }

    .mySwiper {
      position: relative;
      overflow: visible;

      @include mq($bp1024) {
        flex: 0 0 cols(28, 42);
        width: cols(28, 42);
      }

      .accent {
        display: block;
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: cols(4, 40);
        width: rem(1);
        height: rem(65);
        background-color: $color-york-yellow;

        @include mq($bp768) {
          bottom: rem(-25);
          left: cols(3, 40);
          height: rem(75);
        }

        @include mq($bp1024) {
          top: rem(91);
          right: cols(-2, 28);
          left: auto;
          width: cols(3, 28);
          height: rem(1);
        }

        &::before {
          @include centered(x);
          content: "";
          bottom: 0;
          width: rem(9);
          height: 100%;
          border-top: rem(1) solid $color-york-yellow;
          border-bottom: rem(1) solid $color-york-yellow;

          @include mq($bp1024) {
            bottom: rem(-4.5);
            width: 100%;
            height: rem(9);
            border-top: 0;
            border-right: rem(1) solid $color-york-yellow;
            border-bottom: 0;
            border-left: rem(1) solid $color-york-yellow;
          }
        }
      }

      .swiper-wrapper {
        .swiper-slide {
          transition: opacity $transition-duration-default $transition-easing-default;
          opacity: 0 !important;

          &.swiper-slide-active {
            opacity: 1 !important;
          }
        }
      }

      .swiper-buttons {
        @include margin(rem(10) cols(-0.5, 40) null auto);
        display: flex;
        position: relative;
        z-index: 1;
        align-items: center;
        justify-content: space-between;
        width: rem(90);

        @include mq($bp768) {
          @include margin(0 null null 0);
          position: absolute;
          top: calc(100% + #{rem(25)});
          right: 0;
          flex-direction: column;
          width: rem(50);
          height: rem(90);
          row-gap: rem(30);
        }

        @include mq($bp1024) {
          top: 50%;
          right: calc(100% + #{rem(25)});
          transform: translateY(-50%);
        }

        &::before {
          @include centered(x);
          content: "";
          top: rem(-0.5);
          width: rem(1);
          height: calc(100% + #{rem(1)});
          background-color: $color-nicholson-gray;

          @include mq($bp768) {
            top: 50%;
            width: rem(40);
            height: rem(1);
          }
        }

        .swiper-button-prev,
        .swiper-button-next {
          display: block;
          position: relative;
          top: 0;
          right: auto;
          left: auto;
          width: rem(30);
          height: rem(30);
          margin: 0;
          padding: 0;
          border: 0;
          background: transparent;

          &::after {
            content: "";
            display: block;
          }

          &.swiper-button-disabled {
            opacity: 1;

            svg path {
              fill: $color-nicholson-gray;
            }
          }

          svg path {
            transition: fill $transition-duration-default $transition-easing-default;
            fill: $color-raleigh-red;
          }

          &:hover,
          &:focus-visible {
            svg path {
              fill: $color-randolph-burgundy;
            }
          }
        }
      }
    }

    .text-container {
      @include padding(rem(15) null null);

      @include mq($bp768) {
        @include padding(rem(50) cols(5, 40) null null);
      }

      @include mq($bp1024) {
        @include padding(rem(75) 0 null null);
      }

      .f--section-title {
        h2 {
          @extend %font-source-serif-regular-italic;
          @include font-size(22, 26);
          letter-spacing: rem(8.5);
          text-transform: uppercase;

          @include mq($bp768) {
            @include font-size(26, 30);
            letter-spacing: rem(10);
          }
        }
      }

      .f--description {
        @include margin(rem(5) null null);

        p {
          @include font-size(16, 30);
          letter-spacing: rem(0.2);

          @include mq($bp768) {
            @include font-size(18, 34);
            letter-spacing: rem(0.25);
          }

          &:not(:last-child) {
            @include margin(null null rem(10));
          }
        }
      }

      .f--link {
        @include margin(rem(15) null null);
        display: inline-block;
        position: relative;

        @include mq($bp768) {
          @include margin(rem(20) null null);
        }

        &::before {
          @include mq($bp1024) {
            @include triangle(left, $color-raleigh-red, 8px);
            @include centered(y);
            right: calc(100% + #{rem(25)});
          }
        }

        a {
          @include button(
            $outline: true,
            $hover-bg-color: $color-randolph-burgundy
          );
        }
      }
    }
  }

  &.image-right {
    position: static;

    @include mq($bp768) {
      @include margin(rem(-25) null null);
    }

    .c--feature-image-carousel {
      @include mq($bp768) {
        @include padding(0 cols(4) null);
      }

      @include mq($bp1024) {
        @include padding(null 0 null);
      }

      .inner-wrapper {
        @include mq($bp1024) {
          flex-direction: row-reverse;
          column-gap: cols(2, 40);
        }
      }

      .mySwiper {
        @include mq($bp1024) {
          flex: 0 0 cols(25, 40);
          width: cols(25, 40);
        }

        .swiper-buttons {
          @include mq($bp1024) {
            right: auto;
            left: calc(100% + #{rem(25)});
          }
        }

        .accent {
          @include mq($bp1024) {
            right: auto;
            left: cols(-1, 25);
            width: cols(3, 25);
          }
        }
      }

      .text-container {
        @include mq($bp1024) {
          flex: 0 0 cols(13, 40);
        }

        .f--link {
          &::before {
            @include mq($bp1024) {
              @include centered(y);
              left: calc(100% + #{cols(1, 16)});
              border-right: 0;
              border-left: rem(8) solid $color-raleigh-red;
            }
          }
        }
      }
    }
  }
}

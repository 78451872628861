.cc--editorial-cards-grid {
  @include maxwidth;

  .c--editorial-cards-grid {
    @include padding(rem(35) null rem(25));

    @include mq($bp768) {
      @include padding(0 null rem(35) null);
    }

    @include mq($bp1024) {
      @include padding(rem(50) null rem(75) null);
    }

    .inner-wrapper {

      @include mq($bp768) {
        display: flex;
        flex-wrap: wrap;
      }

      .card {

        &.title-card {
          @include padding(null cols(4) rem(25));

          @include mq($bp768) {
            @include padding(rem(75) cols(3));
            flex: 0 0 cols(24);
          }

          @include mq($bp768-1024) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: $color-ludwell-black;
            color: $color-white;
          }

          @include mq($bp1024) {
            @include padding(0 cols(3) rem(35) cols(4));
            display: flex;
            position: relative;
            flex: 0 0 100%;
            align-items: center;
            justify-content: space-between;
          }

          &.has-desc-links:not(.no-title) {
            &::after {
              @include mq($bp1024) {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                width: rem(1);
                height: 100%;
                background-color: $color-york-yellow;
              }
            }

            &::before {
              @include mq($bp1024) {
                content: '';
                position: absolute;
                top: 0;
                left: calc(50% - #{rem(4)});
                width: rem(9);
                height: rem(1);
                background-color: $color-york-yellow;
              }
            }
          }

          .title-subtitle-container {

            @include mq($bp768-1024) {
              text-align: center;
            }

            @include mq($bp1024) {
              @include padding(rem(20) null null null);
              flex: 0 0 cols(18, 41);
            }
          }

          .description-link-container {

            @include mq($bp768-1024) {
              text-align: center;
            }

            @include mq($bp1024) {
              @include padding(rem(10) null null null);
              flex: 0 0 cols(18, 41);
            }
          }

          .f--sub-title {
            @include margin(rem(5) null null null);
          }

          .f--description {
            @include margin(rem(15) null null null);

            @include mq($bp1024) {
              @include margin(0 null null null);
            }

            p {
              @include mq($bp768) {
                @include font-size(18, 34);
              }

              a {
                color: $color-raleigh-red;

                @include mq($bp768) {
                  color: $color-white;
                }

                @include mq($bp1024) {
                  color: $color-raleigh-red;
                }
              }
            }
          }

          .f--link {
            @include margin(rem(15) null null null);

            @include mq($bp768) {
              @include margin(rem(20) null null null);
            }

            a {
              @include button(
                $outline: true,
                $hover-bg-color: $color-randolph-burgundy
              );

              @include mq($bp768) {
                @include button(
                  $outline: true,
                  $text-color: $color-white,
                  $outline-color: $color-york-yellow,
                  $focus-color: $color-focus-light
                );
              }

              @include mq($bp1024) {
                @include button(
                  $outline: true,
                  $hover-bg-color: $color-randolph-burgundy
                );
              }
            }
          }
        } // .title-card

        &.cta-card {
          position: relative;
          background-color: $color-ludwell-black;
          overflow-anchor: none;

          @include mq($bp768) {
            flex: 0 0 cols(24);
          }

          @include mq($bp1024) {
            flex: 0 0 cols(16);
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, transparent 0%, $color-black 100%);
          }

          .text-container {
            @include padding(null cols(4) rem(35) cols(4));
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
            color: $color-white;
            text-align: center;

            @include mq($bp768) {
              @include padding(null null rem(25) null);
            }

            @include mq($bp1024) {
              @include padding(null cols(2, 16) rem(50) cols(2, 16));
            }

            .description-link-container {
              @include padding(null null rem(4) null);

              @include mq($bp1024) {
                max-height: 0;
                overflow: hidden;
                transition: max-height $transition-duration-slow $transition-easing-default, opacity $transition-duration-slow $transition-easing-default;
                opacity: 0;
              }
            }

            .f--description {
              p {
                @include font-size(15, 25);

                @include mq($bp768) {
                  @include font-size(16, 28);
                  letter-spacing: rem(0.25);
                }

                a {
                  color: $color-white;
                }
              }
            }

            .f--link {
              @include margin(rem(15) null null);

              @include mq($bp768) {
                @include margin(rem(20) null null);
              }

              a {
                @include button(
                  $outline: true,
                  $text-color: $color-white,
                  $outline-color: $color-york-yellow,
                  $focus-color: $color-focus-light
                );
              }
            }
          }

          .f--image {
            height: 100%;

            img {
              height: 100%;
              object-fit: cover;
            }
          }

          @include mq($bp1024) {
            &:focus-within {
              .text-container {
                .description-link-container {
                  max-height: rem(400);
                  transition: max-height $transition-duration-slow $transition-easing-default, opacity $transition-duration-slow $transition-easing-default;
                  opacity: 1;
                }
              }
            }

            @include hover {
              .text-container {
                .description-link-container {
                  max-height: rem(400);
                  transition: max-height $transition-duration-slow $transition-easing-default, opacity $transition-duration-slow $transition-easing-default;
                  opacity: 1;
                }
              }
            }
          }
        } // .cta-card
      }
    }
  }
}

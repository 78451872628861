*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: transparent;
}

body {
  // @extend %font-davis-sans-regular;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  // background-color: $color-charcoal;

  &.search-open,
  &.main-menu-open {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.1;
}

a[href^='tel'] {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

p {
  margin-top: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  margin-top: 0;

  ul,
  ol {
    margin-bottom: 0;
  }
}

.skip-link {
  @include padding(rem(12));
  position: absolute;
  z-index: 1000;
  top: rem(30);
  left: rem(30);
  border: rem(1) solid $color-black;
  background-color: $color-white;
  color: $color-black;
  white-space: nowrap;

  &:not(:focus) {
    @include visuallyhidden;
  }

  html[data-whatinput='keyboard'] &:focus-visible {
    position: absolute;
    z-index: 1000;
  }
}

// CW Fonts
// Source Serif
%font-source-serif-regular {
  font-family: "Source Serif 4", serif;
  font-style: normal;
  font-weight: 400;
  font-optical-sizing: auto;
}

%font-source-serif-regular-italic {
  font-family: "Source Serif 4", serif;
  font-style: italic;
  font-weight: 400;
  font-optical-sizing: auto;
}

%font-source-serif-semibold {
  font-family: "Source Serif 4", serif;
  font-style: normal;
  font-weight: 600;
  font-optical-sizing: auto;
}

%font-source-serif-semibold-italic {
  font-family: "Source Serif 4", serif;
  font-style: italic;
  font-weight: 600;
  font-optical-sizing: auto;
}

%font-source-serif-bold {
  font-family: "Source Serif 4", serif;
  font-style: normal;
  font-weight: 700;
  font-optical-sizing: auto;
}

%font-source-serif-bold-italic {
  font-family: "Source Serif 4", serif;
  font-style: italic;
  font-weight: 700;
  font-optical-sizing: auto;
}

// Source Sans
%font-source-sans-regular {
  font-family: "Source Sans 3", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-optical-sizing: auto;
}

%font-source-sans-regular-italic {
  font-family: "Source Sans 3", sans-serif;
  font-style: italic;
  font-weight: 400;
  font-optical-sizing: auto;
}

%font-source-sans-semibold {
  font-family: "Source Sans 3", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-optical-sizing: auto;
}

%font-source-sans-semibold-italic {
  font-family: "Source Sans 3", sans-serif;
  font-style: italic;
  font-weight: 600;
  font-optical-sizing: auto;
}

%font-source-sans-bold {
  font-family: "Source Sans 3", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-optical-sizing: auto;
}

%font-source-sans-bold-italic {
  font-family: "Source Sans 3", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-optical-sizing: auto;
}

.cc--spacer {
  @include maxwidth;

  .c--spacer {
    height: rem(25);

    @include mq($bp768) {
      height: rem(50);
    }
  }
}

//  Visually hide text within an element
//   Used mostly for accessibility reasonse
//
//   No Params
//
//   Example Usage:
//
//     button {
//       span {
//         @include visuallyhidden;
//       }
//     }
//
//
@mixin visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
  white-space: nowrap;
  clip-path: inset(50%);
}

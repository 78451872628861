.cc--explore-cards {
  @include maxwidth;

  .c--explore-cards {
    @include padding(null cols(4));
    @include component-padding;

    @include mq($bp1024) {
      @include padding(null cols(2));
    }

    .f--section-title {
      text-align: center;

      @include mq($bp1024) {
        @include padding(null cols(9, 44));
      }
    }

    .cards-container {
      @include margin(rem(25) null null null);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: rem(25);

      @include mq($bp768) {
        flex-direction: row;
        column-gap: cols(2, 42);
      }

      @include mq($bp1024) {
        @include margin(rem(35) null null null);
        flex-direction: row;
        column-gap: cols(1, 44);
      }

      .cc--card {
        @include mq($bp768) {
          flex: 0 0 cols(20, 42);
        }

        @include mq($bp1024) {
          flex: 0 0 cols(14, 44);
        }
      }
    }
  }
}

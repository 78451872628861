.cc--resort-cards-list {
  @include padding(null cols(4));

  @include mq($bp1024) {
    @include padding(null 0);
    position: sticky;
    top: rem(15);
  }

  .c--resort-cards-list {
    .inner-wrapper {
      @include component-box-shadow;
      @include padding(rem(25) cols(4, 40));

      @include mq($bp768) {
        @include padding(rem(35) cols(3, 40));
      }

      @include mq($bp1024) {
        @include padding(rem(35) cols(2, 14));
        max-height: calc(100vh - #{rem(30)});
        overflow-y: auto;
      }
    }

    .resort-cards-list {
      @include margin(null null rem(15));

      @include mq($bp768) {
        @include margin(null null rem(25));
      }

      h3 {
        @extend %font-source-sans-regular;
        @include font-size(18, 28);
        letter-spacing: rem(4);
        text-transform: uppercase;

        @include mq($bp768) {
          @include font-size(22, 32);
          letter-spacing: rem(5);
        }
      }
    }

    .cards-container {
      @include margin(rem(15) null null null);

      @include mq($bp768) {
        display: grid;
        row-gap: rem(25);
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: rem(32);
      }

      @include mq($bp1024) {
        display: block;
      }

      .card {
        @include padding(null null rem(20));

        @include mq($bp768) {
          @include padding(null null rem(16));
        }

        &:not(:last-child) {
          @include margin(null null rem(20));
          border-bottom: rem(1) solid $color-nicholson-gray;

          @include mq($bp768) {
            @include margin(null null 0);
          }

          @include mq($bp1024) {
            @include margin(null null rem(20));
          }
        }

        .resort-info-card {
          h3 {
            @extend %font-source-serif-semibold;
            @include font-size(16, 26);

            @include mq($bp768) {
              @include font-size(18, 28);
            }
          }
        }

        .f--description {
          @include margin(rem(5) null null);

          p {
            @extend %font-source-sans-regular;
            @include font-size(14, 22);

            @include mq($bp768) {
              @include font-size(16, 28);
            }
          }
        }

        .f--link {
          @include margin(rem(10) null null null);

          @include mq($bp768) {
            @include margin(rem(15) null null null);
          }

          a {
            @include button-cta(
              $text-color: $color-black,
              $hover-color: $color-raleigh-red,
              $outline-color: $color-black,
              $svg-color: $color-raleigh-red
            );
          }
        }
      }
    }
  }
}

.cc--statistics {
  @include maxwidth;

  .c--statistics {
    @include padding(null cols(4));
    @include component-padding;

    .text-container {

      @include mq($bp1024) {
        @include padding(null cols(5, 40));
      }

      .f--description {
        p {
          @include mq($bp768) {
            @include font-size(18, 34);
          }
        }
      }
    }

    .cards-container {
      @include margin(rem(15) null null null);

      @include mq($bp768) {
        @include margin(rem(25) null null null);
      }

      @include mq($bp1024) {
        @include margin(rem(25) null null null);
        display: flex;
        flex-wrap: wrap;
        row-gap: rem(25);
      }

      .card {
        @include padding(null null rem(25) null);
        text-align: center;

        @include mq($bp768) {
          text-align: left;
        }

        @include mq($bp768-1024) {
          display: flex;
          align-items: center;
        }

        @include mq($bp1024) {
          @include padding(rem(20) null);
          position: relative;
          flex: 0 0 cols(12, 40);
          text-align: center;
        }

        &:not(:first-child) {
          @include mq($bp1024max) {
            @include padding(rem(15) null null null);
          }
        }

        &:last-child {
          @include mq($bp1024max) {
            @include padding(null null 0 null);
          }
        }

        &:not(:last-child) {

          @include mq($bp1024max) {
            border-bottom: rem(1) solid $color-nicholson-gray;
          }

          @include mq($bp1024) {
            &::after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              right: cols(-1, 12);
              width: rem(1);
              height: 100%;
              background-color: $color-nicholson-gray;
            }
          }
        }

        &:nth-child(3n+1) {
          @include mq($bp1024) {
            @include margin(null cols(1, 40) null null);
          }
        }

        &:nth-child(3n+2) {
          @include mq($bp1024) {
            @include margin(null cols(1, 40));
          }
        }

        &:nth-child(3n+3) {
          @include mq($bp1024) {
            @include margin(null null null cols(1, 40));

            &::after {
              display: none;
            }
          }
        }

        .stat-number {
          @extend %font-source-serif-semibold;
          @include font-size(80, 80);
          display: block;
          color: $color-raleigh-red;

          @include mq($bp768) {
            display: inline-block;
            vertical-align: middle;
          }

          @include mq($bp1024) {
            display: block;
          }
        }

        .stat-title {
          @extend %font-source-serif-semibold-italic;
          @include font-size(14, 16);
          display: block;
          color: $color-ludwell-black;
          letter-spacing: rem(1.75);
          text-transform: uppercase;

          @include mq($bp768) {
            @include margin(null null null rem(15));
            @include font-size(16, 20);
            display: inline-block;
            letter-spacing: rem(2);
            vertical-align: middle;
          }

          @include mq($bp1024) {
            @include margin(null null null 0);
            display: block;
          }
        }

      }
    }
  }
}

.f--input-checkbox {
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    display: inline-block;
    position: relative;
    flex: 0 0 rem(25);
    width: rem(25);
    height: rem(25);
    margin: 0;
    transition: background $transition-duration-default $transition-easing-default;
    border: rem(1) solid $color-nicholson-gray;
    outline: none;
    background: $color-white;
    vertical-align: top;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus-visible {
      outline: rem(3) solid $color-focus;
    }

    &:after {
      @include centered(x);
      content: '';
      display: block;
      z-index: 1;
      width: 100%;
      height: 100%;
      transition: opacity $transition-duration-fast $transition-easing-default;
      opacity: 1;
      background-image: url('../images/icons/icon-check.svg');
      background-size: cover;
    }

    &:checked {
      border: 0;
      background-color: $color-raleigh-red;
    }
  }

  label {
    @extend %font-source-sans-regular;
    @include margin(null null null rem(15));
    @include font-size(14, 18);
    display: inline-block;
  }
}

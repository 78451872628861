.cc--search-inline {
  @include maxwidth;
  overflow: hidden;

  .c--search-inline {
    @include padding(rem(20) cols(1, 12) null);

    @include mq($bp768) {
      @include padding(rem(50) cols(4) null);
    }

    @include mq($bp1024) {
      @include padding(null cols(9));
    }
  }

  form {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-bottom: rem(1) solid $color-nicholson-gray;
  }

  .fi--form-item {
    margin: 0;

    label {
      @include visuallyhidden;
    }

    input {
      @extend %font-source-serif-regular-italic;
      @include font-size(18, 30);
      @include padding(0 0 rem(5));
      width: 100%;
      border: 0;
      background: transparent;
      color: $color-ludwell-black;

      @include mq($bp768) {
        @include font-size(36, 46);
        @include padding(null null rem(12));
      }

      &:focus {
        outline: none;
      }

      @include placeholder {
        color: $color-ludwell-black;
      }
    }

    button {
      display: block;
      flex: 1 0 auto;
      width: rem(30);
      height: rem(30);
      min-height: 0;
      padding: 0;
      border: 0;
      background: transparent;
      background-image: url('../images/icons/icon-search-red.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: rem(30);
      font-size: 0;

      @include mq($bp768) {
        width: rem(40);
        height: rem(40);
        background-size: rem(40);
      }
  
      @include mq($bp1024) {
        width: rem(50);
        height: rem(50);
        background-size: rem(50);
      }

      @include hover {
        background-color: transparent;
      }
    }
  }
}

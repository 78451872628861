.cc--chapter-content-main {
  .c--chapter-content-main {
    .cc--chapter-jump-links {
      @include mq($bp1024) {
        position: sticky;
        top: rem(15);
      }
    }

    .content-main {
      @include maxwidth;
      @include padding(rem(35) null rem(50));

      @include mq($bp768) {
        @include padding(rem(50) null rem(100));
      }

      .cc--chapter {
        &:not(:last-child):not(.cc--ctas-block) {
          @include padding(0 null rem(50));

          @include mq($bp768) {
            @include padding(0 null rem(100));
          }
        }

        .c--component:not(.c--ctas-block):not(.c--modal-donation-form) {
          @include padding(0 null);

          &.c--chapter {
            > .f--section-title {
              @include padding(null null rem(25));

              @include mq($bp768) {
                @include padding(null null rem(50));
              }
            }
          }
        }

        .cc--component-container {
          &:not(:last-child):not(.cc--card) {
            @include padding(null null rem(35));

            @include mq($bp768) {
              @include padding(null null rem(50));
            }
          }
        }
      }
    }
  }
}

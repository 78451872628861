.f--section-title {
  h2 {
    @extend %font-source-serif-regular;
    @include font-size(40, 48);
    color: inherit;

    @include mq($bp768) {
      @include font-size(60, 70);
    }

    a {
      color: inherit;
      text-decoration: none;

      @include hover {
        text-decoration: underline;
      }
    }
  }

  &.ctas-block {
    h2 {
      @extend %font-source-serif-semibold;
      @include font-size(18, 28);
      color: $color-ludwell-black;
      letter-spacing: rem(-0.25);

      @include mq($bp768) {
        @include font-size(22, 32);
        letter-spacing: rem(1);
      }
    }
  }

  &.promo-banner {
    color: $color-ludwell-black;
    text-align: center;

    h2 {
      @extend %font-source-serif-regular-italic;
      @include font-size(22, 26);
      letter-spacing: rem(8.5);
      text-transform: uppercase;

      @include mq($bp768) {
        @include font-size(26, 30);
        letter-spacing: rem(10);
      }
    }
  }

  &.spotlight {
    h2 {
      @extend %font-source-serif-regular;
      @include font-size(18, 26);
      color: $color-black;

      @include mq($bp1024) {
        @include font-size(22, 32);
      }
    }
  }

  &.editorial-card {
    h2 {
      @include font-size(30, 40);

      @include mq($bp768) {
        @include font-size(36, 46);
      }
    }
  }

  &.donate-promo {
    h2 {
      @extend %font-source-serif-semibold-italic;
      @include font-size(22, 32);

      @include mq($bp768) {
        @include font-size(32, 42);
      }
    }
  }
}

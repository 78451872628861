.events-no-results {
  @include maxwidth;
  @include padding(rem(25) cols(4));

  @include mq($bp768) {
    @include padding(rem(35) cols(4));
  }

  @include mq($bp1024) {
    @include padding(rem(25) cols(3));
  }
}

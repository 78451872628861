.no_display {
  display: none;
}

.b2c {
  @include padding(rem(35) cols(4) 0);
  text-align: center;

  @include mq($bp768) {
    @include padding(rem(75) null null);
  }

  @include mq($bp1024) {
    @include padding(null cols(14));
  }

  .b2c {
    @include padding(0);
  }

  img {
    @include maxwidth(rem(350));
    width: auto;
  }

  .headline-1 {
    @extend %font-source-sans-regular;
    @include font-size(18, 24);
    @include margin(null null rem(15));
    letter-spacing: rem(4);
    text-transform: uppercase;
  }

  .intro {
    @include margin(null null rem(10));

    h2 {
      @extend %font-source-serif-regular;
      @include font-size(22, 32);
      @include margin(null null rem(25));

      @include mq($bp768) {
        @include font-size(28, 36);
      }
    }
  }

  .entry {
    display: flex;
    flex-direction: column;

    @include mq($bp1024) {
      @include padding(null cols(4, 20));
    }

    &-item {
      &:first-child {
        @include margin(null null rem(25));
      }

      &:not(:first-child) {
        @include margin(null null rem(25));
        @include padding(null null rem(32));
        position: relative;
      }

      label {
        @extend %font-source-sans-regular;
        @include font-size(12, 14);
        @include margin(null null rem(5));
        display: block;
        letter-spacing: rem(1.5);
        text-align: left;
        text-transform: uppercase;

        @include mq($bp768) {
          @include font-size(14, 18);
        }
      }

      input {
        @extend %font-source-sans-regular;
        @include padding(null rem(40) null rem(15));
        @include font-size(14, 20);
        position: relative;
        width: 100%;
        height: rem(50);
        border: 0;
        background-color: $color-franklin-white;
        color: $color-ludwell-black;
        appearance: none;

        @include mq($bp1024) {
          @include font-size(16, 24);
        }

        &:focus-visible {
          outline: rem(3) solid $color-focus;
        }

        @include placeholder {
          color: $color-ludwell-black;
        }
      }

      .password-label {
        a {
          @extend %font-source-sans-regular;
          position: absolute;
          bottom: 0;
          left: 0;
          color: $color-raleigh-red;
        }
      }
    }
  }

  .buttons {
    @include margin(rem(25) null null);

    button {
      @include button;
      align-items: center;
      width: 100%;
      border: 0;
      cursor: pointer;
    }
  }

  .rememberMe {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      display: inline-block;
      position: relative;
      flex: 0 0 rem(25);
      width: rem(25);
      height: rem(25);
      margin: 0;
      transition: background $transition-duration-default
        $transition-easing-default;
      border: rem(1) solid $color-nicholson-gray;
      outline: none;
      background: $color-white;
      vertical-align: top;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;

      &:focus-visible {
        outline: rem(3) solid $color-focus;
      }

      &:after {
        @include centered(x);
        content: "";
        display: block;
        z-index: 1;
        width: 100%;
        height: 100%;
        transition: opacity $transition-duration-fast $transition-easing-default;
        opacity: 1;
        background-image: url("../images/icons/icon-check.svg");
        background-size: cover;
      }

      &:checked {
        border: 0;
        background-color: $color-raleigh-red;
      }
    }

    label {
      @extend %font-source-sans-regular;
      @include margin(null null null rem(15));
      @include font-size(14, 18);
      display: inline-block;
      cursor: pointer;
    }
  }

  .create {
    @include margin(rem(15) null null);
  }

  img:last-of-type,
  .divider {
    display: none;
  }
}

.t5 {
  @include padding(0 cols(4));
  text-align: center;

  @include mq($bp1024) {
    @include padding(null cols(14));
  }

  .t5 {
    @include padding(0);
  }

  p {
    @extend %font-source-sans-regular;
    @include font-size(16, 28);
    @include margin(null null rem(10));

    a {
      color: $color-raleigh-red;

      @include hover {
        text-decoration: none;
      }
    }
  }
}

.b2c-api-container {
  @include margin(rem(35) null null);
  @include padding(null null rem(35));

  @include mq($bp768) {
    @include padding(null null rem(100));
  }
}

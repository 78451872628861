.cc--search-form {
  display: none;
  position: relative;
  background-color: $color-white;

  @include mq($bp1024) {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    animation: fadeOut $transition-duration-fast $transition-easing-default;
    background-color: rgba($color-raleigh-red, 0.95);
  }

  &.is-open {
    display: block;
    
    @include mq($bp1024) {
      animation: fadeIn $transition-duration-fast $transition-easing-default;
    }

    &::before {
      @include centered(x);
      content: '';
      display: block;
      z-index: 1;
      top: rem(-11);
      width: rem(65);
      height: rem(12);
      background-image: url('../images/interface/interface-insetarrow-up.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include mq($bp1024) {
        display: none;
      }
    }
  }

  .c--search-form {
    @include maxwidth;

    > .inner-wrapper {
      @include padding(rem(24) cols(4) rem(16));
      position: relative;

      @include mq($bp768) {
        @include padding(rem(32) null rem(24));
      }

      @include mq($bp1024) {
        @include padding(rem(75) cols(3) rem(50));
      }
    }
  }

  form {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border-bottom: rem(1) solid $color-nicholson-gray;

    @include mq($bp1024) {
      border-color: rgba($color-white, 0.25);
    }
  }

  .input-wrapper {
    position: relative;
    width: 100%;
  }

  .fi--form-item {
    margin: 0;

    label {
      @include visuallyhidden;
    }

    input {
      @extend %font-source-serif-regular-italic;
      @include font-size(18, 30);
      @include padding(0 0 rem(5));
      width: 100%;
      border: 0;
      background: transparent;
      color: $color-ludwell-black;

      @include mq($bp768) {
        @include font-size(24, 36);
      }

      @include mq($bp1024) {
        @include padding(null null rem(12));
        @include font-size(36, 46);
        color: $color-white;
      }

      &:focus {
        outline: none;
      }

      @include placeholder {
        color: $color-ludwell-black;

        @include mq($bp1024) {
          color: $color-white;
        }
      }
    }

    button {
      display: block;
      flex: 1 0 auto;
      width: rem(30);
      height: rem(30);
      min-height: 0;
      padding: 0;
      border: 0;
      background: transparent;
      background-image: url('../images/icons/icon-search-red.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: rem(30);
      font-size: 0;

      @include mq($bp768) {
        width: rem(40);
        height: rem(40);
        background-size: rem(40);
      }
  
      @include mq($bp1024) {
        width: rem(50);
        height: rem(50);
        background-image: url('../images/icons/icon-search.svg');
        background-size: rem(50);
      }

      @include hover {
        background-color: transparent;
      }
    }
  }

  .search-close {
    display: none;

    @include mq($bp1024) {
      @include vertical-align-flex;
      @include padding(rem(20) rem(20) rem(5) rem(5));
      position: absolute;
      top: 0;
      right: 0;
      border: 0;
      background: $color-randolph-burgundy;
      cursor: pointer;
    }

    @include hover {
      background: $color-york-yellow;

      svg {
        polygon {
          fill: $color-ludwell-black;
        }
      }
    }

    &:focus-visible {
      position: absolute;
    }

    svg {
      width: rem(25);
      height: rem(25);
    }
  }
}

@keyframes fadeIn {
  from {
    display: none;
    opacity: 0;
  }

  to {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    display: block;
    opacity: 1;
  }

  to {
    display: none;
    opacity: 0;
  }
}
.cc--shortcut-banner {
  position: fixed;
  z-index: 1002;
  bottom: 0;
  left: 0;
  width: 100%;

  @include mq($bp768) {
    display: none;
  }

  .c--shortcut-banner {
    position: relative;
    overflow: hidden;
    background-color: rgba($color-raleigh-red, 0.95);

    .inner-wrapper {
      @include padding(rem(35) cols(4) rem(40));
      position: relative;
      z-index: 2;
    }

    .banner-close {
      @include padding(0);
      position: absolute;
      z-index: 3;
      top: 0;
      right: rem(5);
      border: 0;
      background: transparent;
      appearance: none;
      cursor: pointer;

      svg {
        width: rem(25);
        height: rem(25);
      }
    }

    .f--cta-title {
      h3 {
        @extend %font-source-serif-regular;
        @include font-size(18, 26);
        color: $color-white;
        text-align: center;
      }
    }

    .links-container {
      @include margin(rem(10) null null);
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: rem(8);

      .f--link {
        width: 100%;

        a {
          @include button(
            $outline: true,
            $text-color: $color-white,
            $outline-color: $color-york-yellow,
            $focus-color: $color-white
          );
          @include padding(null 0 null);
          width: 100%;
        }
      }
    }

    .f--image {
      position: absolute;
      z-index: 1;
      top: rem(15);
      right: rem(-30);
      width: rem(200);
      height: rem(200);
    }
  }

  .mobile-menu-open &,
  &.is-hidden {
    display: none;
  }
}

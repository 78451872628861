.f--input-select {
  .select-wrap {
    position: relative;
    background-color: transparent;

    select {
      @extend %font-source-sans-regular;
      @include padding(null rem(50) null rem(15));
      @include font-size(14, 22);
      width: 100%;
      height: rem(50);
      border: 0;
      border-radius: 0;
      background-color: $color-franklin-white;
      background-image: none;
      box-shadow: none;
      color: $color-ludwell-black;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      text-overflow: ellipsis;

      @include placeholder {
        color: $color-ludwell-black;
      }

      &:focus {
        @include outline($color: $color-focus);
      }
    }

    svg {
      @include centered('y');
      right: rem(15);
      width: rem(20);
      height: rem(20);
      pointer-events: none;
    }
  }
}

.cc--people-grid-manual {
  @include maxwidth;

  .c--people-grid-manual {
    @include padding(null cols(4));
    @include component-padding;

    @include mq($bp1024) {
      @include padding(null cols(2));
    }

    .text-container {
      @include mq($bp1024) {
        @include padding(null cols(7, 44));
      }

      .f--description {
        p {
          @include font-size(18, 34);

          @include mq($bp1024) {
            @include font-size(22, 44);
          }
        }
      }
    }

    .cards-container {
      @include margin(rem(25) null null null);
      display: flex;
      flex-wrap: wrap;
      column-gap: cols(4, 40);
      row-gap: rem(25);

      @include mq($bp768) {
        @include margin(rem(35) null null null);
        column-gap: cols(2, 40);
        row-gap: rem(25);
      }

      @include mq($bp1024) {
        column-gap: cols(1, 44);
        row-gap: rem(25);
      }

      .card {
        flex: 0 0 cols(18, 40);

        @include mq($bp768) {
          flex: 0 0 cols(19, 40);
        }

        @include mq($bp1024) {
          flex: 0 0 cols(14, 44);
        }

        .profile-info {
          @include padding(rem(20) null null null);

          @include mq($bp768) {
            @include padding(null cols(2, 19));
          }

          @include mq($bp1024) {
            @include padding(null cols(1, 14));
          }
        }

        .profile-name {
          @extend %font-source-serif-regular;
          display: block;
          position: relative;
          @include font-size(22, 32);
          color: $color-ludwell-black;

          @include mq($bp768) {
            @include font-size(26, 36);
          }
        }

        .profile-title {
          @extend %font-source-sans-semibold;
          @include margin(rem(5) null null null);
          @include font-size(15, 25);
          display: block;
          position: relative;

          @include mq($bp768) {
            @include font-size(16, 28);
          }
        }

        .profile-phone {
          @extend %font-source-sans-regular;
          @include margin(rem(5) null null null);
          @include font-size(15, 25);
          display: block;
          position: relative;
          color: $color-ludwell-black;

          @include mq($bp768) {
            @include font-size(16, 28);
          }
        }

        .profile-email {
          @extend %font-source-sans-regular;
          @include margin(rem(5) null null null);
          @include font-size(15, 25);
          display: block;
          position: relative;
          color: $color-raleigh-red;

          @include mq($bp768) {
            @include font-size(16, 28);
          }

          a {
            color: inherit;

            @include hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

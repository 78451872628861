body {
  margin: 0;
  padding: 0;

  &:not(.transparent-header):not(.opaque-header) #main-content {
    @include padding(rem(80) null null);

    @include mq($bp768) {
      @include padding(rem(115) null null);
    }

    @include mq($bp1024) {
      @include padding(rem(185) null null);
    }
  }
}

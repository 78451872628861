.f--sub-title {
  h3 {
    @extend %font-source-sans-regular;
    @include font-size(18, 28);
    color: inherit;
    letter-spacing: rem(4);
    text-transform: uppercase;

    @include mq($bp768) {
      @include font-size(22, 32);
      letter-spacing: rem(5);
    }
  }

  &.featured-special-event {
    h3 {
      @extend %font-source-serif-semibold-italic;
      @include font-size(14, 16);
      letter-spacing: rem(1.75);

      @include mq($bp768) {
        @include font-size(16, 20);
        letter-spacing: rem(2);
      }
    }
  }

  &.contact-info-card {
    h3 {
      @include font-size(12, 14);
      color: $color-ludwell-black;
      letter-spacing: rem(1.5);

      @include mq($bp768) {
        @include font-size(14, 18);
        letter-spacing: rem(1.75);
      }
    }
  }

  &.cta-cards-collection-card-subtitle {
    h3 {
      @extend %font-source-serif-regular;
      @include font-size(22, 32);
      letter-spacing: 0;
      text-transform: none;

      @include mq($bp768) {
        @include font-size(26, 36);
      }
    }
  }

  &.hole-title {
    h3 {
      @extend %font-source-serif-regular-italic;
      @include font-size(22, 26);
      color: $color-ludwell-black;
      letter-spacing: rem(8.5);
      text-transform: uppercase;

      @include mq($bp768) {
        @include font-size(26, 30);
        letter-spacing: rem(10);
      }
    }
  }

  &.ticket-title {
    h3 {
      @extend %font-source-serif-regular;
      @include font-size(22, 32);
      color: $color-ludwell-black;
      letter-spacing: 0;
      text-transform: none;

      @include mq($bp768) {
        @include font-size(26, 36);
      }

      a {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        text-decoration: none;

        @include hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.card-sub-title {
    h3 {
      @extend %font-source-serif-regular;
      @include font-size(18, 26);
      letter-spacing: 0;
      text-transform: none;

      @include mq($bp1024) {
        @include font-size(22, 32);
      }
    }
  }
}

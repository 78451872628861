.cc--donation-widget {
  @include maxwidth;
  position: relative;

  .c--donation-widget {
    @include padding(rem(25) cols(4));

    @include mq($bp768) {
      @include padding(rem(50) null);
    }

    @include mq($bp1024) {
      @include padding(null cols(3));
    }

    .inner-wrapper {
      @include component-box-shadow;
      @include padding(rem(25) cols(4, 40));
      position: relative;

      @include mq($bp768) {
        @include padding(rem(50) cols(3, 40));
      }

      @include mq($bp1024) {
        @include padding(null cols(4, 42));
        display: flex;
        justify-content: space-between;
        column-gap: cols(6, 42);

        &::after {
          @include centered(x);
          content: '';
          top: rem(35);
          width: rem(1);
          height: calc(100% - #{rem(70)});
          background-color: $color-nicholson-gray;
        }
      }
    }

    .left {
      @include mq($bp1024) {
        flex: 0 0 cols(14, 34);
      }

      .header-container {
        @include padding(null null rem(20));
        @include margin(null null rem(20));
        border-bottom: rem(1) solid $color-nicholson-gray;

        @include mq($bp768) {
          @include padding(null null rem(25));
          @include margin(null null rem(25));
        }

        @include mq($bp1024) {
          @include padding(null null 0);
          @include margin(null null 0);
          border: 0;
        }

        .f--section-title {
          h2 {
            @extend %font-source-serif-regular-italic;
            @include font-size(22, 26);
            letter-spacing: rem(8.5);
            text-align: center;
            text-transform: uppercase;

            @include mq($bp768) {
              @include font-size(26, 30);
              letter-spacing: rem(10);
            }
          }
        }
      }

      .f--description {
        @include margin(rem(5) null null);

        @include mq($bp768) {
          @include margin(rem(10) null null);
        }

        p {
          @include font-size(14, 22);
          text-align: center;
        }
      }
    }

    .right {
      @include mq($bp1024) {
        flex: 0 0 cols(14, 34);
        width: cols(14, 34);
      }

      .donation-widget-pagination {
        @include no-bullet;
        @include margin(null null rem(5));
        @include padding(null null rem(15));
        display: flex;
        position: relative;
        justify-content: space-between;
        border-bottom: rem(1) solid $color-raleigh-red;

        &::after {
          @include centered(x);
          content: '';
          bottom: rem(-4);
          width: 100%;
          height: rem(8);
          border-right: rem(1) solid $color-raleigh-red;
          border-left: rem(1) solid $color-raleigh-red;
        }

        li {
          position: relative;

          span {
            @extend %font-source-serif-semibold-italic;
            @include font-size(14, 18);
            display: inline-block;
            position: relative;
            border: 0;
            color: $color-black;
            letter-spacing: rem(1.75);
            text-transform: uppercase;
            cursor: pointer;
          }

          &.active {
            &::after {
              @include centered(x);
              content: '';
              display: block;
              z-index: 1;
              top: calc(100% + #{rem(9)});
              width: rem(35);
              height: rem(7);
              background-image: url('../images/interface/interface-insetarrow-up.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              filter: drop-shadow(0 rem(-1) 0 $color-raleigh-red);
            }

            span {
              color: $color-raleigh-red;
            }
          }
        }
      }

      .swiper {
        overflow: visible;

        .swiper-wrapper {
          .swiper-slide {
            > span {
              @include visuallyhidden;
            }

            .form-wrapper {
              form {
                @include mq($bp768) {
                  display: flex;
                  column-gap: cols(1, 34);
                }

                @include mq($bp1024) {
                  display: block;
                }
              }
            }

            .f--input-text {
              @include margin(null null rem(10));
              position: relative;

              @include mq($bp768) {
                @include margin(null null 0);
                flex: 0 0 cols(19, 34);
              }

              @include mq($bp1024) {
                @include margin(null null rem(10));
              }

              &::before {
                @extend %font-source-sans-bold;
                @include font-size(14, 22);
                @include centered(y);
                content: '$';
                z-index: 2;
                left: rem(10);
                color: $color-raleigh-red;
              }

              label {
                @include visuallyhidden;
              }

              input {
                @include font-size(14, 22);
                @include padding(rem(16) rem(25));
                height: rem(55);
                box-shadow: none;

                &:focus-visible {
                  z-index: 1;
                }

                @include placeholder {
                  @include font-size(14, 22);
                }
              }
            }

            .f--input-submit {
              @include mq($bp768) {
                flex: 0 0 cols(14, 34);
              }

              button {
                align-items: center;
                width: 100%;
                height: rem(50);

                @include mq($bp768) {
                  height: rem(55);
                  min-height: rem(55);
                }
              }
            }
          }
        }
      }
    }
  }
}

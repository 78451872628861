.f--form-item-date {
  .input-wrap {
    position: relative;
    background-color: transparent;

    &:focus-within {
      z-index: 999;
    }

    input {
      @extend %font-source-sans-regular;
      @include padding(null rem(50) null rem(15));
      @include font-size(14, 22);
      width: 100%;
      height: rem(50);
      border: 0;
      border-radius: 0;
      background-image: none;
      box-shadow: none;
      color: $color-input-text;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      text-overflow: ellipsis;

      @include placeholder {
        color: $color-input-text;
      }

      &:focus-visible {
        outline: rem(3) solid $color-focus;
      }
    }

    > span:not(.easepick-wrapper) {
      top: 50% !important;
      right: rem(20);
      left: auto !important;
      width: rem(20);
      height: rem(20);
      transform: translateY(-50%);
      pointer-events: none;

      &:focus-within button {
        outline: rem(3) solid $color-focus;
      }
    }

    svg {
      @include centered('y');
      right: rem(15);
      width: rem(25);
      height: rem(25);
      background-color: $color-white;
      pointer-events: none;
    }
  }
}

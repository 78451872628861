.tc--resource-hub {
  .cc--component-container {
    .c--component {
      &.c--related-content {
        .inner-wrapper {
          @include padding(null null 0);
        }
      }

      &.c--editorial-cards-grid {
        @include padding(null null 0);
      }

      &.c--article-resource-list {
        @include padding(0 null rem(50));

        @include mq($bp768) {
          @include padding(0 null rem(100));
        }

        .cards-container {
          @include margin(rem(30) null null);

          @include mq($bp768) {
            @include margin(rem(25) null null);
          }
        }
      }
    }
  }
}

//  Triangle helper mixin
//
//   @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
//   @param {Color} $color [currentcolor] - Triangle color
//   @param {Length} $size [1em] - Triangle size
//
//   Returns the opposite direction of each direction in a list
//   @author Hugo Giraudel
//   @param {List} $directions - List of initial directions
//   @return {List} - List of opposite directions
//
//
@function opposite-direction($directions) {
  $opposite-directions: ();
  $direction-map: (
    'top':    'bottom',
    'right':  'left',
    'bottom': 'top',
    'left':   'right',
    'center': 'center',
    'ltr':    'rtl',
    'rtl':    'ltr'
  );

  @each $direction in $directions {
    $direction: to-lower-case($direction);

    @if map-has-key($direction-map, $direction) {
      $opposite-directions: append($opposite-directions, unquote(map-get($direction-map, $direction)));
    } @else {
      @warn "No opposite direction can be found for `#{$direction}`. Direction omitted.";
    }
  }

  @return $opposite-directions;
}

@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  content: '';
  z-index: 2;
  width: 0;
  height: 0;
  border-#{opposite-direction($direction)}: ($size * 1) solid $color;

  $perpendicular-borders: (.75 * $size) solid transparent;

  @if $direction == top or $direction == bottom {
    border-right:  $perpendicular-borders;
    border-left:   $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-top:    $perpendicular-borders;
    border-bottom: $perpendicular-borders;
  }
}

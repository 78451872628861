.cc--hero-basic {
  @include maxwidth;

  .c--hero-basic {
    @include padding(rem(35) cols(4) rem(15));
    text-align: center;

    @include mq($bp768) {
      @include padding(rem(35) null rem(25));
    }

    @include mq($bp1024) {
      @include padding(rem(75) null rem(25));
    }

    .f--eyebrow {
      @include margin(null null rem(15) null);

      @include mq($bp768) {
        @include margin(null null rem(20) null);
      }
    }
  }
}

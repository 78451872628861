.main-menu-item {
  position: relative;

  &::before {
    @include mq($bp1024) {
      @include centered(x);
      content: '';
      display: none;
      z-index: 1;
      bottom: rem(-15);
      width: rem(5);
      height: rem(5);
      border-radius: 50%;
      background-color: $color-white;
    }
  }

  &::after {
    @include mq($bp1024) {
      @include centered(x);
      content: '';
      display: none;
      z-index: 1;
      bottom: rem(-46.5);
      width: rem(65);
      height: rem(12);
      background-image: url('../images/interface/interface-insetarrow-up.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  &:first-child {
    @include padding(rem(15) null null);

    @include mq($bp768) {
      @include padding(rem(10) null null);
    }

    @include mq($bp1024) {
      @include padding(0 null null);
    }
  }

  &:nth-last-child(2) {
    @include mq($bp1024) {
      @include margin(null rem(25) null null);
    }
  }

  &:last-child {
    .menu-item-toggle button {
      @include mq($bp1024) {
        &::before {
          content: '';
          position: absolute;
          top: rem(-5);
          left: rem(-25);
          width: rem(1);
          height: calc(100% + #{rem(20)});
          background-color: $color-nicholson-gray;
        }
      }
    }

    > .submenus-wrapper {
      @include margin(null null 0);
    }
  }

  &.is-open,
  &--active-trail {
    &::before {
      @include mq($bp1024) {
        display: block;
      }
    }

    &::after {
      @include mq($bp1024) {
        display: block;
      }
    }
  }

  @include hover {
    &::before {
      display: block;
    }
  }

  .menu-item-toggle {
    @include padding(null cols(4));

    @include mq($bp1024) {
      @include padding(null 0);
    }

    button {
      @extend %font-source-serif-semibold;
      @include font-size(26, 34);
      @include padding(rem(10) 0);
      display: block;
      position: relative;
      width: 100%;
      border: 0;
      background: transparent;
      color: $color-black;
      text-align: left;
      text-decoration: none;
      cursor: pointer;

      @include mq($bp768) {
        @include font-size(32, 34);
        @include padding(rem(15) null)
      }

      @include mq($bp1024) {
        @include font-size(20, 25);
        @include padding(0 null);
        color: $color-ludwell-black;
      }
    }
  }

  > .submenus-wrapper {
    @include maxwidth;
    @include margin(rem(15) null);
    @include padding(rem(25) cols(4) rem(15));
    display: none;
    position: relative;
    width: 100%;
    background-color: $color-white;

    @include mq($bp768) {
      @include margin(rem(10) null);
      @include padding(null null rem(35));
    }

    @include mq($bp1024) {
      @include margin(0 null);
      @include padding(rem(25) cols(2) rem(25) cols(3));
      position: fixed;
      top: rem(200);
      left: 50%;
      width: 100%;
      height: calc(100vh - #{rem(200)});
      overflow-y: auto;
      transform: translateX(-50%);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      bottom: calc(100% - #{rem(1)});
      left: cols(2, 40);
      width: rem(65);
      height: rem(12);
      background-image: url('../images/interface/interface-insetarrow-up.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      @include mq($bp768) {
        left: cols(4);
      }

      @include mq($bp1024) {
        display: none;
      }
    }

    > .submenus-wrapper-inner {
      @include mq($bp1024) {
        display: grid;
        grid-template-columns: #{cols(13, 43)} #{cols(30, 43)};
        height: calc(100% - #{rem(150)});
        overflow-y: auto;

        &.is-scrollable {
          @include margin(null rem(-12.5) null null);
        }
      }

      .item-description {
        @include margin(null null rem(15));
        @include padding(null null rem(25));
        z-index: 1;
        border-bottom: rem(1) solid $color-nicholson-gray;
        background-color: $color-white;

        @include mq($bp768) {
          @include margin(null null rem(17.5));
          @include padding(null null rem(30));
        }

        @include mq($bp1024) {
          @include margin(null null 0);
          @include padding(rem(50) cols(2, 13) null rem(4));
          border-bottom: 0;
        }

        .f--description {
          @include margin(rem(5) null null);

          p {
            @extend %font-source-sans-regular;
            @include font-size(15, 25);
            color: $color-ludwell-black;

            @include mq($bp768) {
              @include font-size(16, 28);
              letter-spacing: rem(0.25);
            }

            a {
              color: inherit;
              text-decoration: underline;

              @include hover {
                text-decoration: none;
                cursor: pointer;
              }
            }
          }
        }

        .f--link {
          @include margin(rem(15) null null);

          @include mq($bp768) {
            @include margin(rem(25) null null);
          }

          a {
            @include button-cta(
              $text-color: $color-ludwell-black,
              $hover-color: $color-raleigh-red,
              $outline-color: $color-black,
              $svg-color: $color-raleigh-red
            );
          }
        }
      }

      > .submenu {
        @include no-bullet;

        @include mq($bp1024) {
          @include padding(rem(37.5) null null calc(#{cols(1, 16)} - #{rem(10)}));
          width: cols(16, 30);
          border-right: rem(1) solid $color-nicholson-gray;
          border-left: rem(1) solid $color-nicholson-gray;
        }

        .menu-item {
          @include padding(rem(12.5) null);
          background-color: $color-white;

          @include mq($bp1024) {
            @include padding(null rem(15) null null);
          }

          &.is-open {
            @include mq($bp768max) {
              @include padding(null null rem(7.5));
            }
          }

          .arrow-toggle {
            @extend %font-source-sans-regular;
            @include padding(0);
            @include font-size(16, 26);
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            column-gap: rem(10);
            border: 0;
            background: transparent;
            color: $color-ludwell-black;
            letter-spacing: rem(4);
            text-align: left;
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;

            @include mq($bp768) {
              @include font-size(18, 28);
            }

            &:focus-visible {
              z-index: 0;
            }

            span {
              @include mq($bp1024) {
                @include padding(null rem(20));
                display: inline-block;
                position: relative;
                z-index: 1;
                background-color: $color-white;
              }
            }

            svg {
              width: rem(20);
              height: rem(20);

              @include mq($bp768) {
                width: rem(25);
                height: rem(25);
              }

              @include mq($bp1024) {
                display: none;
              }
            }

            &.is-open {
              @extend %font-source-sans-semibold;
              @include margin(null null rem(7.5));
              position: relative;

              @include mq($bp1024) {
                @include margin(null null 0);
              }

              &::before {
                @include mq($bp1024) {
                  @include centered(y);
                  content: '';
                  left: calc(#{cols(-2, 16)} - #{rem(20)});
                  width: calc((100% + #{cols(1, 16)}) + #{rem(40)});
                  height: rem(1);
                  background-color: $color-nicholson-gray;
                }
              }

              &::after {
                @include mq($bp1024) {
                  @include centered(y);
                  content: '';
                  display: block;
                  z-index: 2;
                  right: rem(-26);
                  width: rem(12);
                  height: rem(65);
                  background-image: url('../images/interface/interface-insetarrow-right.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: cover;
                  filter: drop-shadow(rem(1) 0 0 $color-nicholson-gray);
                }
              }

              svg {
                transform: rotate(180deg);
              }
            }
          }

          .submenus-wrapper {
            @include padding(null null null cols(4, 40));
            display: none;

            @include mq($bp768) {
              @include padding(null null null cols(2, 40));
            }

            @include mq($bp1024) {
              @include padding(rem(62.5) null rem(175) 0);
              position: absolute;
              top: 0;
              right: cols(2, 45);
              bottom: 0;
              width: cols(11, 45);
              overflow-y: auto;
            }

            .submenus-wrapper-inner {
              > .submenu {
                @include no-bullet;

                .menu-item {
                  @include mq($bp1024) {
                    @include padding(null 0 null rem(4));
                    background-color: transparent;
                  }
                }
              }
            }

            a {
              @extend %font-source-sans-regular;
              @include font-size(16, 30);
              color: $color-black;
              letter-spacing: rem(-0.2);
              text-decoration: none;

              @include mq($bp768) {
                @include font-size(18, 34);
                letter-spacing: rem(0.25);
              }

              @include hover {
                text-decoration: underline;
              }
            }

            .f--link {
              @include margin(rem(15) null null);

              @include mq($bp768) {
                @include margin(rem(22.5) null null);
              }

              @include mq($bp1024) {
                @include padding(null null null rem(4));
              }

              a {
                @include button-cta(
                  $text-color: $color-ludwell-black,
                  $hover-color: $color-raleigh-red,
                  $outline-color: $color-black,
                  $svg-color: $color-raleigh-red
                );
              }
            }
          }
        }
      }
    }
  }

  .transparent-header & {
    &--active-trail:not(.is-open)::after {
      display: none;
    }

    .menu-item-toggle button {
      @include mq($bp1024) {
        color: $color-white;

        &:focus {
          @include outline($color: $color-focus-light);
        }
      }
    }
  }

  .main-menu-open & {
    &.main-menu-item--active-trail:not(.is-open):not(:hover) {
      &::before,
      &::after {
        display: none;
      }
    }

    .menu-item-toggle button {
      color: $color-ludwell-black;

      &:focus {
        @include outline($color: $color-focus);
      }
    }
  }

  .home:not(.main-menu-open) &.main-menu-item--active-trail {
    &::before,
    &::after {
      animation: fadeIn 0.3s ease-out 1s forwards;
      opacity: 0;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.f--description {

  p {
    @extend %font-source-sans-regular;
    @include font-size(16, 30);
    letter-spacing: rem(0.2);

    @include mq($bp1024) {
      @include font-size(18, 34);
      letter-spacing: rem(0.25);
    }


    strong,
    b {
      @extend %font-source-sans-semibold;
    }

    i,
    em {
      font-style: italic;
    }

    a {
      color: $color-raleigh-red;
      font-family: inherit;
      text-decoration: underline;
      overflow-wrap: anywhere;

      @include hover {
        text-decoration: none;
      }
    }
  }

  ul,
  ol {
    @extend %font-source-sans-regular;
    @include padding(null null null rem(20));
    @include font-size(16, 30);
    overflow: hidden;

    @include mq($bp1024) {
      @include font-size(18, 34);
    }

    li {
      @include clearfix;

      &:not(:last-child) {
        @include margin(null null rem(10));
      }

      a {
        text-decoration: underline;
        overflow-wrap: anywhere;

        @include hover {
          text-decoration: none;
        }
      }
    }
  }

  &.footer-contact {
    p {
      @include font-size(14, 22);

      a {
        color: inherit;

        &:focus {
          @include outline($color: $color-focus-light);
        }
      }
    }
  }

  &.footer-stay-conntected {
    p {
      @include font-size(14, 22);

      a {
        color: inherit;
      }
    }
  }

  &.hole-description {
    p {
      @include font-size(15, 25);
      color: $color-ludwell-black;

      @include mq($bp768) {
        @include font-size(16, 28);
        letter-spacing: rem(0.25);
      }
    }
  }

  &.ticket-description {
    p {
      @include font-size(16, 30);
      color: $color-ludwell-black;
      letter-spacing: rem(0.2);

      @include mq($bp768) {
        @include font-size(18, 34);
        letter-spacing: rem(0.25);
      }
    }
  }

  &.hero-event-description,
  &.hero-historic-details-description {
    p {
      @extend %font-source-sans-regular;
      @include font-size(16, 30);
      letter-spacing: rem(0.2);

      @include mq($bp768) {
        @include font-size(18, 34);
        letter-spacing: rem(0.25);
      }
    }
  }

  &.hours-location-desc {
    p {
      @include font-size(15, 25);
      color: $color-ludwell-black;

      @include mq($bp768) {
        @include font-size(16, 28);
        letter-spacing: rem(0.25);
      }
    }
  }

  &.card-section-description {
    p {
      @include font-size(15, 25);
      color: $color-ludwell-black;

      @include mq($bp1024) {
        @include font-size(16, 28);
        letter-spacing: rem(0.25);
      }
    }
  }

  &.card-description {
    p {
      @include font-size(14, 22);
      color: $color-ludwell-black
    }
  }
}

.tc--explore-listing {
  .content-main {
    @include padding(null null rem(50));
    overflow: hidden;

    @include mq($bp768) {
      @include padding(null null rem(100));
    }

    .cc--component-container {
      &.cc--pager {
        @include margin(rem(15) null null);

        @include mq($bp768) {
          @include margin(rem(25) null null);
        }
      }

      .c--component {
        &.c--explore-cards {
          @include mq($bp768) {
            @include padding(rem(35) null null);
          }

          @include mq($bp1024) {
            @include padding(rem(25) null null);
          }

          .cards-container {
            @include margin(0 null null);
          }
        }
      }
    }
  }
}

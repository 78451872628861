.receipt {
  @include margin(rem(10) null rem(35));
  @include padding(null cols(4));

  @include mq($bp1024) {
    @include padding(null cols(5));
  }

  &__inner-wrapper {
    @include component-box-shadow;
    @include padding(rem(25) cols(4));

    @include mq($bp1024) {
      @include padding(rem(50) cols(6, 38));
    }
  }

  &__header {
    @include margin(null null rem(5));

    h2 {
      @extend %font-source-serif-regular;
      @include font-size(22, 32);

      @include mq($bp1024) {
        @include font-size(26, 36);
      }
    }

    h3 {
      @extend %font-source-serif-regular;
      @include font-size(22, 32);
    }
  }

  &__description {
    @extend %font-source-sans-regular;
    @include font-size(16, 28);
    @include margin(null null rem(15));

    a {
      color: $color-raleigh-red;
    }
  }

  &__title {
    @extend %font-source-serif-semibold;
    @include font-size(22, 32);
    @include margin(null null rem(10));
    color: $color-ludwell-black;
  }

  &__body {
    @extend %font-source-sans-regular;
    @include font-size(14, 22);
    @include margin(null null 0);
    color: $color-ludwell-black;

    &.total {
      @extend %font-source-serif-semibold-italic;
      @include font-size(18, 28);

      @include mq($bp1024) {
        @include font-size(22, 32);
      }
    }

    &.total-amount {
      @extend %font-source-serif-semibold;
      @include font-size(18, 28);

      @include mq($bp1024) {
        @include font-size(22, 32);
      }
    }
  }

  &__label {
    @extend %font-source-serif-regular-italic;
    @include font-size(16, 26);
    @include margin(null null rem(15));
    color: $color-ludwell-black;
  }

  &__eyebrow {
    @extend %font-source-sans-regular;
    @include font-size(14, 18);
    @include margin(null null rem(10));
    color: $color-ludwell-black;
    letter-spacing: rem(1.75);
    text-transform: uppercase;
  }

  &__columns {
    @include padding(rem(10) null);
    display: flex;
    justify-content: space-between;

    &.underline {
      @include margin(null null rem(15));
      border-bottom: rem(1) solid $color-nicholson-gray;
    }
  }

  &__title-wrapper {
    @include padding(null null rem(10));
    position: relative;
    text-align: center;

    &::after {
      @include centered(x);
      content: '';
      bottom: 0;
      width: 100%;
      height: rem(1);
      background-color: $color-ludwell-black;

      @include mq($bp1024) {
        width: calc(100% + #{cols(6, 26)});
      }
    }
  }

  &__location {
    @include margin(rem(10) null rem(15));
    display: flex;
    align-items: center;
    column-gap: rem(5);

    svg {
      width: rem(20);
      height: rem(20);
    }
  }

  &__information {
    @include margin(rem(25) null rem(35));

    &-summary {
      @include margin(rem(25) null null);
    }
  }

  &__items {
    &-item {
      @include padding(rem(25) null null);
      position: relative;

      &::before {
        @include centered(x);
        content: '';
        top: 0;
        width: 100%;
        height: rem(1);
        background-color: $color-ludwell-black;

        @include mq($bp1024) {
          width: calc(100% + #{cols(6, 26)});
        }
      }

      &:not(:last-child) {
        @include margin(null null rem(15));

        @include mq($bp1024) {
          @include margin(null null rem(25));
        }
      }
    }

    &-subitem {
      @include margin(rem(25) null null);

      @include mq($bp1024) {
        @include margin(rem(35) null null);
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      &:not(:last-child) {
        @include margin(null null rem(15));

        @include mq($bp1024) {
          @include margin(null null rem(25));
        }
      }

      tr {
        &:not(:last-child) {
          border-bottom: rem(1) solid $color-nicholson-gray;
        }

        &:has(.total) {
          border: 0;
        }

        th {
          @extend %font-source-sans-regular;
          @include font-size(14, 22);
          @include padding(rem(10) null);
          color: $color-ludwell-black;
          text-align: left;
          text-transform: uppercase;

          @include mq($bp1024) {
            @include padding(rem(15) null);
          }

          &:last-child {
            text-align: right;
          }
        }

        td {
          @extend %font-source-sans-regular;
          @include font-size(14, 22);
          @include padding(rem(10) null);
          color: $color-ludwell-black;
          text-align: left;

          @include mq($bp1024) {
            @include padding(rem(15) null);
          }

          &:first-child {
            width: 50%;
          }

          &:last-child {
            text-align: right;
          }

          &.tc-red {
            color: $color-raleigh-red;
          }

          &.total {
            @extend %font-source-serif-semibold;
            @include font-size(16, 26);
            color: $color-ludwell-black;

            @include mq($bp1024) {
              @include font-size(18, 28);
            }
          }
        }
      }
    }
  }
}
.cc--header-promo {
  background-color: $color-raleigh-red;

  &:not(:nth-of-type(1)) {
    display: none;
  }

  @include mq($bp1024) {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: rem(150);

    &::before {
      @include centered(x);
      content: "";
      bottom: 100%;
      width: 100%;
      height: rem(25);
      background: linear-gradient(0deg, rgba($color-white, 1) 0%, rgba($color-white, 0) 100%);
    }
  }

  .c--header-promo {
    @include maxwidth;
    @include padding(rem(25) cols(4) rem(30));

    @include mq($bp768) {
      @include padding(rem(30) null rem(45));
    }

    @include mq($bp1024) {
      @include padding(null null null cols(3));
    }

    .inner-wrapper {
      display: flex;
      position: relative;
      flex-direction: column;
      row-gap: rem(15);
    }

    .f--image {
      @include maxwidth(cols(36, 40));

      @include mq($bp768) {
        @include vertical-align-flex;
        flex: 0 0 cols(18, 40);
        max-width: none;
      }

      @include mq($bp1024) {
        flex-basis: cols(11, 41);
      }
    }

    .text-container {
      text-align: center;

      @include mq($bp1024) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: cols(2, 41);
        text-align: left;
      }

      .f--description {
        p {
          @extend %font-source-sans-regular;
          @include font-size(15, 25);
          color: $color-white;

          @include mq($bp1024) {
            @include font-size(16, 28);
          }

          a {
            color: inherit;
            text-decoration: underline;

            @include hover {
              text-decoration: none;
            }
          }
        }
      }

      .f--link {
        @include margin(rem(15) null null);

        @include mq($bp1024) {
          @include margin(0 null null);
          flex: 0 0 cols(10, 41);
        }

        a {
          @include button(
            $outline: true,
            $text-color: $color-white,
            $outline-color: $color-york-yellow,
            $focus-color: $color-white
          );

          @include mq($bp1024) {
            width: 100%;
          }
        }
      }
    }
  }

  &.has-image {
    .inner-wrapper {
      @include mq($bp768) {
        @include padding(rem(10) null);
        flex-direction: row;
        column-gap: cols(4, 40);
      }

      @include mq($bp1024) {
        @include padding(rem(8) null);
        column-gap: cols(4, 41);
      }

      &::before {
        @include mq($bp768) {
          @include centered;
          content: "";
          width: rem(1);
          height: 100%;
          background-color: rgba($color-white, 0.3);
        }

        @include mq($bp1024) {
          top: 50%;
          right: cols(12, 41);
          left: auto;
          transform: translate(0, -50%);
        }
      }

      &::after {
        @include mq($bp1024) {
          @include centered(y);
          content: "";
          position: absolute;
          left: cols(13, 41);
          width: rem(1);
          height: 100%;
          background-color: rgba($color-white, 0.3);
        }
      }

      .text-container {
        @include mq($bp768) {
          flex: 0 0 cols(18, 40);
          text-align: left;
        }

        @include mq($bp1024) {
          flex-basis: cols(26, 41);
          column-gap: cols(4, 26);
        }

        .f--description {
          @include mq($bp1024) {
            flex: 0 0 cols(12, 26);
          }
        }

        .f--link {
          @include mq($bp1024) {
            flex: 0 0 cols(10, 26);
            text-align: left;
          }
        }
      }
    }
  }
}

.tc--microsite-landing {
  .content-main {
    @include padding(null null rem(50));

    @include mq($bp768) {
      @include padding(null null rem(100));
    }

    .cc--component-container {
      &:not(:last-child) {
        @include padding(0 null rem(35));

        @include mq($bp768) {
          @include padding(0 null rem(50));
        }
      }

      &:first-child:not(.cc--feature-ambient-video) {
        @include padding(rem(35) null null);

        @include mq($bp768) {
          @include padding(rem(50) null null);
        }
      }

      .c--component {
        @include padding(0 null);
      }
    }
  }
}

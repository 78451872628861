body {
  position: relative;

  &::after {
    content: '';
    display: absolute;
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border: rem(5) solid $color-randolph-burgundy;
    pointer-events: none;
    user-select: none;

    @include mq($bp768) {
      border: rem(15) solid $color-randolph-burgundy;
    }
  }

  &.home::after {
    @include mq($bp1024) {
      animation: borderShrink 0.5s ease-in-out forwards;
      border-width: rem(30);
    }
  }

  @include color-themes(
    $colors: (
      'home': $color-randolph-burgundy,
      'discover': $color-raleigh-red,
      'visit': $color-braken-chartreuse,
      'stay-play': $color-geddy-blue,
      'gather': $color-wythe-green,
      'donate': $color-york-yellow
    )
  )
}

@keyframes borderShrink {
  from {
    border-width: rem(30);
  }

  to {
    border-width: rem(15);
  }
}
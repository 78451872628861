// common box shadow used on components
@mixin component-box-shadow {
  box-shadow: 0 0 rem(20) 0 rgba($color-black, 0.2);
}

@mixin component-padding (
  $padding-top-mobile: rem(35),
  $padding-bottom-mobile: rem(35),
  $padding-top-tablet: rem(50),
  $padding-bottom-tablet: rem(50),
  $padding-top-desktop: rem(50),
  $padding-bottom-desktop: rem(50)
) {
  @include padding($padding-top-mobile null $padding-bottom-mobile null);

  @include mq($bp768) {
    @include padding($padding-top-tablet null $padding-bottom-tablet null);
  }

  @include mq($bp1024) {
    @include padding($padding-top-desktop null $padding-bottom-desktop null);
  }
}

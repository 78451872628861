.cc--featured-block-split {
  .c--featured-block-split {
    @include maxwidth;

    @include mq($bp1024) {
      position: relative;
    }

    .text-image-container {
      display: flex;
      flex-direction: column;

      @include mq($bp1024) {
        flex-direction: row;
      }

      .image-container {
        position: relative;
        order: 1;

        @include mq($bp1024) {
          order: 2;
          width: cols(24);
        }

        &::after {
          @include mq($bp1024) {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 37.5%;
            background: linear-gradient(to bottom, transparent 0%, $color-black 100%);
          }
        }

        .f--ambient-video {
          display: none;

          @include mq($bp1024) {
            display: block;
            height: 100%;

            + .f--image {
              display: none;
            }
          }

          video {
            @include mq($bp768) {
              display: block;
              width: 100%;
              object-fit: cover;
              aspect-ratio: 1 / 1;
            }

            @include mq($bp1024) {
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .video-button {
          &:focus {
            outline-color: $color-white;
          }
        }

        .f--image {
          position: relative;

          @include mq($bp1024) {
            @include padding(null 0);
            height: 100%;
          }

          img {
            position: relative;

            @include mq($bp1024) {
              position: relative;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .text-container {
        @include padding(rem(20) cols(4) rem(35));
        position: relative;
        order: 2;
        overflow: hidden;

        @include mq($bp768) {
          @include padding(rem(35) null rem(50));
        }

        @include mq($bp1024) {
          @include padding(rem(50) cols(4) rem(50));
          display: flex;
          flex-direction: column;
          justify-content: center;
          order: 1;
          width: cols(24);
        }

        > .f--image {
          position: absolute;
          z-index: -1;
          top: rem(15);
          right: rem(-75);
          width: rem(200);
          height: rem(200);

          @include mq($bp768) {
            top: rem(15);
            right: rem(-125);
            width: rem(350);
            height: rem(350);
          }

          @include mq($bp1024) {
            top: rem(100);
            right: cols(-3, 24);
          }
        }

        .f--description {
          @include margin(rem(5) null null);

          p {
            @include font-size(15, 25);

            @include mq($bp768) {
              @include font-size(16, 28);
            }
          }
        }

        .links-container {
          @include margin(rem(15) null null);

          @include mq($bp768) {
            @include margin(rem(20) null null);
          }

          .f--link {
            a {
              @include button(
                $outline: true,
                $hover-bg-color: $color-randolph-burgundy
              );
            }
          }
        }
      }
    }
  }

  .media-link {
    @include padding(rem(15) null null null);
    @include margin(null cols(4) null cols(4));
    border-top: rem(1) solid $color-nicholson-gray;

    @include mq($bp768) {
      @include padding(rem(25) null null null);
    }

    @include mq($bp1024) {
      @include margin(0);
      @include padding(0 cols(3) null cols(2));
      position: absolute;
      right: 0;
      bottom: rem(20);
      width: cols(24);
      border-top: 0;
    }

    .more {
      @extend %font-source-sans-regular;
      @include margin(null null rem(15) null);
      @include font-size(12, 14);
      display: block;
      color: $color-ludwell-black;
      letter-spacing: rem(1.5);
      text-transform: uppercase;

      @include mq($bp768) {
        @include font-size(14, 18);
        letter-spacing: rem(1.75);
      }

      @include mq($bp1024) {
        display: none;
      }
    }

    .f--link {
      a {
        @include button-cta;

        @include mq($bp1024) {
          @include button-cta(
            $text-color: $color-white,
            $hover-color: $color-york-yellow,
            $outline-color: $color-white,
            $svg-color: $color-york-yellow
          );
        }
      }
    }
  }

  &.image-left {
    .text-image-container {
      .image-container {
        @include mq($bp1024) {
          order: 1;
        }

        .f--ambient-video {
          .video-controls {
            right: 0;
          }
        }
      }

      .text-container {
        @include mq($bp1024) {
          order: 2;
        }
      }
    }

    .media-link {
      @include mq($bp1024) {
        @include padding(0 cols(3) null cols(2));
        right: auto;
        left: 0;
      }
    }
  }

  &.has-media-link {
    .c--featured-block-split {
      @include padding(null null rem(35) null);

      @include mq($bp768) {
        @include padding(null null rem(50) null);
      }

      @include mq($bp1024) {
        @include padding(null null 0 null);
      }
    }
  }
}

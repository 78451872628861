//  Color Theme Mixin

@mixin color-themes($colors) {
  @each $name, $color in $colors {
    &.#{$name} {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: fixed;
        z-index: 9999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        border: rem(5) solid $color;
        pointer-events: none;
        user-select: none;

        @include mq($bp768) {
          border: rem(15) solid $color;
        }
      }
    }
  }
}

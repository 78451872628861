.cc--search-filter-options {
  @include maxwidth;
  position: relative;

  .c--search-filter-options {
    .inner-wrapper {
      @include padding(rem(35) cols(4) rem(25));
      background-color: $color-franklin-white;

      @include mq($bp1024) {
        @include padding(rem(25) cols(3) rem(40));
        display: flex;
        align-items: center;
        column-gap: cols(1, 42);
      }
    }

    .filter-title {
      @extend %font-source-serif-regular-italic;
      @include font-size(22, 26);
      @include margin(null null rem(15));
      letter-spacing: rem(8.5);
      text-transform: uppercase;

      @include mq($bp768) {
        @include font-size(26, 30);
        letter-spacing: rem(10);
      }

      @include mq($bp1024) {
        @include margin(null null 0);
        @include padding(rem(18) null null);
        flex: 0 0 cols(17, 42);
      }
    }

    .search-form-content {
      @include mq($bp1024) {
        flex: 0 0 cols(24, 42);
      }

      .fields-container {
        @include mq($bp768) {
          display: flex;
          column-gap: cols(2, 40);
        }

        @include mq($bp1024) {
          column-gap: rem(10);
        }

        .input-text-container {
          @include margin(null null rem(10));
          position: relative;

          @include mq($bp768) {
            @include margin(null null 0);
            flex: 0 0 cols(19, 40);
          }

          @include mq($bp1024) {
            flex: 0 0 calc(#{cols(12, 24)} - #{rem(5)});
          }

          .f--input-text {
            label {
              @extend %font-source-sans-regular;
              @include font-size(12, 14);
              @include margin(null null rem(5));
              display: block;
              letter-spacing: rem(1.5);
              text-transform: uppercase;

              @include mq($bp768) {
                @include font-size(14, 18);
              }
            }

            input {
              @include font-size(14, 22);
              @include padding(null rem(50) null rem(15));
              z-index: 1;
              height: rem(50);
              background-color: $color-white;
              box-shadow: none;
              color: $color-input-text;

              @include placeholder {
                @include font-size(14, 22);
                color: $color-input-text;
              }
            }
          }

          svg {
            position: absolute;
            z-index: 2;
            right: rem(15);
            bottom: rem(15);
            width: rem(20);
            height: rem(20);
            pointer-events: none;
          }
        }

        .cc--multiselect-dropdown {
          @include mq($bp768) {
            flex: 0 0 cols(19, 40);
          }

          @include mq($bp1024) {
            flex: 0 0 calc(#{cols(12, 24)} - #{rem(5)});
          }
        }
      }
    }

    .results-header {
      @include margin(rem(15) null null);
      @include padding(null cols(4));
      position: relative;

      @include mq($bp768) {
        display: flex;
        align-items: center;
        column-gap: rem(35);
      }

      @include mq($bp1024) {
        @include padding(null cols(3));
      }

      .results-count {
        @extend %font-source-sans-bold;
        @include font-size(14, 22);
        @include margin(null null rem(10));

        @include mq($bp768) {
          @include margin(null null 0);
        }
      }

      .results-sorting {
        display: flex;
        align-items: center;
        column-gap: rem(10);

        p {
          @extend %font-source-sans-bold;
          @include font-size(14, 22);
          @include margin(0);
        }

        button {
          @extend %font-source-sans-regular;
          @include font-size(14, 22);
          @include padding(0);
          border: 0;
          border-radius: 0;
          background: none;
          color: $color-black;
          text-align: left;

          @include mq($bp1024) {
            @include line-height(14, 14);
          }

          @include hover {
            text-decoration: underline;
            cursor: pointer;
          }

          &:not(:last-child) {
            @include margin(null rem(10) null null);
          }

          &.active {
            @extend %font-source-sans-bold;
            color: $color-raleigh-red;
            text-decoration: underline;

            @include hover {
              text-decoration: none;
            }
          }
        }
      }

      > button {
        @extend %font-source-sans-regular;
        @include font-size(14, 22);
        @include padding(0);
        position: absolute;
        top: 0;
        right: cols(4);
        border: 0;
        border-radius: 0;
        background: none;
        color: $color-black;
        text-decoration: underline;

        @include mq($bp1024) {
          @include line-height(14, 14);
          right: cols(3);
        }

        @include hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}
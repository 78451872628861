.cc--rich-text {
  @include clearfix;
  @include margin(rem(35) null);

  @include mq($bp768) {
    @include margin(rem(50) null);
  }

  .c--rich-text {
    @include maxwidth;

    .inner-wrapper {
      @include padding(null cols(4));

      @include mq($bp1024) {
        @include padding(null cols(9));
      }
    }
  }
}

.cc--interactive-visualization {
  display: none;

  @include mq($bp1024) {
    display: block;
    position: relative;
    background-color: $color-franklin-white;
  }

  .c--interactive-visualization {
    @include maxwidth;

    .inner-wrapper {
      @include mq($bp1024) {
        @include padding(rem(60) cols(3) rem(50));
      }

      .text-container {
        @include mq($bp1024) {
          @include padding(null null rem(25));
          display: flex;
          position: relative;
          align-items: center;
          justify-content: space-between;
          column-gap: cols(3, 42);
          border-bottom: rem(1) solid $color-nicholson-gray;
        }

        &::before {
          @include mq($bp1024) {
            @include centered(x);
            content: "";
            top: 0;
            width: rem(9);
            height: rem(1);
            background-color: $color-nicholson-gray;
          }
        }

        &::after {
          @include mq($bp1024) {
            @include centered(x);
            content: "";
            top: 0;
            width: rem(1);
            height: 100%;
            background-color: $color-nicholson-gray;
          }
        }

        .title-container {
          @include mq($bp1024) {
            flex: 0 0 cols(20, 42);
          }
        }

        .f--eyebrow {
          @include margin(null null rem(25));
        }

        .f--description {
          @include mq($bp1024) {
            flex: 0 0 cols(18, 42);
          }

          p {
            @include font-size(18, 34);
            letter-spacing: rem(0.25);

            a {
              color: $color-raleigh-red;
              text-decoration: underline;

              @include hover {
                text-decoration: none;
              }
            }
          }
        }
      }

      .intersections-container {
        position: relative;

        @include mq($bp1024) {
          @include padding(rem(35) null null cols(1, 42));
        }

        canvas {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        > .node-container {
          display: grid;
          grid-template-columns: cols(7, 41) cols(27, 41);
          grid-template-rows: repeat(3, 1fr);
          column-gap: cols(7, 41);

          > .node {
            grid-row: span 3;
            transform: translateY(rem(80));
            aspect-ratio: 35 / 32;

            &::after {
              top: 0;
              width: cols(6, 7);
              aspect-ratio: 1 / 1;
            }

            .f--image.node-icon {
              @include margin(null auto);
              width: cols(6, 7);
              aspect-ratio: 1 / 1;

              img {
                transform: translateY(rem(15));
              }
            }

            .modal {
              top: calc(100% + #{rem(35)});
              left: 0;
            }
          }

          > .node-container {
            display: grid;
            grid-template-columns: cols(7, 27) cols(16, 27);
            grid-template-rows: repeat(3, 1fr);
            min-height: rem(225);
            column-gap: cols(4, 27);

            &:nth-child(2) {
              .node {
                .modal {
                  top: 0;
                  left: calc(100% + #{rem(25)});
                }
              }
            }

            &:nth-child(3) {
              .node {
                &::before {
                  top: rem(-30);
                  right: 50%;
                  width: cols(16, 7);
                  transform: rotate(25deg);
                }

                .modal {
                  top: 50%;
                  left: calc(100% + #{rem(25)});
                  transform: translateY(-50%);
                }
              }
            }

            &:nth-child(4) {
              .node {
                  &::before {
                  top: rem(-156);
                  right: 0;
                  width: cols(22, 7);
                  transform: rotate(49deg);
                }

                .modal {
                  bottom: 0;
                  left: calc(100% + #{rem(25)});
                }
              }
            }

            > .node {
              grid-row: span 3;
              transform: translateY(rem(30));
              aspect-ratio: 35 / 24;

              &:not(.is-inactive):hover {
                z-index: 4;
              }

              &::after {
                top: 0;
                width: cols(4, 7);
                aspect-ratio: 1 / 1;
              }

              .f--image.node-icon {
                @include margin(null auto);
                width: cols(4, 7);
                aspect-ratio: 1 / 1;

                img {
                  transform: translateY(rem(10));
                }
              }
            }

            > .node-container {
              width: cols(10, 16);

              &:nth-child(3) {
                left: cols(3, 16);
              }

              &:nth-child(4) {
                left: cols(6, 16);
              }

              .node {
                display: flex;
                align-items: center;

                &::after {
                  left: 0;
                  width: cols(3, 10);
                  height: 100%;
                  transform: translateX(0);
                }

                .modal {
                  right: calc(100% + #{rem(25)});
                  left: auto;
                }

                &-icon {
                  @include margin(0 rem(15) 0 0);
                  flex: 0 0 cols(3, 10);
                  width: cols(3, 10);
                  aspect-ratio: 1 / 1;

                  img {
                    transform: translateY(rem(5));
                  }
                }

                .f--eyebrow {
                  @include margin(0);
                  @include padding(0);
                  background-color: transparent;
                  box-shadow: none;
                  text-align: left;
                }
              }
            }
          }
        }

        .node-container {
          position: relative;

          .node {
            position: relative;
            transition: z-index $transition-duration-default $transition-easing-default $transition-duration-default;
            cursor: pointer;

            &::after {
              @include centered(x);
              content: "";
              z-index: 1;
              border-radius: 50%;
              background-color: $color-white;
            }

            @include hover {
              .f--image.node-icon {
                &::before {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }

            &.is-active {
              z-index: 3;

              .f--image.node-icon {
                &::before {
                  visibility: visible;
                  opacity: 1;
                }
              }

              .modal {
                visibility: visible;
                opacity: 1;
              }
            }

            &.is-inactive {
              .f--image.node-icon {
                opacity: 0.6;
              }
            }

            &.places {
              .node-icon {
                background-color: $color-york-yellow;
              }

              .modal {
                border-bottom: rem(5) solid $color-york-yellow;
              }
            }

            &.people {
              .node-icon {
                background-color: $color-nicholson-gray;
              }

              .modal {
                border-bottom: rem(5) solid $color-nicholson-gray;
              }
            }

            &.historical-moments {
              .node-icon {
                background-color: $color-carter-peach;
              }

              .modal {
                border-bottom: rem(5) solid $color-carter-peach;
              }
            }

            &.objects {
              .node-icon {
                background-color: $color-braken-chartreuse;
              }

              .modal {
                border-bottom: rem(5) solid $color-braken-chartreuse;
              }
            }

            &.ideas {
              .node-icon {
                background-color: $color-charlton-robins-egg;
              }

              .modal {
                border-bottom: rem(5) solid $color-charlton-robins-egg;
              }
            }

            &-icon {
              position: relative;
              z-index: 2;
              overflow: hidden;
              border-radius: 50%;
              
              &::before {
                @include centered;
                content: "";
                visibility: hidden;
                width: 100%;
                height: 100%;
                transition: opacity $transition-duration-default $transition-easing-default, visibility $transition-duration-default $transition-easing-default;
                border: rem(5) solid rgba($color-black, 0.15);
                border-radius: 50%;
                opacity: 0;
              }

              img {
                height: 100%;
                object-fit: contain;
                filter: invert(93%) sepia(3%) saturate(492%) hue-rotate(341deg) brightness(105%) contrast(87%);
              }
            }

            .f--eyebrow {
              @include component-box-shadow;
              @include margin(rem(-25) auto 0);
              @include padding(rem(10) rem(12));
              position: relative;
              z-index: 2;
              background-color: $color-white;
              text-align: center;

              span {
                display: block;
              }
            }

            .modal {
              @include component-box-shadow;
              visibility: hidden;
              position: absolute;
              z-index: 3;
              width: rem(300);
              transition: opacity $transition-duration-default $transition-easing-default, visibility $transition-duration-default $transition-easing-default;
              opacity: 0;
              background-color: $color-white;

              &-text-container {
                @include padding(rem(20) rem(25) rem(35));
                position: relative;
                overflow: hidden;

                .f--cta-title {
                  @include margin(null null rem(5));

                  h3 {
                    @extend %font-source-serif-regular;
                    @include font-size(22, 32);
                  }
                }

                .f--description {
                  p {
                    @include font-size(16, 28);
                    letter-spacing: rem(0.25);
                  }
                }

                .f--link {
                  @include margin(rem(15) null null);

                  a {
                    @include button-cta(
                      $text-color: $color-ludwell-black,
                      $hover-color: $color-raleigh-red,
                      $outline-color: $color-ludwell-black,
                      $svg-color: $color-raleigh-red
                    );
                  }
                }
              }

              &-close {
                @include padding(0);
                appearance: none;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: rem(25);
                height: rem(25);
                border: 0;
                cursor: pointer;

                &:focus {
                  outline-color: $color-focus-light;
                }
  
                svg {
                  width: rem(25);
                  height: rem(25);
                  background-color: $color-black;

                  @include hover {
                    background-color: $color-raleigh-red;
                  }
                }
              }

              &-icon {
                position: absolute;
                z-index: -1;
                right: rem(-25);
                bottom: rem(-25);
                width: rem(200);
                height: rem(200);

                img {
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}

.cc--tickets-and-passes {
  @include maxwidth;

  .c--tickets-and-passes {
    @include padding(null cols(4));
    @include component-padding;

    @include mq($bp768) {
      @include padding(null cols(4));
    }

    @include mq($bp1024) {
      @include padding(null cols(3));
    }

    .f--section-title {
      text-align: center;
    }

    .tickets-container {
      @include margin(rem(25) null null null);

      @include mq($bp768) {
        @include margin(rem(35) null null null);
      }

      .ticket {
        @include padding(rem(35) null rem(20) null);
        border-bottom: rem(1) solid $color-nicholson-gray;

        @include mq($bp768) {
          @include padding(rem(35) null rem(35) null);
          display: flex;
          flex-wrap: wrap;
        }

        @include mq($bp1024) {
          @include padding(rem(35) null rem(35) null);
          column-gap: cols(2, 42);
        }

        &:first-child {
          @include padding(0 null null null);
        }

        .f--image {

          @include mq($bp768max) {
            display: none;
          }

          @include mq($bp768) {
            flex: 0 0 cols(12, 40);
          }

          @include mq($bp1024) {
            flex: 0 0 cols(8, 42);
          }
        }

        .text-container {

          @include mq($bp768) {
            @include margin(null null null cols(2, 40));
            flex: 0 0 cols(26, 40);
          }

          @include mq($bp1024) {
            @include margin(null null null 0);
            flex: 0 0 cols(20, 42);
          }

          .ticket-promotion {
            @extend %font-source-sans-regular;
            @include margin(rem(5) null rem(5) null);
            @include padding(rem(9) rem(20) rem(7) rem(20));
            @include font-size(12);
            display: inline-block;
            background-color: $color-york-yellow;
            color: $color-ludwell-black;
            letter-spacing: rem(1.25);
            text-transform: uppercase;

            @include mq($bp768) {
              @include margin(rem(10) null rem(10) null);
              @include font-size(14);
            }
          }

          .f--description {
            @include margin(rem(5) null null null);
          }
        }

        .types {
          @include margin(rem(20) null null null);

          @include mq($bp768) {
            @include margin(rem(25) null null null);
            flex: 0 0 100%;
          }

          @include mq($bp1024) {
            @include margin(0 null null null);
            flex: 0 0 cols(10, 42);
          }

          .types-container {
            .type {
              @include padding(rem(15) null rem(10) null);
              display: flex;
              flex-wrap: wrap;
              align-items: baseline;
              justify-content: space-between;

              @include mq($bp1024) {
                border-bottom: rem(1) solid $color-nicholson-gray;
              }

              &:not(:last-child) {
                @include mq($bp1024max) {
                  border-bottom: rem(1) solid $color-nicholson-gray;
                }
              }

              &:first-child {
                @include padding(0 null null null);
              }

              .type-title {
                @extend %font-source-sans-regular;
                @include margin(null null rem(10) null);
                @include font-size(12, 14);
                display: block;
                flex: 0 0 100%;
                color: $color-ludwell-black;
                letter-spacing: rem(1.5);
                text-transform: uppercase;

                @include mq($bp768) {
                  @include margin(null null rem(8) null);
                  @include font-size(14, 18);
                  letter-spacing: rem(1.75);
                }
              }

              .type-age {
                @extend %font-source-sans-regular;
                @include font-size(14, 22);
                color: $color-ludwell-black;

              }

              .type-price {
                @extend %font-source-sans-regular;
                @include font-size(15, 25);
                color: $color-ludwell-black;

                @include mq($bp768) {
                  @include font-size(16, 28);
                  letter-spacing: rem(0.25);
                }
              }
            }
          }

          .f--link {
            @include margin(rem(5) null null null);

            @include mq($bp768) {
              @include margin(rem(10) null null null);
            }

            @include mq($bp1024) {
              @include margin(rem(20) null null null);
            }

            @include mq($bp768-1024) {
              text-align: center;
            }

            a {
              @include button(
                $outline: false,
                $btn-height-mobile: rem(60),
                $btn-height-desktop: rem(60),
                $btn-height-tablet: rem(60)
              );
              width: 100%;

              @include mq($bp768-1024) {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}

.cc--featured-block-full-width {
  position: relative;

  .c--featured-block-full-width {
    @include maxwidth;

    @include mq($bp1024) {
      @include padding(rem(50) null 0);
      display: flex;
      align-items: flex-end;
      width: 100%;
      max-height: rem(500);
      aspect-ratio: 16 / 9;
    }

    .image-container {

      @include mq($bp1024) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .f--ambient-video {
        display: none;

        @include mq($bp1024) {
          display: block;
          height: 100%;

          + .f--image {
            display: none;
          }
        }

        video {
          @include mq($bp768) {
            display: block;
            width: 100%;
            object-fit: cover;
            aspect-ratio: 16 / 9;
          }

          @include mq($bp1024) {
            max-height: rem(500);
            object-fit: cover;
          }
        }

      }

      .video-button {
        &:focus {
          outline-color: $color-white;
        }
      }

      .f--image {
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
          aspect-ratio: 3 / 2;

          @include mq($bp1024) {
            aspect-ratio: 16 / 9;
          }
        }
      }

      &::after {
        @include mq($bp1024) {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.85;
          background: linear-gradient(to bottom, transparent 0%, $color-black 100%);
        }
      }
    }

    .text-container-wrapper {
      position: relative;

      @include mq($bp1024) {
        left: cols(4);
        max-width: cols(32);
      }
    }

    .text-container {
      @include padding(rem(20) cols(4) rem(35));
      background-color: $color-white;
      color: $color-ludwell-black;

      @include mq($bp1024) {
        @include padding(rem(50) 0 rem(35));
        background-color: transparent;
        color: $color-white;
      }

      .f--description {
        p {
          a {
            color: $color-raleigh-red;

            @include mq($bp1024) {
              color: $color-white;
            }
          }
        }
      }

      .links-container {
        @include margin(rem(15) null null);

        @include mq($bp1024) {
          display: flex;
        }

        .f--link {

          &:not(:last-child) {
            @include margin(null null rem(10) null);

            @include mq($bp1024) {
              @include margin(null rem(10) 0 null);
            }
          }

          a {
            @include mq($bp1024max) {
              @include button(
                $outline: true,
                $hover-bg-color: $color-randolph-burgundy
              );
            }

            @include mq($bp1024) {
              @include button(
                $outline: true,
                $text-color: $color-white,
                $outline-color: $color-york-yellow,
                $focus-color: $color-focus-light
              );
            }
          }
        }
      }
    }
  }
}

.cc--hero-secondary-landing {
  position: relative;

  .c--hero-secondary-landing {
    @include maxwidth;

    @include mq($bp1024) {
      @include padding(rem(50) null 0);
      display: flex;
      align-items: flex-end;
      width: 100%;
      aspect-ratio: 2 / 1;
    }

    @include mq($bp1800) {
      max-height: rem(900);
    }

    .image-container {

      @include mq($bp1024) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .f--ambient-video {
        display: none;

        @include mq($bp1024) {
          display: block;
          height: 100%;

          + .f--image {
            display: none;
          }
        }

        video {
          @include mq($bp768) {
            display: block;
            width: 100%;
            object-fit: cover;
            aspect-ratio: 2 / 1;
          }

          @include mq($bp1800) {
            max-height: rem(900);
          }
        }

      }

      .video-button {
        &:focus {
          outline-color: $color-white;
        }
      }

      .f--image {
        position: relative;
        height: 100%;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 5;
          bottom: rem(-1);
          left: cols(2);
          width: rem(65);
          height: rem(12);
          background-image: url('../images/interface/interface-insetarrow-up.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;

          @include mq($bp1024) {
            display: none;
          }
        }

        img {
          height: 100%;
          object-fit: cover;
          aspect-ratio: 3 / 2;

          @include mq($bp1024) {
            aspect-ratio: 2 / 1;
          }

          @include mq($bp1800) {
            max-height: rem(900);
          }
        }
      }

      &::after {
        @include mq($bp1024) {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.85;
          background: linear-gradient(to bottom, transparent 0%, $color-black 100%);
        }
      }
    }

    .text-container-wrapper {
      position: relative;

      @include mq($bp1024) {
        left: cols(3);
        width: calc(100% - #{cols(6)});
      }
    }

    .text-container {
      @include padding(rem(12) cols(4) rem(25));
      display: flex;
      flex-direction: column;
      background-color: $color-white;
      color: $color-ludwell-black;

      @include mq($bp768) {
        @include padding(rem(15) null null);
      }

      @include mq($bp1024) {
        @include padding(rem(50) 0 rem(30));
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        background-color: transparent;
        color: $color-white;
      }
    }

    .f--page-title {
      @include margin(rem(15) null null);
      order: 2;

      @include mq($bp768) {
        @include margin(rem(25) null null);
      }

      @include mq($bp1024) {
        @include margin(0 null null);
        order: 1;
      }

      h1 {
        @include mq($bp1024) {
          color: $color-white;
        }
      }
    }

    .f--page-title + .f--description {
      order: 1;

      @include mq($bp1024) {
        @include padding(null null null cols(1, 42));
        flex: 0 0 cols(16, 42);
        order: 2;
        width: cols(16, 42);
      }

      p {
        @include font-size(12, 22);

        @include mq($bp768) {
          @include font-size(14, 24);
        }

        @include mq($bp1024) {
          color: $color-white;
          text-align: right;
        }
      }

      a {
        @extend %font-source-sans-regular;
        color: $color-raleigh-red;
        text-decoration: underline;

        @include mq($bp1024) {
          color: $color-white;

          &:focus {
            outline-color: $color-white;
          }
        }

        @include hover {
          text-decoration: none;
        }
      }
    }
  }
}

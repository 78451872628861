.cc--subscribe-form {
  @include maxwidth;
  position: relative;

  .c--subscribe-form {
    @include padding(rem(25) cols(4));

    @include mq($bp768) {
      @include padding(rem(50) null);
    }

    @include mq($bp1024) {
      @include padding(null cols(3));
    }

    .inner-wrapper {
      @include padding(rem(25) cols(2, 20));
      position: relative;
      box-shadow: 0 0 rem(20) 0 rgba($color-black,0.20);

      @include mq($bp768) {
        @include padding(rem(50) cols(3, 40));
      }

      @include mq($bp1024) {
        @include padding(null cols(4, 42));
        display: flex;
        align-items: center;
        justify-content: space-between;

        &::after {
          @include centered(x);
          content: '';
          top: rem(35);
          width: rem(1);
          height: calc(100% - #{rem(70)});
          background-color: $color-nicholson-gray;
        }
      }
    }

    .header-container {
      @include padding(null null rem(20));
      @include margin(null null rem(20));
      border-bottom: rem(1) solid $color-nicholson-gray;

      @include mq($bp1024) {
        @include padding(null null 0);
        @include margin(null null 0);
        flex: 0 0 cols(14, 34);
        border: 0;
      }

      .f--section-title {
        @include margin(null null rem(10));

        @include mq($bp768) {
          @include margin(null null rem(15));
        }

        h2 {
          @extend %font-source-serif-regular-italic;
          @include font-size(22, 26);
          letter-spacing: rem(8.5);
          text-align: center;
          text-transform: uppercase;

          @include mq($bp768) {
            @include font-size(26, 30);
          }
        }
      }

      .f--image {
        img {
          @include margin(null auto);
          max-width: rem(240);

          @include mq($bp768) {
            max-width: rem(350);
          }
        }
      }

      .f--description {
        @include margin(rem(15) null null);

        p {
          @include font-size(14, 22);
          text-align: center;
        }
      }
    }

    .form-wrapper {
      @include mq($bp1024) {
        flex: 0 0 cols(14, 34);
      }
    }

    form {
      @include mq($bp768) {
        @include maxwidth(320px);
      }

      @include mq($bp1024) {
        max-width: 100%;
      }
    }

    .f--input-text {
      @include margin(null null rem(10));

      input {
        @include padding(rem(16) rem(25));
        height: rem(55);
        box-shadow: none;
      }
    }

    .f--input-submit {
      button {
        align-items: center;
        width: 100%;
        height: rem(50);

        @include mq($bp768) {
          height: rem(55);
          min-height: rem(55);
        }
      }
    }

    #icontactSignupFormWrapper806 {

      .elcontainer {
        @include padding(0);
      }

      img {
        width: auto;
        height: auto;
      }

      h3 {
        @extend %font-source-sans-regular;
        @include font-size(14, 22);
        margin-top: rem(-20)!important;
        margin-bottom: rem(10)!important;
        font-weight: normal!important;
      }

      .fieldtype-checkbox {
        @include padding(null 0 null null);
        right: auto;
      }

      .fieldtype-input {
        @include padding(null 0 null null);
        right: auto;

        input[type='text'] {
          @extend %input-style;
          border: 0!important;
          background-color: $color-franklin-white!important;
          color: $color-ludwell-black!important;
          font-family: "Source Sans 3", sans-serif!important;
          font-style: normal!important;
          font-weight: 400!important;
          font-optical-sizing: auto!important;
          text-overflow: ellipsis;

          @include placeholder {
            color: $color-ludwell-black!important;
            font-style: normal!important;
          }

          @include mq($bp1024) {
            height: rem(50);
          }
        }
      }

      .option-container {
        @extend %font-source-sans-regular;
        @include font-size(14, 18);

        label {
          display: block;
          position: relative;
          padding-left: rem(35)!important;

          &:not(:last-child) {
            margin-bottom: rem(10)!important;
          }

          input[type='checkbox'] {
            @include margin(0 rem(10) 0 0);
            display: inline-block;
            position: absolute;
            left: 0;
            flex: 0 0 rem(25);
            width: rem(25);
            height: rem(25);
            transition: background $transition-duration-default $transition-easing-default;
            border: rem(1) solid $color-nicholson-gray;
            outline: none;
            background: $color-white;
            background-color: $color-franklin-white;
            vertical-align: top;
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;

            &:focus-visible {
              outline: rem(3) solid $color-focus;
            }

            &:after {
              @include centered(x);
              content: '';
              display: block;
              z-index: 1;
              width: 100%;
              height: 100%;
              transition: opacity $transition-duration-fast $transition-easing-default;
              opacity: 1;
              background-image: url('../images/icons/icon-check-franklin-white.svg');
              background-size: cover;
            }

            &:checked {
              border: 0;
              background-color: $color-raleigh-red;
            }
          }
        }
      }

      input[type='submit'] {
        @include button {
          border-radius: 0!important;
          cursor: pointer;
        };
      }
    }
  }
}

.cc--supplementary-links {
  @include padding(null cols(4) rem(35));
  background-color: $color-franklin-white;

  @include mq($bp768) {
    @include padding(null null rem(35));
  }

  @include mq($bp1024) {
    @include margin(null null null rem(5));
    @include padding(null 0 0 rem(5));
    position: relative;
    background-color: transparent;
  }

  .c--supplementary-links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    row-gap: rem(10);

    @include mq($bp1024) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &::before {
      @include mq($bp1024) {
        content: '';
        position: absolute;
        top: rem(15);
        left: 0;
        width: rem(1);
        height: rem(35);
        background-color: $color-nicholson-gray;
      }
    }

    .f--link:first-child {
      @include margin(null null null cols(-0.5, 40));
    }

    .f--link,
    .icon-search {
      &:nth-child(3n + 2):not(.shopping-cart) {
        justify-content: center;

        a {
          justify-content: center;
        }
      }

      &:nth-child(3n + 3) {
        justify-content: flex-end;

        a {
          justify-content: flex-end;
        }
      }
    }

    .f--link a,
    .icon-search {
      @extend %font-source-sans-semibold;
      @include font-size(14, 20);
      @include padding(0);
      display: flex;
      align-items: center;
      column-gap: rem(5);
      border: 0;
      background: transparent;
      color: $color-ludwell-black;
      text-decoration: none;
      cursor: pointer;

      @include mq($bp768) {
        @include font-size(16, 22);
      }

      @include mq($bp1024) {
        @include font-size(14, 20);
        @include padding(rem(15) 0 rem(10) rem(20));

        @include hover {
          color: $color-raleigh-red;
          text-decoration: underline;

          svg path {
            fill: $color-raleigh-red;
          }
        }
      }

      svg {
        flex: 0 0 rem(25);
        width: rem(25);
        height: rem(25);

        @include mq($bp768) {
          flex-basis: rem(30);
          width: rem(30);
          height: rem(30);
        }

        @include mq($bp1024) {
          flex-basis: rem(25);
          width: rem(25);
          height: rem(25);
        }

        path {
          fill: $color-ludwell-black;
        }
      }

      .search-close {
        display: none;
      }

      &.is-active {
        color: $color-raleigh-red;

        .search-open {
          display: none;
        }

        .search-close {
          display: block;
        }
      }
    }

    .shopping-cart {
      &.is-active {
        display: inline-flex;
        align-items: center;

        a {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: rem(10);
            height: rem(10);
            border-radius: 50%;
            background-color: $color-raleigh-red;

            @include mq($bp1024) {
              bottom: rem(10);
              left: rem(20);
            }
          }
        }
      }
    }
  }

  .transparent-header & {
    .c--supplementary-links {
      &::before {
        background-color: rgba($color-white, 0.3);
      }

      .f--link a,
      .icon-search {
        @include mq($bp1024) {
          color: $color-white;

          svg path {
            fill: $color-white;
          }

          &:focus {
            @include outline($color: $color-focus-light);
          }

          @include hover {
            color: $color-white;

            svg path {
              fill: $color-white;
            }
          }
        }
      }
    }
  }

  .search-is-open & {
    .c--supplementary-links {
      .icon-search {
        color: $color-raleigh-red;

        .search-close {
          display: block;
        }

        .search-open {
          display: none;
        }
      }
    }
  }

  .main-menu-open & {
    .c--supplementary-links {
      @include mq($bp1024) {
        &::before {
          background-color: $color-nicholson-gray;
        }

        .f--link a,
        .icon-search {
          color: $color-ludwell-black;

          &:focus {
            @include outline($color: $color-focus);
          }

          svg path {
            fill: $color-ludwell-black;
          }

          @include hover {
            color: $color-raleigh-red;

            svg path {
              fill: $color-raleigh-red;
            }
          }
        }
      }
    }
  }

  .home & {
    .c--supplementary-links {
      @include mq($bp1024) {
        @for $i from 1 through 5 {
          > :nth-child(#{$i}) {
            animation: fadeIn 0.3s ease-out #{0.8 + ($i * 0.2)}s forwards;
            opacity: 0;
          }
        }

        &::before {
          height: 0;
          animation: grow 0.3s ease-out 1.6s forwards;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes grow {
  from {
    height: 0;
  }

  to {
    height: #{rem(35)};
  }
}

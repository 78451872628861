.cc--pager {
  @include margin(rem(25) null null);
  @include padding(null null rem(50));

  @include mq($bp768) {
    @include padding(null null rem(100));
  }

  .c--pager {
    text-align: center;

    a {
      @include button(
        $outline: true,
        $background-color-default: null
      );
      min-width: rem(225);
    }
  }
}

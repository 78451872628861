.cc--golf-course-widget {
  .c--golf-course-widget {
    @include component-padding;

    .title-container {
      @include maxwidth;

      .f--section-title {
        @include padding(null cols(4));
        text-align: center;

        @include mq($bp1024) {
          @include padding(null cols(9));
        }
      }
    }

    .holes-pagination {
      @include padding(rem(20) null rem(15) null);
      display: flex;
      position: relative;
      z-index: 5;
      box-shadow: 0 rem(10) rem(10) 0 rgba($color-black, 0.1);

      @include mq($bp768max) {
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        -ms-overflow-style: none;
        scroll-snap-align: start;
      }

      @include mq($bp768) {
        @include padding(rem(25) null rem(14) null);
        display: flex;
      }

      .holes-pagination-inner {
        display: flex;

        @include mq($bp768max) {
          @include padding(null rem(25) null rem(25));
          flex-shrink: 0;
          flex-wrap: nowrap;
        }

        @include mq($bp768) {
          justify-content: center;
        }
      }

      button {
        @extend %font-source-serif-semibold-italic;
        @include padding(0);
        @include margin(0);
        @include font-size(14, 16);
        border: 0;
        background: transparent;
        color: $color-ludwell-black;
        letter-spacing: rem(1.75);
        cursor: pointer;

        @include mq($bp768max) {
          scroll-snap-align: start;
        }

        @include mq($bp768) {
          @include font-size(16, 20);
          letter-spacing: rem(2);
        }

        &:last-child {
          @include mq($bp768max) {
            @include padding(null rem(25) null null);
          }
        }

        &:first-child {
          @include mq($bp768max) {
            @include padding(null null null rem(25));
          }
        }

        &:not(:last-child) {
          @include margin(null rem(25) null null);

          @include mq($bp768) {
            @include margin(null rem(20) null null);
          }
        }

        &.active {
          color: $color-raleigh-red;
        }
      }
    }

    .swiper-buttons {
      display: flex;
      position: absolute;
      z-index: 10;
      top: rem(33);
      right: cols(4);

      @include mq($bp768) {
        top: rem(40);
        right: auto;
        left: calc(50% - #{cols(7)});
      }

      @include mq($bp1024) {
        top: rem(48);
        right: cols(2, 16);
        left: auto;
      }

      .swiper-button-prev {
        @include margin(null rem(30) null null);
        position: relative;

        &::after {
          position: absolute;
          top: rem(-5);
          right: rem(-15);
          width: rem(1);
          height: calc(100% + #{rem(10)});
          background-color: $color-nicholson-gray;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        display: block;
        position: relative;
        top: 0;
        right: auto;
        left: auto;
        width: rem(30);
        height: rem(30);
        margin-top: 0;
        padding: 0;
        border: 0;
        background: transparent;

        &::after {
          content: "";
          display: block;
        }

        &.swiper-button-disabled {
          opacity: 1;

          svg path {
            fill: $color-nicholson-gray;
          }
        }

        svg path {
          transition: fill $transition-duration-default $transition-easing-default;
          fill: $color-raleigh-red;
        }

        &:hover,
        &:focus-visible {
          svg path {
            fill: $color-randolph-burgundy;
          }
        }
      }
    }

    .swipers-container-wrapper {
      background-color: $color-franklin-white;
    }

    .swipers-container {
      @include maxwidth;

      @include mq($bp1024) {
        display: flex;
        position: relative;
      }

      .image-swiper {

        .swiper-slide {
          &.is-playing {
            .f--image {
              display: none;
            }

            .controls-container {
              display: none;
            }
          }
        }

        .image-container {
          height: 100%;

          @include mq($bp1024) {
            flex: 0 0 cols(32);
          }

          .f--image {
            position: relative;
            z-index: 10;
            height: 100%;

            img {
              height: 100%;
              object-fit: cover;
              aspect-ratio: 4 / 3;
            }
          }

          .f--video {
            position: absolute;
            z-index: 9;
            top: 0;
            left: 0;

            @include mq($bp1024) {
              width: 100%;
              height: 100%;
            }

            video {
              aspect-ratio: 4 / 3;
              width: 100%;
              object-fit: cover;

              @include mq($bp1024) {
                height: 100%;
              }
            }
          }

          .controls-container {
            display: flex;
            position: absolute;
            z-index: 20;
            top: 0;
            left: 0;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            button.play {
              display: flex;
              align-items: center;
              justify-content: center;
              width: rem(35);
              height: rem(35);
              padding: 0;
              border: 0;
              background-color: $color-ludwell-black;
              cursor: pointer;

              svg {
                width: rem(35);
                height: rem(35);
              }

              &:focus {
                @include outline($color: $color-focus-light);
              }
            }
          }

        }

      }

      .text-swiper {

        @include mq($bp1024) {
          flex: 0 0 cols(16);
        }
      }
    }


    .holes-container {
      height: 100%;

      .hole {

        .text-container {
          @include padding(rem(35) cols(4));
          height: 100%;
          background-color: $color-franklin-white;

          @include mq($bp768-1024) {
            @include padding(null null rem(50) null);
            display: flex;
          }

          @include mq($bp1024) {
            @include padding(rem(50) cols(2, 16));
          }

          .hole-info {
            @include mq($bp768-1024) {
              @include padding(null cols(2, 40) null null);
              flex: 0 0 cols(20, 40);
            }

            .hole-title {
              width: calc(100% - #{rem(100)});
            }

            .hole-par {
              @extend %font-source-sans-regular;
              @include margin(rem(15) null null null);
              @include font-size(12, 14);
              color: $color-ludwell-black;
              letter-spacing: rem(1.5);
              text-transform: uppercase;

              @include mq($bp768) {
                @include margin(rem(10) null null null);
                @include font-size(14, 18);
                letter-spacing: rem(1.75)
              }
            }

            .hole-tees {
              @include margin(rem(20) null null null);

              @include mq($bp768) {
                @include margin(rem(15) null null null);
              }

              .hole-tee {
                @include padding(rem(5) null rem(10) null);
                display: flex;
                border-top: rem(1) solid $color-nicholson-gray;

                .hole-color {
                  flex: 0 0 rem(130);
                }

                span.color-swatch {
                  display: inline-block;
                  width: rem(10);
                  height: rem(10);
                  border-radius: rem(10);
                }

                span.color-name {
                  @extend %font-source-sans-semibold;
                  @include margin(null null null rem(20));
                  @include font-size(14, 22);
                  display: inline-block;
                  color: $color-ludwell-black;
                  text-transform: capitalize;
                }

                .hole-yards {
                  @extend %font-source-sans-regular;
                  @include font-size(14, 22);
                  color: $color-ludwell-black;
                }
              }
            }
          }

          .hole-desc-links {
            @include mq($bp768-1024) {
              @include padding(null null null cols(2, 40));
              flex: 0 0 cols(20, 40);
              border-left: rem(1) solid $color-nicholson-gray;
            }

            .f--description {
              @include margin(rem(10) null null null);

              @include mq($bp768) {
                @include margin(0 null null null);
              }

              @include mq($bp1024) {
                @include margin(rem(15) null null null);
              }
            }

            .links-container {
              @include margin(rem(15) null null null);

              @include mq($bp768) {
                @include margin(rem(20) null null null);
              }

              .f--link {
                &:not(:last-child) {
                  @include margin(null null rem(15) null);
                }

                a {
                  @include button-cta;
                }
              }
            }
          }

        }
      }
    }
  }
}

.cc--breadcrumbs {
  display: none;
  z-index: 99;

  @include mq($bp1024) {
    display: block;
    position: absolute;
    top: rem(210);
    left: cols(2);
  }

  @include mq($bp1800) {
    left: calc(((100vw - 1650px) / 2));
  }

  .c--breadcrumbs {
    @include padding(rem(5) rem(15));
    border: rem(1) solid $color-franklin-white;
    border-radius: rem(17.5);
    background-color: $color-white;

    ul {
      @include no-bullet;
      display: flex;
      column-gap: rem(15);

      li {
        position: relative;

        &:not(:last-child) {
          &::after {
            @extend %font-source-sans-regular;
            @include centered(y);
            content: '\203A';
            display: block;
            right: rem(-10);
            color: $color-black;
          }
        }

        a {
          @extend %font-source-sans-regular;
          @include font-size(14, 24);
          color: $color-black;
          text-decoration: none;

          @include hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .transparent-header & {
    z-index: 999;
  }

  .opaque-header &,
  .main-menu-open & {
    z-index: 1;
  }

  .transparent-header &,
  .opaque-header & {
    .c--breadcrumbs {
      border: 0;
      background-color: rgba($color-black, 0.5);

      ul {
        li {
          &::after {
            color: $color-white;
          }

          a {
            color: $color-white;
          }
        }
      }
    }
  }
}

.cc--back-to-top {
  position: fixed;
  z-index: 999;
  right: rem(20);
  bottom: rem(20);
  width: auto;
  transform: translateX(calc(100% + #{rem(20)}));
  transition: transform $transition-duration-default $transition-easing-default;

  @include mq($bp768) {
    right: rem(30);
    bottom: rem(30);
    transform: translateX(calc(100% + #{rem(30)}));
  }

  @include mq($bp1024) {
    display: none;
  }

  &.show {
    transform: translateX(0);
  }

  .c--back-to-top {
    button {
      @include vertical-align-flex;
      z-index: 1;
      align-items: center;
      width: rem(35);
      height: rem(35);
      border: 0;
      background-color: $color-york-yellow;
      cursor: pointer;

      svg {
        width: rem(25);
        height: rem(25);
      }
    }
  }
}

.cc--hero-section-landing {
  position: relative;

  .c--hero-section-landing {
    @include maxwidth;

    @include mq($bp1024) {
      @include padding(rem(50) null 0);
      display: flex;
      align-items: flex-end;
      width: 100%;
      aspect-ratio: 16 / 9;
    }

    @include mq($bp1800) {
      max-height: rem(1013);
    }

    .image-container {

      @include mq($bp1024) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .f--ambient-video {
        display: none;

        @include mq($bp1024) {
          display: block;
          height: 100%;

          + .f--image {
            display: none;
          }
        }

        video {
          @include mq($bp768) {
            display: block;
            width: 100%;
            object-fit: cover;
            aspect-ratio: 16 / 9;
          }

          @include mq($bp1024) {
            object-fit: cover;
          }

          @include mq($bp1800) {
            max-height: rem(1013);
          }
        }
      }

      .video-button {
        &:focus {
          outline-color: $color-white;
        }
      }

      .f--image {
        position: relative;
        height: 100%;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 5;
          bottom: rem(-1);
          left: cols(2);
          width: rem(65);
          height: rem(12);
          background-image: url('../images/interface/interface-insetarrow-up.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;


          @include mq($bp1024) {
            display: none;
          }
        }

        img {
          height: 100%;
          object-fit: cover;
          aspect-ratio: 3 / 2;

          @include mq($bp1024) {
            aspect-ratio: 16 / 9;
          }

          @include mq($bp1800) {
            max-height: rem(1013);
          }
        }
      }

      &::after {
        @include mq($bp1024) {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.85;
          background: linear-gradient(to bottom, transparent 0%, $color-black 100%);
        }
      }
    }

    .text-container-wrapper {
      position: relative;

      @include mq($bp1024) {
        bottom: rem(30);
        left: cols(3);
        width: calc(100% - #{cols(5)});
      }
    }

    .text-container {
      @include padding(rem(25) cols(4));
      display: flex;
      flex-direction: column;
      background-color: $color-white;
      color: $color-ludwell-black;

      @include mq($bp1024) {
        @include padding(rem(10) 0);
        position: relative;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        color: $color-white;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: cols(16, 43);
          width: rem(1);
          height: 100%;
          background-color: rgba($color-white, 0.3);
        }

        &.single-title {
          &::after {
            display: none;
          }

          .f--page-title {
            h1 {
              @include mq($bp1024) {
                @include padding(null 0 null null);
              }
            }
          }
        }
      }
    }

    .f--page-title {
      h1 {
        @include mq($bp1024) {
          @include padding(null cols(2, 43) null null);
          color: $color-white;
        }
      }
    }

    .f--page-title + .text-cta {
      @include margin(rem(5) null null);

      @include mq($bp1024) {
        @include margin(0 null null cols(3, 43));
        flex: 0 0 cols(15, 43);
        width: cols(15, 43);
      }
    }

    .f--description {
      p {
        @extend %font-source-serif-regular;
        @include font-size(18, 26);

        @include mq($bp768) {
          @include font-size(22, 32);
        }

        @include mq($bp1024) {
          color: $color-white;
        }
      }

      a {
        @extend %font-source-serif-regular;
        color: $color-raleigh-red;
        font-family: inherit;
        text-decoration: underline;

        @include mq($bp1024) {
          color: $color-white;

          &:focus {
            outline-color: $color-white;
          }
        }

        @include hover {
          text-decoration: none;
        }
      }

      strong {
        @extend %font-source-serif-bold;
      }
    }

    .f--description + .f--link {
      @include margin(rem(10) null null);
    }

    .f--link {
      a {
        @include button-cta;

        @include mq($bp1024) {
          @include button-cta($text-color: $color-white, $svg-color: $color-york-yellow, $hover-color: $color-york-yellow);
        }
      }
    }
  }

  .c--hero-section-landing {
    .text-container-wrapper {
      .text-container {
        &.business-landing {
          position: relative;

          @include mq($bp768-1024) {
            @include padding(null null rem(35) null);
          }

          @include mq($bp1024) {
            &::after {
              display: none;
            }
          }

          .business-title-link {
            @include mq($bp1024) {
              flex: 0 0 cols(29, 43);
            }

            .f--description {
              @include margin(rem(5) null null null);

              @include mq($bp768) {
                @include margin(rem(15) null null null);
              }
            }
          }

          .business-logo {
            position: absolute;
            right: cols(2);
            bottom: calc(100% + #{rem(15)});
            max-width: rem(100);

            @include mq($bp768) {
              bottom: calc(100% + #{rem(30)});
              max-width: rem(175);
            }

            @include mq($bp1024) {
              @include padding(rem(20) null);
              position: relative;
              right: auto;
              flex: 0 0 cols(8, 43);
              max-width: 100%;
            }

            &::after {
              @include mq($bp1024) {
                content: '';
                position: absolute;
                top: 0;
                left: cols(-2, 8);
                width: rem(1);
                height: 100%;
                background-color: rgba($color-white, 0.3);
              }
            }
          }
        }
      }
    }
  }
}

.video-controls {
  display: none;
  position: absolute;
  z-index: 99;
  right: rem(15);
  bottom: 0;

  @include mq($bp1024) {
    display: block;
  }

  .video-controls-inner {
    display: flex;
  }

  .video-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(35);
    height: rem(35);
    padding: 0;
    border: 0;
    background-color: $color-ludwell-black;
    cursor: pointer;

    // pause button
    &.video-pause-button {
      display: flex;

      &.hidden {
        display: none;
      }
    }

    // play button
    &.video-play-button {
      display: none;

      &.active {
        display: flex;
      }
    }

    svg {
      width: rem(35);
      height: rem(35);
    }

    @include hover {

      svg {
        background: $color-black;

        path,
        polygon {
          fill: $color-white;
        }
      }
    }
  }
}

.cc--main-menu {
  background-color: $color-franklin-white;

  @include mq($bp1024) {
    background-color: transparent;
  }

  .m--menu {
    @include no-bullet;

    @include mq($bp1024) {
      display: flex;
      justify-content: flex-end;
      column-gap: rem(25);
    }
  }

  .cc--header-promo {
    display: none;

    .main-menu-open & {
      @include mq($bp1024) {
        display: block;
      }
    }
  }

  .home & {
    .m--menu .main-menu-item {
      @include mq($bp1024) {
        @for $i from 1 through 5 {
          &:nth-child(#{$i}) .menu-item-toggle {
            animation: fadeInDown 0.3s ease-out #{0.6 + ($i * 0.1)}s forwards;
            opacity: 0;
          }
        }

        &:last-child button:not(.arrow-toggle)::before {
          height: 0;
          animation: grow 0.3s ease-out 1.6s forwards;
        }
      }
    }
  }
}

@keyframes fadeInDown {
  from {
    transform: translateY(rem(-10));
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes grow {
  from {
    height: 0;
  }

  to {
    height: #{rem(35)};
  }
}
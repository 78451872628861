.cc--multiselect-dropdown {
  .c--multiselect-dropdown {
    span.filter-label {
      @extend %font-source-sans-regular;
      @include font-size(12, 14);
      @include margin(null null rem(5));
      display: block;
      letter-spacing: rem(1.5);
      text-transform: uppercase;

      @include mq($bp768) {
        @include font-size(14, 18);
      }
    }

    .select-container {
      @include no-bullet;
      position: relative;

      &.is-open {
        svg {
          z-index: 1;
          transform: rotate(180deg);
        }
      }

      .filter-option {
        @extend %font-source-sans-regular;
        @include font-size(14, 22);
        @include padding(rem(14) rem(50) rem(14) rem(15));
        height: rem(50);
        background-color: $color-white;
        color: $color-ludwell-black;
        cursor: pointer;

        &:focus-visible ~ svg {
          z-index: 99;
        }
      }

      .options-container {
        .options {
          @include centered(x);
          @include no-bullet;
          @include padding(rem(15) rem(15) null);
          display: none;
          z-index: 2;
          top: 0;
          width: 100%;
          border: rem(1) solid $color-nicholson-gray;
          background-color: $color-franklin-white;

          &.is-open {
            display: block;
            z-index: 100;
          }

          li {
            &:not(:last-child) {
              @include margin(null null rem(10));
            }
          }

          .filter-button {
            @include margin(rem(15) rem(-16) rem(-1));
  
            a {
              @include button(
                $background-color-default: $color-nicholson-gray,
                $btn-height-desktop: rem(50),
                $btn-height-tablet: rem(50)
              );
              width: 100%;
              color: $color-ludwell-black;

              &.is-active {
                background-color: $color-raleigh-red;
                color: $color-white;
              }
            }

            span {
              display: inline;
            }
          }
        }
      }

      svg {
        position: absolute;
        top: rem(15);
        right: rem(15);
        width: rem(20);
        height: rem(20);
        pointer-events: none;
      }
    }
  }
}
.f--eyebrow {
  @include margin(null null rem(15) null);
  color: $color-ludwell-black;

  @include mq($bp768) {
    @include margin(null null rem(15) null);
  }

  a,
  span {
    @extend %font-source-sans-regular;
    @include font-size(12, 14);
    color: inherit;
    letter-spacing: rem(1.5);
    text-decoration: none;
    text-transform: uppercase;

    @include mq($bp768) {
      @include font-size(14, 18);
      letter-spacing: rem(1.75);
    }
  }

  a {
    @include hover {
      text-decoration: underline;
    }
  }
}

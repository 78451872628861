.cc--contact-info-cards-grid {
  @include maxwidth;

  .c--contact-info-cards-grid {
    @include padding(null cols(4));
    @include component-padding;

    @include mq($bp1024) {
      @include padding(null 0);
    }

    > .f--section-title {
      text-align: center;

      @include mq($bp1024) {
        @include margin(null auto);
        max-width: cols(30);
      }
    }

    > .f--description {
      @include margin(rem(5) null null);
      text-align: center;

      @include mq($bp768) {
        @include margin(rem(10) null null);
      }

      @include mq($bp1024) {
        @include margin(null auto null);
        max-width: cols(30);
      }

      p {
        @include font-size(18, 34);
      }
    }

    .cards-container {
      @include margin(rem(25) null null null);

      @include mq($bp768) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: rem(25);
      }

      @include mq($bp1024) {
        row-gap: rem(50);
        column-gap: normal;
      }

      .card {
        @include padding(rem(20) null);
        text-align: center;

        @include mq($bp768) {
          flex: 0 0 cols(20, 40);
          @include padding(null cols(2));
        }

        @include mq($bp1024) {
          @include padding(null cols(2));
          flex: 0 0 cols(12);
          border-top: 0;
        }

        &:nth-child(odd) {
          @include mq($bp768-1024) {
            @include padding(null null null 0);
          }
        }

        &:nth-child(even) {
          @include mq($bp768-1024) {
            @include padding(null 0 null null);
          }
        }

        &:first-child {
          @include mq($bp768max) {
            @include padding(0 null null null);
          }
        }

        &:last-child {
          @include mq($bp768max) {
            @include padding(null null 0 null);
          }
        }

        &:not(:first-child) {
          @include mq($bp768max) {
            border-top: rem(1) solid $color-nicholson-gray;
          }
        }

        &:not(:last-child) {

          &:not(:nth-child(2n+2)) {
            @include mq($bp768-1024) {
              border-right: rem(1) solid $color-nicholson-gray;
            }
          }

          &:not(:nth-child(4n+4)) {
            @include mq($bp1024) {
              border-right: rem(1) solid $color-nicholson-gray;
            }
          }
        }


        .f--description {
          @extend %font-source-sans-regular;
          @include font-size(15, 25);

          @include mq($bp768) {
            @include font-size(16, 28);
            letter-spacing: rem(0.25);
          }

          p {
            @include font-size(15, 25);

            @include mq($bp768) {
              @include font-size(16, 28);
              letter-spacing: rem(0.25);
            }
          }

          &.contact-info-card-email {
            @include margin(rem(10) null null null);
          }

          .contact-info-card-phone {
            @include margin(rem(10) null null null);
          }
        }

        .f--link {
          @include margin(rem(10) null null null);

          a {
            @include plain-text-cta;
          }
        }
      }
    }
  }
}

.cc--social-media-embed {
  @include maxwidth;
  position: relative;
  overflow: hidden;

  .c--social-media-embed {
    @include padding(rem(35) null);


    @include mq($bp768) {
      @include padding(rem(50) null);
    }

    .inner-wrapper {
      @include margin(null null rem(20));
      @include padding(null cols(4) rem(20));
      position: relative;

      @include mq($bp768) {
        @include margin(null null rem(25));
      }

      @include mq($bp1024) {
        @include padding(null null rem(25));
      }

      &::after {
        @include centered(x);
        content: '';
        bottom: 0;
        width: calc(100% - #{cols(8)});
        height: rem(1);
        background-color: $color-nicholson-gray;

        @include mq($bp1024) {
          width: calc(100% - #{cols(6)});
        }
      }
    }

    .text-container {
      @include padding(null null rem(15));
      text-align: center;

      @include mq($bp768) {
        @include padding(null null rem(25));
      }

      @include mq($bp1024) {
        @include padding(null cols(7, 40) 0);
      }

      .f--section-title {
        h2 {
          @include font-size(40, 48);

          @include mq($bp768) {
            @include font-size(60, 70);
          }
        }
      }

      .f--sub-title {
        @include margin(rem(5) null null);
      }

      .f--description {
        @include margin(rem(5) null null);

        p {
          @include font-size(18, 34);
          letter-spacing: rem(0.25);
        }
      }
    }

    .social-networks {
      @include mq($bp1024) {
        position: absolute;
        top: rem(18);
        right: cols(4);
      }

      ul {
        @include no-bullet;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: rem(10);

        @include mq($bp768) {
          column-gap: rem(15);
        }

        @include mq($bp1024) {
          gap: rem(15);
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        li {
          svg {
            width: rem(35);
            height: rem(35);
          }

          @include hover {
            svg path {
              fill: $color-raleigh-red;
            }
          }
    
          a {
            @include vertical-align-flex;
          }
        }
      }
    }

    .f--wysiwyg {
      @include padding(null rem(5));

      @include mq($bp768) {
        @include padding(null rem(15));
      }

      .ctaExpanded {
        a {
          @include button-cta($color-white, $color-york-yellow, $color-white, $color-york-yellow);
          background-color: transparent !important;

          @include hover {
            border-color: $color-york-yellow;
            color: $color-york-yellow !important;

            span {
              border-color: $color-york-yellow;
              color: $color-york-yellow;
            }

            &::after {
              background: transparent;
            }
          }

          span {
            display: inline-block;
            position: relative;

            &::before {
              content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMjAgMjAiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICNlM2MyNjg7CiAgICAgICAgZmlsbC1ydWxlOiBldmVub2RkOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMjguNi4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogMS4yLjAgQnVpbGQgNzA5KSAgLS0+CiAgPGc+CiAgICA8ZyBpZD0iTGF5ZXJfMSI+CiAgICAgIDxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTE0LjEsOS43di42bC0uNy4zYy0yLjcsMS4xLTQsMy40LTYuMSw2bC0uNC0uMmMuNS0xLjksMi45LTQuOSw0LjItNi40di0uM2MtMS4yLTEuNS0zLjctNC4yLTQuMi02LjFsLjUtLjJjMi4xLDIuNSwzLjQsNC44LDYuMSw2bC43LjNaIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4=');
              @include centered(y);
              left: calc(100% + #{rem(10)});
            }
          }
        }

        .iconWrapper.ctaExpanded__icon {
          svg {
            display: none;
          }
        }
      }

      .cr__gallery .cr__asset__button {
        border-radius: 0 !important;

        &:focus-visible {
          outline-color: $color-black;
        }
      }

      .loadMore {
        @include button($outline: true);
        display: inline-flex;
        left: 50%;
        align-items: center;
        justify-content: center;
        width: auto !important;
        min-width: rem(225);
        margin: rem(15) auto 0 !important;
        transform: translateX(-50%);
        border-radius: 0;
        background-color: transparent !important;

        @include mq($bp768) {
          @include margin(rem(25) null null);
        }

        &::after {
          background: transparent;
        }

        span {
          color: $color-ludwell-black;
        }
      }
    }
  }
}

.cr__gallery {
  .fullscreenDetail__container {
    .ctaPanel__button {
      @include button-cta($color-white, $color-york-yellow, $color-white, $color-york-yellow);
      background-color: transparent !important;

      @include hover {
        border-color: $color-york-yellow;
        color: $color-york-yellow !important;

        span {
          border-color: $color-york-yellow;
          color: $color-york-yellow;
        }

        &::after {
          background: transparent;
        }
      }

      span {
        display: inline-block;
        position: relative;

        &::before {
          content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMjAgMjAiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICNlM2MyNjg7CiAgICAgICAgZmlsbC1ydWxlOiBldmVub2RkOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMjguNi4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogMS4yLjAgQnVpbGQgNzA5KSAgLS0+CiAgPGc+CiAgICA8ZyBpZD0iTGF5ZXJfMSI+CiAgICAgIDxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTE0LjEsOS43di42bC0uNy4zYy0yLjcsMS4xLTQsMy40LTYuMSw2bC0uNC0uMmMuNS0xLjksMi45LTQuOSw0LjItNi40di0uM2MtMS4yLTEuNS0zLjctNC4yLTQuMi02LjFsLjUtLjJjMi4xLDIuNSwzLjQsNC44LDYuMSw2bC43LjNaIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4=');
          @include centered(y);
          left: calc(100% + #{rem(10)});
        }
      }

      .iconWrapper.ctaPanel__icon {
        svg {
          display: none;
        }
      }
    }
  }

  .fullscreen__previousHold,
  .fullscreen__nextHold {
    @include vertical-align-flex;
    width: rem(20) !important;
    height: rem(20) !important;

    &:focus-visible {
      position: absolute;
      outline-color: $color-white;
    }

    &:focus,
    &:hover {
      .fullscreen__next,
      .fullscreen__previous {
        background: none !important;
      }
    }
  }

  .fullscreen__previousHold {
    left: rem(10) !important;
  }

  .fullscreen__nextHold {
    right: rem(10) !important;
  }

  .fullscreen__next,
  .fullscreen__previous {
    width: rem(20) !important;
    height: rem(20) !important;
  }

  .fullscreen__next {
    right: rem(10) !important;
  }

  .fullscreen__previous {
    left: rem(10) !important;
  }

  .fullscreen__close:focus-visible {
    position: absolute;
    outline-color: $color-white;
  }

  .fullscreen__ahref img:focus-visible {
    outline: none;
  }

  .mobileImage__ctas {
    .mobileCtas__button {
      background: none !important;

      &::after {
        display: none;
      }
    }

    .mobileCtas__icon {
      display: none;
    }

    a {
      @include button-cta($color-white, $color-york-yellow, $color-white, $color-york-yellow);
      background-color: transparent !important;

      @include hover {
        border-color: $color-york-yellow;
        color: $color-york-yellow !important;

        span {
          border-color: $color-york-yellow;
          color: $color-york-yellow;
        }

        &::after {
          background: transparent;
        }
      }

      span {
        display: inline-block;
        position: relative;

        &::before {
          content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMjAgMjAiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICNlM2MyNjg7CiAgICAgICAgZmlsbC1ydWxlOiBldmVub2RkOwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMjguNi4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogMS4yLjAgQnVpbGQgNzA5KSAgLS0+CiAgPGc+CiAgICA8ZyBpZD0iTGF5ZXJfMSI+CiAgICAgIDxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTE0LjEsOS43di42bC0uNy4zYy0yLjcsMS4xLTQsMy40LTYuMSw2bC0uNC0uMmMuNS0xLjksMi45LTQuOSw0LjItNi40di0uM2MtMS4yLTEuNS0zLjctNC4yLTQuMi02LjFsLjUtLjJjMi4xLDIuNSwzLjQsNC44LDYuMSw2bC43LjNaIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4=');
          @include centered(y);
          left: calc(100% + #{rem(10)});
        }
      }
    }
  }
}
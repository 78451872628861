.cc--header {
  position: absolute;
  z-index: 9999;
  top: 0;
  width: 100%;
  transition: transform $transition-duration-default $transition-easing-default;
  background: $color-franklin-white;

  body:not(.main-menu-open):not(.mobile-menu-open) &.scroll-down {
    transform: translateY(-100%);
  }

  body:not(.mobile-menu-open):not(:has(.cc--chapter-jump-links)):not(
      :has(.cc--booking-widget-hotel)
    )
    &.scroll-up {
    position: fixed;
    transform: translateY(0);
  }

  body:not(:has(.cc--booking-widget-hotel)) & {
    @include mq($bp1024) {
      position: fixed;
      transform: translateY(0);
    }
  }

  .c--header {
    .header-wrapper {
      @include maxwidth;
    }

    .header-inner {
      @include padding(null cols(2) null);

      @include mq($bp768) {
        @include padding(null cols(3) null);
      }

      @include mq($bp1024) {
        @include padding(null cols(2));
      }
    }
  }

  .desktop-nav {
    display: none;

    @include mq($bp1024) {
      @include padding(rem(15) null null);
      display: block;
      height: rem(185);
    }

    .branding-container {
      @include mq($bp1024) {
        flex: 0 0 cols(14, 42);
      }
    }

    .nav-wrapper {
      @include mq($bp1024) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .utility-nav-container {
      @include padding(rem(25) null rem(15));
      display: flex;
      position: relative;
      align-items: center;

      @include mq($bp1024) {
        @include padding(0 null);
      }

      &::after {
        @include mq($bp1024) {
          content: "";
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          width: 100%;
          height: rem(1);
          background-color: $color-nicholson-gray;
        }
      }

      .cc--utility-menu {
        flex: 1 0 auto;
      }
    }

    .main-menu-close {
      @include padding(0);
      visibility: hidden;
      position: absolute;
      top: rem(210);
      right: cols(1);
      border: 0;
      opacity: 0;
      background-color: transparent;

      @include hover {
        cursor: pointer;
      }

      svg {
        width: rem(35);
        height: rem(35);

        polygon {
          fill: $color-raleigh-red;
        }
      }
    }
  }

  .mobile-nav {
    @include mq($bp1024) {
      display: none;
    }

    &-topbar-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: rem(80);

      @include mq($bp768) {
        height: rem(115);
      }

      .mobile-buttons {
        display: flex;
        align-items: center;
      }

      .icon-search {
        @include margin(null rem(15) null null);
      }

      .mobile-menu-trigger {
        @include vertical-align-flex;
        flex: 0 0 rem(30);
        width: rem(30);
        height: rem(30);
        padding: 0;
        border: 0;
        background-color: transparent;

        @include mq($bp768) {
          width: rem(35);
          height: rem(35);
        }

        svg {
          &.icon-hamburger,
          &.icon-close {
            width: rem(30);
            height: rem(30);

            @include mq($bp768) {
              width: rem(35);
              height: rem(35);
            }
          }
        }

        .icon-close {
          display: none;
        }

        &.is-active {
          .icon-hamburger {
            display: none;
          }

          .icon-close {
            display: block;
          }
        }
      }
    }

    &-menu-container {
      @include margin(rem(-1) null null);
      display: none;
      position: fixed;
      z-index: 99;
      top: rem(80);
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      overflow: auto;
      background-color: rgba($color-black, 0.8);

      @include mq($bp768) {
        top: rem(115);
      }
    }
  }

  .transparent-header & {
    background: transparent;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      width: 100%;
      height: rem(150);
      opacity: 0.7;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );

      @include mq($bp768) {
        height: rem(200);
      }

      @include mq($bp1024) {
        min-height: rem(335);
      }
    }

    .utility-nav-container {
      &::after {
        @include mq($bp1024) {
          background-color: rgba($color-white, 0.3);
        }
      }
    }

    .mobile-menu-trigger {
      svg.icon-hamburger path {
        fill: $color-white;
      }
    }
  }

  .static-header & {
    position: absolute;

    .scroll-down {
      transform: translateY(0);

      @include mq($bp768) {
        transform: translateY(0);
      }

      @include mq($bp1024) {
        transform: translateY(0);
      }
    }
  }

  .mobile-menu-open & {
    position: fixed;

    @include mq($bp1024max) {
      background: $color-franklin-white;
    }

    &::before {
      @include mq($bp1024max) {
        display: none;
      }
    }

    &::after {
      display: none;
    }
  }

  .main-menu-open & {
    position: fixed;

    @include mq($bp1024) {
      height: 100vh;
      background: $color-franklin-white;

      .utility-nav-container {
        &::after {
          background-color: $color-nicholson-gray;
        }
      }

      .main-menu-close {
        visibility: visible;
        opacity: 1;
      }
    }

    &::before {
      @include mq($bp1024) {
        display: none;
      }
    }

    &::after {
      @include mq($bp1024) {
        @include centered(x);
        content: "";
        display: block;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        background-color: $color-white;
      }
    }
  }

  .home & {
    .utility-nav-container::after {
      @include mq($bp1024) {
        animation: leftToRight 1s ease-out 0.5s forwards;
        mask-size: 0%;
        mask-repeat: no-repeat;
        mask-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 1) 0,
          rgba(0, 0, 0, 0)
        );
      }
    }
  }
}

body.mobile-menu-open {
  @include mq($bp1024max) {
    overflow: hidden;
  }
}

body.main-menu-open {
  @include mq($bp1024) {
    overflow: hidden;
  }
}

@keyframes leftToRight {
  from {
    mask-size: 0%;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0));
  }

  to {
    mask-size: 100%;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 100%,
      rgba(0, 0, 0, 0)
    );
  }
}

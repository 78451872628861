.cc--utility-menu {
  @include padding(rem(25) null rem(20));
  position: relative;
  background-color: $color-franklin-white;

  @include mq($bp768) {
    @include padding(rem(35) null rem(25));
  }

  @include mq($bp1024) {
    @include padding(0);
  }

  .c--utility-menu {
    position: relative;

    &::before,
    &::after {
      @include centered(x);
      content: '';
      width: calc(100% - #{cols(8)});
      height: rem(1);
      background-color: $color-nicholson-gray;

      @include mq($bp1024) {
        display: none;
      }
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    .menu-container {
      @include mq($bp1024) {
        display: flex;
        position: relative;
        z-index: 2;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  .m--menu {
    @include no-bullet;

    @include mq($bp1024) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    > li {
      position: relative;

      &:first-child {
        @include padding(rem(12.5) null null);

        @include mq($bp768) {
          @include padding(rem(15) null null);
        }

        @include mq($bp1024) {
          @include padding(0 null null);
        }
      }

      &:last-child {
        @include padding(null null rem(12.5));

        @include mq($bp768) {
          @include padding(null null rem(15));
        }

        @include mq($bp1024) {
          @include padding(null null 0);
        }
      }

      &.is-open {
        button {
          color: $color-raleigh-red;

          svg {
            transform: rotate(180deg);

            path {
              fill: $color-raleigh-red;
            }
          }
        }
      }

      button {
        @extend %font-source-sans-semibold;
        @include padding(rem(7.5) 0 rem(7.5) cols(4));
        @include font-size(14, 26);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 0;
        background-color: transparent;
        color: $color-ludwell-black;
        cursor: pointer;

        @include mq($bp768) {
          @include font-size(16, 26);
          @include padding(rem(10) null rem(10) null);
        }

        @include mq($bp1024) {
          @include font-size(14, 26);
          @include padding(rem(15) rem(15) null rem(15));
          justify-content: flex-start;
          width: auto;
          column-gap: rem(5);

          @include hover {
            background-color: $color-white;
            color: $color-raleigh-red;

            svg path {
              fill: $color-raleigh-red;
            }
          }
        }

        svg {
          @include margin(null cols(4, 44) null null);
          width: rem(15);
          height: rem(15);

          @include mq($bp768) {
            width: rem(20);
            height: rem(20);
          }

          @include mq($bp1024) {
            @include margin(null 0 null null);
            width: rem(15);
            height: rem(15);
          }

          path {
            fill: $color-ludwell-black;
          }
        }
      }

      &:hover {
        @include mq($bp1024) {
          background-color: $color-white;

          button {
            color: $color-raleigh-red;

            svg {
              transform: rotate(180deg);

              path {
                fill: $color-raleigh-red;
              }
            }
          }

          .submenus-wrapper {
            display: block !important;
            box-shadow: 0 rem(10) rem(10) rgba($color-black, 0.2);
          }
        }
      }
    }

    .submenus-wrapper {
      @include padding(rem(20) cols(4));
      @include margin(rem(10) null null);
      display: none;
      position: relative;
      background-color: $color-white;

      @include mq($bp1024) {
        @include margin(0 null null);
        @include padding(null rem(15));
        position: absolute;
        z-index: 1;
        width: rem(230);
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        bottom: calc(100% - #{rem(1)});
        left: cols(2, 40);
        width: rem(65);
        height: rem(12);
        background-image: url('../images/interface/interface-insetarrow-up.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include mq($bp1024) {
          display: none;
        }
      }

      .submenu {
        @include no-bullet;

        li {
          &:not(:last-child) {
            @include margin(null null rem(15));

            @include mq($bp768) {
              @include margin(null null rem(20));
            }

            @include mq($bp1024) {
              @include margin(null null rem(15));
            }
          }

          a {
            @extend %font-source-sans-semibold;
            @include font-size(14, 20);
            color: $color-ludwell-black;
            text-decoration: none;

            @include mq($bp768) {
              @include font-size(16, 20);
            }

            @include mq($bp1024) {
              @include font-size(14, 20);
            }

            @include hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .transparent-header & {
    @include mq($bp1024) {
      background-color: transparent;
    }

    .m--menu {
      > li {
        button {
          @include mq($bp1024) {
            color: $color-white;

            svg path {
              fill: $color-white;
            }

            &:focus {
              @include outline($color: $color-focus-light);
            }
          }
        }

        &:hover {
          button {
            @include mq($bp1024) {
              color: $color-raleigh-red;

              svg path {
                fill: $color-raleigh-red;
              }
            }
          }
        }

        &.is-open {
          button {
            color: $color-raleigh-red;

            @include mq($bp1024) {
              background-color: $color-white;
            }

            svg {
              transform: rotate(180deg);

              path {
                fill: $color-raleigh-red;
              }
            }
          }
        }
      }
    }
  }

  .main-menu-open & {
    .m--menu {
      > li {
        button {
          color: $color-ludwell-black;

          &:focus {
            @include outline($color: $color-focus);
          }

          svg path {
            fill: $color-ludwell-black;
          }
        }
      }
    }
  }

  .home & {
    .m--menu {
      @include mq($bp1024) {
        @for $i from 1 through 2 {
          &:nth-child(#{$i}) .link-arrow-wrapper {
            animation: fadeIn 0.3s ease-out #{0.8 + ($i * 0.2)}s forwards;
            opacity: 0;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

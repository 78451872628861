.cc--hero-explore {
  position: relative;

  .c--hero-explore {
    @include maxwidth;
    aspect-ratio: 3 / 2;

    @include mq($bp1024) {
      aspect-ratio: 3 / 1;
      min-height: rem(420);
    }

    .image-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .f--image {
        position: relative;
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;

          @include mq($bp1800) {
            max-height: rem(900);
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-black, 0.42);

        @include mq($bp1024) {
          opacity: 0.85;
          background: linear-gradient(to bottom, transparent 0%, $color-black 100%);
        }
      }
    }

    .title-container {
      @include centered(x);
      @include padding(null cols(4) rem(35));
      z-index: 1;
      bottom: 0;
      width: 100%;
      text-align: center;

      @include mq($bp1024) {
        @include padding(null null rem(50));
        display: flex;
        justify-content: center;
        column-gap: rem(16);
      }

      span {
        @extend %font-source-serif-semibold;
        @include font-size(40, 48);
        display: block;
        color: $color-white;

        @include mq($bp768) {
          @include font-size(64, 70);
        }
      }

      .f--page-title {
        display: inline-block;
        border-bottom: rem(1) dashed #CCC;

        h1 {
          color: $color-white;
        }
      }
    }
  }
}

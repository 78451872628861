.tc--scheduled-events {
  .content-main {
    @include padding(null null rem(50));
    position: relative;
    overflow: hidden;

    @include mq($bp768) {
      @include padding(null null rem(100));
    }

    .cc--component-container {
      &.cc--card-event {
        @include margin(0 null null);

        &.featured {
          @include margin(null null 0);

          @include mq($bp768max) {
            @include margin(rem(10) null null);
          }

          @include mq($bp768-1024) {
            @include padding(null null rem(25));
          }

          .c--card-event {
            @include mq($bp768-1024) {
              @include padding(rem(35) null);
            }
          }
        }

        &:not(.add-link) {
          @include mq($bp768max) {
            @include margin(null null 0);
          }

          .c--card-event {
            @include mq($bp768-1024) {
              @include padding(null null 0);
            }
          }
        }

        .c--card-event {
          @include mq($bp768-1024) {
            @include padding(rem(25) null);
          }
        }
      }
    }
  }
}

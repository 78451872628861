.cc--chapter-jump-links {
  @include component-box-shadow;
  z-index: 3;
  background-color: $color-white;

  .c--chapter-jump-links {
    @include maxwidth;

    .inner-wrapper {
      @include padding(rem(15) cols(4) rem(25));

      @include mq($bp1024) {
        @include padding(rem(25) cols(3));
      }
    }
  }

  h3 {
    @extend %font-source-sans-regular;
    @include margin(null null rem(20));
    @include font-size(12, 14);
    color: $color-ludwell-black;
    letter-spacing: rem(1.5);
    text-transform: uppercase;

    @include mq($bp768) {
      @include font-size(14, 18);
      letter-spacing: rem(1.75);
    }

    @include mq($bp1024) {
      display: none;
    }
  }

  .chapter-menu {
    @include no-bullet;
    display: flex;
    position: relative;
    flex-direction: column;
    row-gap: rem(15);

    @include mq($bp768) {
      row-gap: rem(20);
    }

    @include mq($bp1024) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    li {
      a {
        @extend %font-source-serif-semibold;
        @include font-size(18, 28);
        display: inline-block;
        position: relative;
        width: 100%;
        color: $color-ludwell-black;
        text-decoration: none;

        @include hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.cc--tab-links {
  @include maxwidth;
  @include padding(rem(35) cols(4) rem(20));
  position: relative;
  overflow: hidden;

  @include mq($bp768) {
    @include padding(rem(50) null rem(25));
  }

  @include mq($bp1024) {
    @include padding(null cols(2) null);
  }

  .c--tab-links {
    .title-container {
      @include margin(null null rem(20));
      text-align: center;

      @include mq($bp768) {
        @include margin(null null rem(35));
      }

      @include mq($bp1024) {
        @include margin(null auto null);
        max-width: cols(30, 44);
      }

      .f--section-title {
        h2 {
          @include font-size(40, 48);

          @include mq($bp768) {
            @include font-size(60, 70);
          }
        }
      }

      .f--description {
        p {
          @include font-size(18, 34);
          letter-spacing: rem(0.25);

          &:not(:last-child) {
            @include margin(null null rem(10));
          }
        }
      }
    }

    .inner-wrapper {
      @include padding(null null rem(20));
      position: relative;
      border-bottom: rem(1) solid $color-raleigh-red;

      &::before {
        @include centered(x);
        content: '';
        bottom: rem(-4);
        width: 100%;
        height: rem(8);
        border-right: rem(1) solid $color-raleigh-red;
        border-left: rem(1) solid $color-raleigh-red;
      }

      &::after {
        @include centered(x);
        content: '';
        display: block;
        z-index: 1;
        bottom: rem(-1);
        width: rem(44);
        height: rem(8);
        background-image: url('../images/interface/interface-insetarrow-up.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        filter: drop-shadow(0 rem(-1) 0 $color-raleigh-red);

        @include mq($bp1024) {
          display: none;
        }
      }
    }

    .select-container {
      @include no-bullet;
      position: relative;

      @include mq($bp768) {
        row-gap: rem(20)
      }

      @include mq($bp1024) {
        display: none;
      }

      &.is-open {
        svg {
          transform: rotate(180deg);
        }
      }

      .options-container {
        flex: 0 1 100%;

        .options {
          @include no-bullet;
          display: none;

          &.is-open {
            @include margin(rem(20) null null);
            display: block;
          }

          li {
            &:not(:last-child) {
              @include margin(null null rem(20));
            }
          }
        }
      }

      svg {
        position: absolute;
        top: rem(15);
        right: 0;
        width: rem(25);
        height: rem(25);
        pointer-events: none;
      }
    }

    .link-container {
      display: none;

      @include mq($bp1024) {
        display: block;
      }

      .menu {
        @include no-bullet;

        @include mq($bp1024) {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    li {
      width: 100%;

      @include mq($bp1024) {
        flex: 0 0 cols(5, 44);
      }

      &.is-active {
        position: relative;

        &::after {
          @include centered(x);
          content: '';
          display: block;
          z-index: 1;
          bottom: rem(-21);
          width: rem(44);
          height: rem(8);
          background-image: url('../images/interface/interface-insetarrow-up.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          filter: drop-shadow(0 rem(-1) 0 $color-raleigh-red);
        }

        a {
          color: $color-raleigh-red;
        }
      }

      a,
      span {
        @extend %font-source-serif-semibold;
        @include font-size(18, 28);
        display: flex;
        align-items: center;
        color: $color-ludwell-black;
        text-decoration: none;

        @include mq($bp1024) {
          @include font-size(16, 22);
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          text-align: center;
        }

        @include hover {
          color: $color-raleigh-red;
          cursor: pointer;
        }
      }

      .f--image {
        @include margin(null rem(10) null null);
        width: rem(50);
        height: rem(50);

        @include mq($bp1024) {
          @include margin(null 0 rem(10) null);
          width: rem(75);
          height: rem(75);
        }

        img {
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }

    .decoration-image {
      position: absolute;
      z-index: -1;
      top: 0;
      right: cols(-4, 40);
      width: rem(200);
      height: rem(200);

      @include mq($bp768) {
        width: rem(350);
        height: rem(350);
      }

      @include mq($bp1024) {
        right: rem(-85);
        width: rem(400);
        height: rem(400);
      }
    }
  }
}
.f--image {

  > a {
    display: block;
  }

  figure {
    @include margin(0);
  }
}

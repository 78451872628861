.cc--featured-special-event {
  position: relative;

  .c--featured-special-event {
    @include maxwidth;

    @include mq($bp1024) {
      @include padding(null null 0);
      display: flex;
      align-items: flex-end;
      width: 100%;
      aspect-ratio: 16 / 9;
    }

    .image-container {

      @include mq($bp1024) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .f--image {
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
          aspect-ratio: 3 / 2;

          @include mq($bp1024) {
            aspect-ratio: 16 / 9;
          }
        }
      }

      &::after {
        @include mq($bp1024) {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.85;
          background: linear-gradient(to right, $color-black 0%, transparent 100%);
        }
      }
    }

    .text-container-wrapper {
      position: relative;

      @include mq($bp1024) {
        left: cols(4);
        align-self: center;
        max-width: cols(18);
      }
    }

    .text-container {
      @include padding(rem(20) cols(4) rem(35));
      background-color: $color-white;
      color: $color-ludwell-black;

      @include mq($bp1024) {
        @include padding(rem(75) 0 rem(75));
        background-color: transparent;
        color: $color-white;
      }

      .f--eyebrow {
        @include mq($bp1024) {
          color: $color-white;
        }
      }

      .f--sub-title {
        @include margin(rem(10) null null null);

        @include mq($bp768) {
          @include margin(rem(10) null null null);
        }
      }

      .f--description {
        @include margin(rem(20) null null null);

        @include mq($bp768) {
          @include margin(rem(25) null null null);
        }

        p {
          a {
            color: $color-raleigh-red;

            @include mq($bp1024) {
              color: $color-white;
            }
          }
        }
      }

      .links-plain-container {
        @include margin(rem(15) null null null);

        @include mq($bp768) {
          @include margin(rem(25) null null null);
        }

        @include mq($bp1024) {
          @include margin(rem(15) null null null);
        }

        .f--link {
          a {
            @include button-cta(
              $text-color: $color-ludwell-black,
              $hover-color: $color-raleigh-red,
              $outline-color: $color-ludwell-black,
              $svg-color: $color-raleigh-red
            );

            @include mq($bp1024) {
              @include button-cta(
                $text-color: $color-white,
                $hover-color: $color-york-yellow,
                $outline-color: $color-white,
                $svg-color: $color-york-yellow
              );
            }
          }
        }
      }

      .links-container {
        @include margin(rem(25) null null);

        @include mq($bp768) {
          @include margin(rem(35) null null null);
        }

        @include mq($bp1024) {
          display: flex;
        }

        .f--link {

          &:not(:last-child) {
            @include margin(null null rem(10) null);

            @include mq($bp1024) {
              @include margin(null rem(10) 0 null);
            }
          }

          a {
            @include mq($bp1024max) {
              @include button(
                $outline: true,
                $hover-bg-color: $color-randolph-burgundy
              );
            }

            @include mq($bp1024) {
              @include button(
                $outline: true,
                $text-color: $color-white,
                $outline-color: $color-york-yellow,
                $focus-color: $color-focus-light
              );
            }
          }
        }
      }
    }
  }
}

.cc--featured-block-full-width-carousel {
  @include maxwidth;

  .c--featured-block-full-width-carousel {
    @include padding(rem(25) null);

    @include mq($bp768) {
      @include padding(rem(50) cols(4));
    }

    .title-description {
      @include padding(null cols(4) rem(30));
      position: relative;

      @include mq($bp768) {
        @include padding(null 0 rem(25));
      }

      @include mq($bp1024) {
        @include margin(null null rem(35));
        @include padding(null null 0);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & > div:nth-child(2) {
        &::before {
          @include mq($bp1024) {
            @include centered(x);
            content: "";
            top: rem(-10);
            width: rem(1);
            height: calc(100% + #{rem(20)});
            background-color: $color-nicholson-gray;
          }
        }
      }

      .f--section-title {
        @include mq($bp1024) {
          @include margin(null cols(2, 42) null null);
          max-width: cols(18, 42);
        }

        h2 {
          @include font-size(40, 48);
          color: $color-ludwell-black;

          @include mq($bp768) {
            @include font-size(60, 70);
          }
        }
      }

      .f--description {
        @include mq($bp1024) {
          @include margin(null null null cols(2, 42));
          max-width: cols(18, 42);
        }

        p {
          @include font-size(16, 30);
          letter-spacing: rem(0.2);

          @include mq($bp768) {
            @include font-size(18, 34);
            letter-spacing: rem(0.25);
          }

          &:not(:last-child) {
            @include margin(null null rem(10));
          }

          a {
            color: $color-raleigh-red;
            font-weight: inherit;
            text-decoration: underline;

            &:hover {
              color: $color-ludwell-black;
              text-decoration: none;
            }
          }
        }
      }
    }

    .inner-wrapper {
      position: relative;
    }

    .mySwiper {
      overflow: visible;

      .swiper-wrapper {
        overflow: visible;

        .swiper-slide {
          position: relative;
          transition: opacity $transition-duration-default
            $transition-easing-default;
          opacity: 0 !important;

          @include mq($bp1024) {
            @include padding(null cols(11, 40) null null);
          }

          &.swiper-slide-active {
            opacity: 1 !important;
          }
        }
      }

      .navigation-wrapper {
        @include margin(null null null cols(-0.5, 40));
        @include padding(null cols(4) rem(15));
        display: flex;
        position: static;
        align-items: center;
        justify-content: space-between;

        @include mq($bp768) {
          @include padding(null 0 null);
        }

        @include mq($bp1024) {
          @include margin(null null null 0);
          @include padding(null null 0);
          position: absolute;
          bottom: 0;
          left: calc(#{cols(-1, 40)} - #{rem(50)});
          flex-direction: column;
          justify-content: space-between;
          height: calc(56.25% + #{rem(45)});
        }

        .swiper-buttons {
          display: flex;
          position: relative;
          z-index: 1;
          align-items: center;
          justify-content: space-between;
          width: rem(90);

          @include mq($bp768) {
            width: rem(97.13);
          }

          @include mq($bp1024) {
            @include margin(null null null 0);
            flex-direction: column;
            width: rem(50);
            height: rem(90);
            row-gap: rem(30);
          }

          &::before {
            @include centered(x);
            content: "";
            top: rem(-0.5);
            width: rem(1);
            height: calc(100% + #{rem(1)});
            background-color: $color-nicholson-gray;

            @include mq($bp1024) {
              top: 50%;
              width: rem(40);
              height: rem(1);
            }
          }

          .swiper-button-prev,
          .swiper-button-next {
            appearance: none;
            display: block;
            position: relative;
            top: 0;
            right: auto;
            left: auto;
            width: rem(30);
            height: rem(30);
            margin: 0;
            padding: 0;
            border: 0;
            background: transparent;

            &::after {
              content: "";
              display: block;
              width: rem(30);
              height: rem(30);
            }

            &.swiper-button-disabled {
              opacity: 1;
  
              svg path {
                fill: $color-nicholson-gray;
              }
            }

            svg path {
              transition: fill $transition-duration-default
                $transition-easing-default;
              fill: $color-raleigh-red;
            }

            &:hover,
            &:focus-visible {
              svg path {
                fill: $color-randolph-burgundy;
              }
            }
          }
        }

        .swiper-pagination {
          display: flex;
          position: relative;
          bottom: auto;
          align-items: center;
          width: auto;
          column-gap: rem(15);

          @include mq($bp1024) {
            flex-direction: column;
            row-gap: rem(15);
          }

          .swiper-pagination-bullet {
            width: rem(5);
            height: rem(5);
            margin: 0;
            opacity: 1;
            background: $color-nicholson-gray;

            &.swiper-pagination-bullet-active {
              background: $color-raleigh-red;
            }
          }
        }
      }
    }

    .card-container {
      @include padding(null cols(4));
      position: relative;
      z-index: 1;

      @include mq($bp768) {
        @include padding(null cols(2, 40));
      }

      @include mq($bp1024) {
        @include centered(y);
        @include padding(null 0);
        right: cols(-2, 40);
        width: cols(18, 40);
      }

      .text-container {
        @include component-box-shadow;
        @include margin(rem(-25) null null);
        @include padding(rem(25) cols(4, 40));
        position: relative;
        background-color: $color-white;

        @include mq($bp768) {
          @include padding(rem(35) cols(2, 36));
        }

        @include mq($bp1024) {
          @include margin(0 null null);
          @include padding(rem(50) cols(2, 18));
        }

        .eyebrow-container {
          @include margin(null null rem(10));
          display: flex;
          flex-wrap: wrap;

          @include mq($bp768) {
            @include margin(null null rem(20));
          }

          .f--eyebrow {
            @include margin(null null 0);
            position: relative;

            &:not(:last-child) {
              &::after {
                @extend %font-source-sans-regular;
                @include padding(null rem(7.5) null rem(5));
                @include font-size(12, 22);
                content: "|";
                display: inline-flex;
                color: $color-ludwell-black;
              }
            }

            span {
              @include line-height(12, 14);
              color: $color-ludwell-black;

              @include mq($bp768) {
                @include line-height(14, 18);
              }
            }
          }
        }

        .f--cta-title {
          @include margin(null null rem(5));

          h3 {
            @extend %font-source-serif-regular;
            @include font-size(30, 40);
            color: $color-ludwell-black;

            @include mq($bp1024) {
              @include font-size(36, 46);
              @include margin(null null rem(5));
            }
          }
        }

        .f--description {
          p {
            @include font-size(15, 25);

            @include mq($bp768) {
              @include font-size(16, 28);
              letter-spacing: rem(0.25);
            }

            a {
              color: $color-raleigh-red;
              font-weight: inherit;
              text-decoration: underline;

              &:hover {
                color: $color-ludwell-black;
                text-decoration: none;
              }
            }
          }
        }

        .links-container {
          @include margin(rem(15) null null);

          @include mq($bp768) {
            display: flex;
            column-gap: rem(10);
          }

          .f--link {
            a {
              @include button($outline: true);
            }

            &:nth-child(2) {
              @include margin(rem(10) null null);

              @include mq($bp768) {
                @include margin(0 null null);
              }

              a {
                @include button($outline: false);
              }
            }
          }
        }
      }
    }
  }

  &.image-right {
    .c--featured-block-full-width-carousel {
      .mySwiper {
        .swiper-slide {
          @include mq($bp1024) {
            @include padding(null 0 null cols(11, 40));
          }
        }

        .navigation-wrapper {
          @include mq($bp1024) {
            right: calc(#{cols(-1, 40)} - #{rem(50)});
            left: auto;
          }
        }
      }

      .card-container {
        @include mq($bp1024) {
          right: auto;
          left: cols(-2, 40);
        }
      }
    }
  }
}

.cc--related-content {
  position: relative;
  overflow: hidden;

  .tc--special-offer .content-main &:last-child {
    .inner-wrapper {
      @include padding(null null 0 null);
    }
  }

  .c--related-content {
    @include maxwidth;
  }

  .inner-wrapper {
    @include mq($bp1024) {
      @include padding(rem(50) cols(2) rem(50) null);
      display: flex;
    }
  }

  .f--cta-title {
    h3 {
      @extend %font-source-serif-regular;
    }

    a {
      color: inherit;
      text-decoration: none;

      @include hover {
        text-decoration: underline;
      }
    }
  }

  h4 {
    @extend %font-source-serif-regular-italic;
    @include margin(null null rem(15));
    @include font-size(22, 26);
    letter-spacing: rem(8.5);
    text-transform: uppercase;

    @include mq($bp768) {
      @include font-size(26, 30);
    }

    @include mq($bp1024) {
      @include padding(null null null cols(4, 16));
      text-align: right;
    }
  }

  .featured-card {
    position: relative;
    z-index: 2;

    @include mq($bp1024) {
      flex: 0 0 cols(32, 46);
    }

    .f--image {
      @include margin(rem(15) null null);
      position: relative;

      @include mq($bp768) {
        @include margin(0 null null);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 5;
        bottom: rem(-1);
        left: cols(2);
        width: rem(65);
        height: rem(12);
        background-image: url('../images/interface/interface-insetarrow-up.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @include mq($bp768) {
          left: cols(2);
          width: rem(100);
          height: rem(18);
        }

        @include mq($bp1024) {
          left: cols(3, 32);
        }
      }
    }

    .decorative-image {
      position: absolute;
      z-index: -1;
      top: rem(35);
      right: cols(-2, 20);
      width: rem(200);
      height: rem(200);

      &:after {
        display: none;
      }

      @include mq($bp768) {
        top: rem(25);
        right: cols(-2, 40);
        width: rem(300);
        height: rem(300);
      }

      @include mq($bp1024) {
        top: rem(25);
        right: cols(1, 32);
      }
    }

    .f--cta-title {
      h3 {
        @include font-size(40, 48);

        @include mq($bp768) {
          @include font-size(60, 70);
        }
      }
    }

    .f--description {
      @include margin(rem(20) null null);

      @include mq($bp768) {
        @include margin(rem(24) null null);
      }

      p {
        @include mq($bp768) {
          @include font-size(18, 34);
        }
      }

      &.card-subtitle {
        p {
          @extend %font-source-serif-regular;
          @include font-size(18, 26);

          @include mq($bp768) {
            @include font-size(22, 32);
          }
        }
      }
    }

    .f--description + .f--description {
      @include margin(rem(5) null null);
    }

    .f--link {
      @include margin(rem(15) null null);

      @include mq($bp768) {
        @include margin(rem(20) null null);
      }

      a {
        @include button($outline: true);
      }
    }

    .text-container {
      @include padding(rem(20) cols(4) rem(35));
      position: relative;

      @include mq($bp768) {
        @include padding(rem(35) null rem(50));
      }

      @include mq($bp1024) {
        @include padding(null cols(4, 32) 0);
      }
    }
  }

  .sidebar-content {
    @include padding(null cols(4) rem(35));
    position: relative;
    z-index: 2;

    @include mq($bp768) {
      @include padding(null null rem(50));
    }

    @include mq($bp1024) {
      @include margin(null null null cols(-2, 46));
      @include padding(0);
      flex: 0 0 cols(16, 46);
    }
  }

  .sidebar-cta {
    @include margin(rem(25) null null);

    @include mq($bp768) {
      text-align: right;
    }

    a {
      @include button-cta;
    }
  }

  .cards-list {
    position: relative;
    z-index: 2;
  }

  .article-card {
    @include margin(null null rem(10));
    display: flex;
    justify-content: space-between;
    background: $color-white;
    box-shadow: 0 0 rem(20) 0 rgba($color-black, 0.20);

    @include mq($bp768) {
      @include margin(null null rem(15));
    }

    &:last-child {
      @include margin(null null 0);
    }

    .text-container + .f--image {
      flex: 0 0 cols(7, 20);
      order: 1;

      @include mq($bp768) {
        flex: 0 0 cols(8, 40);
      }

      @include mq($bp1024) {
        flex: 0 0 cols(5, 16);
      }
    }

    .text-container {
      @include padding(rem(20) cols(1, 20));
      flex-grow: 1;
      order: 2;

      @include mq($bp768) {
        @include padding(rem(35) cols(3, 40));
      }

      @include mq($bp1024) {
        @include padding(rem(25) cols(1, 16));
      }
    }

    .f--eyebrow {
      @include margin(null null rem(5));

      @include mq($bp768) {
        @include margin(null null rem(10));
      }
    }

    .f--cta-title {
      h3 {
        @include font-size(16, 26);

        @include mq($bp768) {
          @include font-size(18, 28);
        }
      }
    }
  }
}

.cc--image-gallery {
  @include maxwidth;
  overflow: hidden;

  .c--image-gallery {
    @include padding(rem(25) null);

    @include mq($bp768) {
      @include padding(rem(50) cols(4));
    }

    .title-description {
      @include padding(null cols(4) rem(30));

      @include mq($bp768) {
        @include padding(null 0 rem(25));
      }

      @include mq($bp1024) {
        @include padding(null cols(5, 40) null);
      }

      .f--section-title {
        h2 {
          @include font-size(40, 48);
          color: $color-ludwell-black;

          @include mq($bp768) {
            @include font-size(60, 70);
          }
        }
      }

      .f--description {
        p {
          @include font-size(16, 30);
          letter-spacing: rem(0.2);

          @include mq($bp768) {
            @include font-size(18, 34);
            letter-spacing: rem(0.25);
          }

          &:not(:last-child) {
            @include margin(null null rem(10));
          }
        }
      }
    }

    .mySwiper {
      position: relative;
      overflow: visible;

      .swiper-wrapper {
        overflow: visible;

        .swiper-slide {
          transition: opacity $transition-duration-default $transition-easing-default;
          opacity: 0 !important;

          &.swiper-slide-active {
            opacity: 1 !important;
          }
        }
      }

      .navigation-wrapper {
        @include padding(null cols(4) rem(15));

        @include mq($bp768) {
          @include padding(null 0 null);
        }

        @include mq($bp1024) {
          @include padding(null null 0);
          aspect-ratio: 16 / 9;
          display: flex;
          position: absolute;
          top: 0;
          right: cols(-3);
          align-items: flex-end;
          justify-content: flex-end;
          width: 100%;
        }

        .inner-nav-wrapper {
          display: flex;
          position: static;
          align-items: center;
          justify-content: space-between;

          @include mq($bp1024) {
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            height: calc(50% + #{rem(45)});
          }
        }
  
        .swiper-buttons {
          @include margin(null null null cols(-0.5, 40));
          display: flex;
          position: relative;
          z-index: 1;
          align-items: center;
          justify-content: space-between;
          width: rem(90);
  
          @include mq($bp768) {
            width: rem(97.13);
          }
  
          @include mq($bp1024) {
            @include margin(null null null 0);
            flex-direction: column;
            width: rem(50);
            height: rem(90);
            row-gap: rem(30);
          }
  
          &::before {
            @include centered(x);
            content: "";
            top: rem(-0.5);
            width: rem(1);
            height: calc(100% + #{rem(1)});
            background-color: $color-nicholson-gray;
  
            @include mq($bp1024) {
              top: 50%;
              width: rem(40);
              height: rem(1);
            }
          }
  
          .swiper-button-prev,
          .swiper-button-next {
            display: block;
            position: relative;
            top: 0;
            right: auto;
            left: auto;
            width: rem(30);
            height: rem(30);
            margin: 0;
            padding: 0;
            border: 0;
            background: transparent;
  
            &::after {
              content: "";
              display: block;
            }

            &.swiper-button-disabled {
              opacity: 1;

              svg path {
                fill: $color-nicholson-gray;
              }
            }

            svg path {
              transition: fill $transition-duration-default $transition-easing-default;
              fill: $color-raleigh-red;
            }

            &:hover,
            &:focus-visible {
              svg path {
                fill: $color-randolph-burgundy;
              }
            }
          }
        }
  
        .swiper-pagination {
          display: flex;
          position: relative;
          bottom: auto;
          align-items: center;
          width: auto;
          column-gap: rem(15);
  
          @include mq($bp1024) {
            flex-direction: column;
            align-items: center;
            row-gap: rem(15);
            width: rem(50);
          }
  
          .swiper-pagination-bullet {
            width: rem(5);
            height: rem(5);
            margin: 0;
            opacity: 1;
            background: $color-nicholson-gray;
  
            &.swiper-pagination-bullet-active {
              background: $color-raleigh-red;
            }
          }
        }
      }

      .text-container {
        @include padding(rem(15) cols(4) null);
        position: relative;

        @include mq($bp768) {
          @include padding(null 0 null);
        }

        @include mq($bp1024) {
          @include padding(rem(25) cols(5, 40) null);
        }

        .accent {
          display: none;

          @include mq($bp1024) {
            display: block;
            position: absolute;
            right: calc(100% - #{cols(3, 40)});
            bottom: 0;
            width: rem(1);
            height: calc(100% + #{rem(25)});
            background-color: $color-york-yellow;
          }

          &::before,
          &::after {
            content: "";
            position: absolute;
            right: calc(100% - #{cols(3, 40)} - #{rem(4.5)});
            width: rem(8);
            height: rem(1);
            background-color: $color-york-yellow;
          }

          &::before {
            top: rem(0);
          }

          &::after {
            bottom: rem(0);
          }
        }

        .f--cta-title {
          @include margin(null null rem(5));

          h3 {
            @extend %font-source-serif-regular;
            @include font-size(30, 40);
            color: $color-ludwell-black;

            @include mq($bp1024) {
              @include font-size(36, 46);
            }
          }
        }

        .f--sub-title {
          @include margin(null null rem(10));

          h3 {
            color: $color-ludwell-black;
          }
        }

        .f--description {
          p {
            @include font-size(15, 25);

            @include mq($bp768) {
              @include font-size(16, 28);
              letter-spacing: rem(0.25);
            }

            a {
              color: $color-raleigh-red;
              font-weight: inherit;
              text-decoration: underline;

              @include hover {
                color: $color-ludwell-black;
                text-decoration: none;
              }
            }
          }
        }

        .f--link {
          @include margin(rem(15) null null);

          a {
            @include button($outline: true);
          }
        }
      }
    }
  }
}

.tc--special-offer {
  .content-main {
    @include padding(null null rem(50));

    @include mq($bp768) {
      @include padding(null null rem(100));
    }

    .cc--component-container {
      &:not(:last-child) {
        @include padding(0 null rem(35));

        @include mq($bp768) {
          @include padding(0 null rem(50));
        }
      }

      &:first-child {
        @include padding(rem(35) null null);

        @include mq($bp768) {
          @include padding(rem(50) null null);
        }
      }

      .c--component {
        @include padding(0 null);

        &.c--interactive-content-pane {
          .inner-wrapper {
            @include padding(0 null);
          }
        }

        &.c--related-content {
          .inner-wrapper {
            @include padding(0 null);
          }

          .sidebar-content {
            @include padding(null null 0);
          }
        }
      }
    }
  }
}

.cc--featured-events-carousel {
  overflow-x: hidden;

  &.white {


    @include mq($bp768max) {
      background: linear-gradient(to bottom, rgba($color-white, 1) 0%, rgba($color-white, 1) 50%, rgba($color-black, 1) 50%, rgba($color-black, 1) 100%);
    }

    @include mq($bp1024) {
      background: linear-gradient(to bottom, rgba($color-white, 1) 0%, rgba($color-white, 1) rem(75), rgba($color-black, 1) rem(75), rgba($color-black, 1) rem(525), rgba($color-white, 1) rem(525), rgba($color-white, 1) 100%);
    }
  }

  .c--featured-events-carousel {
    @include maxwidth;
    position: relative;

    .inner-wrapper {
      @include padding(rem(35) null rem(35) null);
      position: relative;

      @include mq($bp768) {
        @include padding(rem(70) null rem(70) null);
      }

      @include mq($bp1024) {
        @include padding(rem(75) null rem(75) null);
      }

      > .events-link {
        @include margin(rem(15) null null null);
        text-align: center;

        @include mq($bp768) {
          display: none;
        }

        a {
          @include button(
            $outline: true,
            $text-color: $color-white,
            $outline-color: $color-york-yellow,
            $focus-color: $color-focus-light
          );
        }
      }
    }

    .controls-overlay {
      @include component-box-shadow;
      position: relative;
      z-index: 5;
      height: rem(230);
      overflow: hidden;
      background-color: $color-white;

      @include mq($bp768max) {
        @include margin(null cols(4) rem(-15) cols(4));
      }

      @include mq($bp768) {
        position: absolute;
        top: rem(35);
        left: 0;
        width: rem(335);
        height: calc(100% - #{rem(70)});
      }

      @include mq($bp1024) {
        top: rem(40);
        left: calc(50% - #{rem(150)});
        width: rem(350);
        height: calc(100% - #{rem(80)});
        -webkit-transform: translateX(calc(50% - #{rem(175)}));
        transform: translateX(calc(-50% - #{rem(175)}));
      }

      .controls-overlay-inner {
        @include padding(rem(40) rem(25));
        display: flex;
        position: relative;
        z-index: 3;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;

        @include mq($bp768) {
          @include padding(rem(50) rem(25) rem(50) rem(40));
        }

        @include mq($bp1024) {
          @include padding(rem(50) rem(25));
        }
      }

      .today {
        @extend %font-source-serif-regular-italic;
        @include margin(null null rem(15) null);
        @include font-size(22, 26);
        color: $color-ludwell-black;
        letter-spacing: rem(8.5);
        text-transform: uppercase;

        @include mq($bp768) {
          @include font-size(26, 30);
          letter-spacing: rem(10);
        }
      }

      .date {
        @extend %font-source-serif-regular;
        @include margin(null null rem(15) null);
        @include padding(null rem(20) rem(3) rem(20));
        @include font-size(18, 25);
        border-bottom: rem(1) dotted $color-nicholson-gray;
        color: $color-ludwell-black;
        text-align: center;

        @include mq($bp768) {
          @include font-size(22, 32);
        }
      }

      .subhead {
        @extend %font-source-sans-regular;
        @include font-size(12, 14);
        color: $color-ludwell-black;
        letter-spacing: rem(1.5);
        text-transform: uppercase;

        @include mq($bp768) {
          @include font-size(14, 18);
          letter-spacing: rem(1.75);
        }
      }

      .swiper-buttons {
        @include margin(rem(20) null null null);
        display: flex;
        z-index: 10;
        justify-content: center;

        @include mq($bp768) {
          @include centered('y');
          @include margin(auto null null null);
        }

        .swiper-button-prev {
          @include margin(null rem(30) null null);
          position: relative;

          &::after {
            position: absolute;
            top: rem(-5);
            right: rem(-15);
            width: rem(1);
            height: calc(100% + #{rem(10)});
            background-color: $color-nicholson-gray;
          }
        }

        .swiper-button-prev,
        .swiper-button-next {
          display: block;
          position: relative;
          top: 0;
          right: auto;
          left: auto;
          width: rem(30);
          height: rem(30);
          margin-top: 0;
          padding: 0;
          border: 0;
          background: transparent;

          &::after {
            content: "";
            display: block;
          }

          &.swiper-button-disabled {
            opacity: 1;

            svg path {
              fill: $color-nicholson-gray;
            }
          }

          svg path {
            transition: fill $transition-duration-default $transition-easing-default;
            fill: $color-raleigh-red;
          }

          &:hover,
          &:focus-visible {
            svg path {
              fill: $color-randolph-burgundy;
            }
          }
        }
      }

      .f--link {
        margin-top: auto;

        @include mq($bp768max) {
          display: none;
        }

        a {
          @include button(
            $outline: true,
            $hover-bg-color: $color-randolph-burgundy
          );
        }
      }

      .f--image.svg-woodcut {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: rem(-45);
        width: rem(200);
        height: rem(200);

        @include mq($bp768) {
          bottom: 0;
          left: rem(-75);
          width: rem(300);
          height: rem(300);
        }
      }
    }

    .cards-container {
      display: flex;

      @include mq($bp768-1024) {
        position: relative;
        left: rem(335);
      }

      @include mq($bp1800) {
        &::after {
          content: '';
          position: absolute;
          z-index: 8;
          top: 0;
          right: rem(-1);
          width: rem(100);
          height: 100%;
          background: linear-gradient(to left, rgba($color-black, 1) 0, transparent 100%);
        }

        &::before {
          content: '';
          position: absolute;
          z-index: 8;
          top: 0;
          left: rem(-1);
          width: rem(100);
          height: 100%;
          background: linear-gradient(to right, rgba($color-black, 1) 0, transparent 100%);
        }
      }

      .card {
        position: relative;
        width: rem(270);

        @include mq($bp768) {
          width: rem(320);
        }

        @include mq($bp1024) {
          width: rem(300);
        }

        .text-container {
          @include padding(rem(35) rem(30) rem(25) rem(30));
          display: flex;
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          flex-direction: column;
          width: 100%;
          height: 100%;
          color: $color-white;
          text-align: center;

          @include mq($bp768) {
            @include padding(rem(10) rem(25) rem(25) rem(25));
          }

          .start-time {
            @extend %font-source-sans-semibold;
            @include padding(null rem(13) rem(4) rem(13));
            @include font-size(14, 16);
            align-self: center;
            border-bottom: rem(1) solid $color-york-yellow;
            letter-spacing: rem(1.75);

            @include mq($bp768) {
              @include padding(null rem(10) rem(10) rem(10));
              @include font-size(16, 16);
              letter-spacing: rem(2);
            }

            & + .title {
              margin-top: auto;
            }
          }

          .eyebrow {
            @extend %font-source-sans-regular;
            @include font-size(12, 14);
            margin-top: auto;
            letter-spacing: rem(1.5);
            text-transform: uppercase;

            @include mq($bp768) {
              @include font-size(14, 18);
              letter-spacing: rem(1.75);
            }
          }

          .title {
            @extend %font-source-serif-regular;
            @include margin(rem(5) null null null);
            @include font-size(18, 26);

            @include mq($bp768) {
              @include margin(rem(10) null null null);
              @include font-size(22, 32);
            }

            a {
              @extend %font-source-serif-regular;
              color: inherit;
              font-size: inherit;
              line-height: inherit;
              text-decoration: none;

              @include hover {
                text-decoration: underline;
              }

              &:focus {
                @include outline($color-focus-light);
              }

            }
          }
        }

        .f--image {
          position: relative;
          height: rem(405);

          @include mq($bp768) {
            height: rem(480);
          }

          @include mq($bp1024) {
            height: rem(450);
          }

          &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 37%;
            opacity: 0.85;
            background: linear-gradient(to bottom, $color-black 0%, transparent 100%);

            @include mq($bp768) {
              height: 25%;
            }
          }

          &::after {
            content: '';
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 60%;
            opacity: 0.85;
            background: linear-gradient(to top, $color-black 0%, transparent 100%);
          }

          img {
            height: 100%;
            object-fit: cover;
            aspect-ratio: 3 / 2;
          }
        }
      }
    }
  }

  // dark background
  &.black {
      background: linear-gradient(to bottom, rgba($color-black, 1) 0%, rgba($color-black, 1) rem(805), rgba($color-white, 1) rem(805), rgba($color-white, 1) 100%);

    @include mq($bp768) {
      background: linear-gradient(to bottom, rgba($color-black, 1) 0%, rgba($color-black, 1) rem(525), rgba($color-white, 1) rem(525), rgba($color-white, 1) 100%);
    }
  }
}

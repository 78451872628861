//  Placeholder Mixin
//
//   No Params
//
//   Example Usage:
//
//     input[placeholder] {
//       @include placeholder {
//         color: red;
//       }
//     }
//
//
@mixin placeholder {

  &::-webkit-input-placeholder {
    opacity: 1;
    @content;
  }

  &::-moz-placeholder {
    opacity: 1;
    @content;
  }

  &:-ms-input-placeholder {
    opacity: 1;
    @content;
  }

  &::placeholder {
    opacity: 1;
    @content;
  }

}

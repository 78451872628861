.cc--cta-cards-collection {
  @include maxwidth;

  .c--cta-cards-collection {
    @include padding(null cols(4));
    @include component-padding;

    @include mq($bp768) {
      @include padding(null cols(4));
    }

    @include mq($bp1024) {
      @include padding(null cols(5));
    }

    .f--section-title {
      text-align: center;
    }

    .cards-container {
      @include margin(rem(15) null null null);

      @include mq($bp768) {
        @include margin(rem(25) null null null);
        display: flex;
        flex-wrap: wrap;
        column-gap: cols(2, 40);
        row-gap: rem(25);
      }

      @include mq($bp1024) {
        @include margin(rem(25) null null null);
        display: flex;
        flex-wrap: wrap;
        column-gap: cols(1, 38);
        row-gap: rem(25);
      }

      .card {
        @include padding(null cols(4, 40));
        position: relative;
        height: rem(162);
        overflow: hidden;
        background-color: $color-franklin-white;

        &:not(:last-child) {
          @include mq($bp768max) {
            @include margin(null null rem(15) null);
          }
        }

        @include mq($bp768) {
          @include padding(null cols(2, 40));
          flex: 0 0 cols(19, 40);
          height: rem(225);
        }

        @include mq($bp1024) {
          @include padding(null cols(1, 38));
          flex: 0 0 cols(12, 38);
          height: rem(225);
        }

        .text-container {
          display: flex;
          position: relative;
          z-index: 1;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          text-align: center;
        }

        .f--sub-title {
          text-align: center;
        }

        .description-link-container {
          @include padding(null null rem(2) null);

          .f--description {
            @include margin(rem(5) null null null);

            p {
              @include font-size(14, 22);
            }
          }

          .f--link {
            @include margin(rem(15) null null null);

            @include mq($bp768) {
              @include margin(rem(10) null null null);
            }

            a {
              @include button-cta();
            }
          }

          @include mq($bp1024) {
            max-height: 0;
            overflow: hidden;
            transition: max-height $transition-duration-slow $transition-easing-default, opacity $transition-duration-slow $transition-easing-default;
            opacity: 0;
          }
        }

        .f--image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        @include mq($bp1024) {

          html[data-whatinput='touch'] &,
          &:focus-within {
            background-color: $color-york-yellow;

            .text-container {
              .description-link-container {
                max-height: rem(300);
                transition: max-height $transition-duration-slow $transition-easing-default, opacity $transition-duration-slow $transition-easing-default;
                opacity: 1;
              }
            }
          }

          @include hover {
            background-color: $color-york-yellow;

            .text-container {
              .description-link-container {
                max-height: rem(300);
                transition: max-height $transition-duration-slow $transition-easing-default, opacity $transition-duration-slow $transition-easing-default;
                opacity: 1;
              }
            }
          }
        }

        &.has-image {
          background-color: $color-ludwell-black;
          color: $color-white;

          .description-link-container {

            .f--description {
              p {
                a {
                  color: $color-white;
                }
              }
            }

            .f--link {
              a {
                @include button-cta(
                  $text-color: $color-white,
                  $hover-color: $color-york-yellow,
                  $outline-color: $color-york-yellow,
                  $svg-color: $color-york-yellow
                );
              }
            }
          }

          .f--image {
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0.43;
              background-color: $color-black;
            }
          }
        }
      }
    }
  }
}

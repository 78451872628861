.f--input-number {
  .input-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: rem(90);

    button {
      appearance: none;
      width: rem(25);
      height: rem(25);
      border: 0;
      background-color: $color-white;
      background-position: center center;
      background-size: rem(19) rem(19);
      cursor: pointer;

      &.counter-increment {
        background-image: url('../images/icons/icon-plus.svg');
      }

      &.counter-decrement {
        background-image: url('../images/icons/icon-minus.svg');
      }
    }

    input[type='number'] {
      @extend %font-source-sans-semibold;
      @include font-size(16, 28);
      @include padding(0);
      -moz-appearance: textfield;
      width: rem(20);
      border: 0;
      background-color: transparent;
      letter-spacing: rem(0.25);
      text-align: center;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      @include placeholder {
        color: $color-ludwell-black;
      }
    }
  }
}

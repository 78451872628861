.cc--map-filter {
  position: relative;
  width: 100%;

  @include mq($bp768) {
    box-shadow: 0 0 rem(20) 0 rgba($color-black, 0.2);
  }

  .c--map-filter {
    @include maxwidth;

    .inner-wrapper {
      @include padding(rem(15) cols(4) rem(25));

      @include mq($bp1024) {
        @include padding(rem(25) cols(3) rem(40));
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: cols(1, 42);
      }
    }

    .filter-title {
      @extend %font-source-serif-semibold;
      @include font-size(18, 26);
      @include margin(null null rem(15));

      @include mq($bp768) {
        @include font-size(22, 32);
      }

      @include mq($bp1024) {
        @include margin(null null 0);
        @include padding(rem(18) null null);
        flex: 0 0 cols(3, 42);
      }
    }

    .search-form-content {
      @include mq($bp1024) {
        flex: 0 0 cols(38, 42);
      }

      .fields-container {
        @include mq($bp768) {
          display: flex;
          flex-wrap: wrap;
          column-gap: cols(2, 40);
        }

        @include mq($bp1024) {
          column-gap: rem(10);
        }

        > div {
          &:not(:nth-last-child(-n + 2)) {
            @include mq($bp768-1024) {
              @include margin(null null rem(20));
            }
          }
        }

        .cc--multiselect-dropdown {
          @include mq($bp768max) {
            @include margin(null null rem(10));
          }

          @include mq($bp768) {
            flex: 0 0 cols(19, 40);
          }

          @include mq($bp1024) {
            flex: 0 0 calc(#{cols(10, 38)} - #{rem(5)});
            width: calc(#{cols(10, 38)} - #{rem(5)});
          }

          .filter-label {
            @include mq($bp768) {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .c--multiselect-dropdown {
            .select-container .filter-option {
              background-color: $color-franklin-white;

              span {
                @extend %font-source-sans-bold;
                color: $color-randolph-burgundy;
              }
            }
          }
        }

        > .f--input-checkbox {
          display: flex;
          align-items: center;

          @include mq($bp768max) {
            @include margin(rem(15) null null);
          }

          @include mq($bp768) {
            @include margin(rem(23) null null);
            flex: 0 0 cols(19, 40);
          }

          @include mq($bp1024) {
            @include margin(0 null null calc(#{cols(1, 38)} - #{rem(5)}));
            @include padding(rem(22) null null cols(1, 38));
            flex: 0 0 calc(#{cols(7, 38)} - #{rem(10)});
            border-left: 1px solid $color-nicholson-gray;
          }

          input {
            &:not(:checked) {
              background-color: $color-franklin-white;

              &::after {
                background: none;
              }
            }
          }
        }
      }
    }

    .filter-button {
      display: none;
    }

    .results-header {
      @include padding(rem(15) null null);
      @include margin(rem(15) null null);
      display: none;
      flex: 0 0 100%;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid $color-nicholson-gray;

      @include mq($bp768) {
        @include padding(rem(25) null null);
        @include margin(rem(25) null null);
      }

      .results-count {
        @extend %font-source-sans-bold;
        @include font-size(14, 22);
      }

      button {
        @extend %font-source-sans-regular;
        @include font-size(14, 22);
        @include padding(0);
        border: 0;
        border-radius: 0;
        background: none;
        color: $color-black;
        text-decoration: underline;

        @include hover {
          text-decoration: none;
          cursor: pointer;
        }

        &:disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }
    }
  }
}

.cc--feature-image-collage {
  @include padding(rem(35) cols(4) null);
  @include maxwidth;
  position: relative;
  z-index: 2;

  @include mq($bp768) {
    @include padding(rem(50) 0 null);
  }

  @include mq($bp1024) {
    @include margin(rem(-75) null null);
    @include padding(0 cols(4) rem(50));
  }

  .c--feature-image-collage {
    .inner-wrapper {
      @include mq($bp1024) {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        column-gap: cols(1, 40);
      }
    }

    .images-container {
      position: relative;

      @include mq($bp1024) {
        flex: 0 0 cols(23, 40);
      }

      .f--image {
        &.large-image {
          @include padding(null null null cols(8, 40));

          @include mq($bp1024) {
            @include padding(null null null cols(1, 23));
          }
        }

        &.small-image {
          position: absolute;
          bottom: rem(-25);
          left: 0;
          width: cols(16, 40);

          @include mq($bp768) {
            width: cols(14, 40);
          }

          @include mq($bp1024) {
            bottom: rem(-75);
            width: cols(8, 23);
          }
        }
      }

      .accent {
        @include centered(x);
        bottom: rem(-40);
        width: rem(1);
        height: rem(65);
        background-color: $color-york-yellow;

        @include mq($bp768) {
          bottom: rem(-50);
          left: cols(24, 40);
          height: rem(90);
        }

        @include mq($bp1024) {
          top: rem(125);
          bottom: auto;
          left: cols(-17, 23);
          width: cols(20, 23);
          height: rem(25);
          transform: translateX(0);
          border-top: rem(1) solid $color-york-yellow;
          border-left: rem(1) solid $color-york-yellow;
          background-color: transparent;
        }

        &::before {
          @include centered(x);
          content: '';
          bottom: 0;
          width: rem(9);
          height: 100%;
          border-top: rem(1) solid $color-york-yellow;
          border-bottom: rem(1) solid $color-york-yellow;

          @include mq($bp1024) {
            left: rem(-4.5);
            transform: translatex(0);
            border-top: 0;
          }
        }

        &::after {
          @include mq($bp1024) {
            content: '';
            position: absolute;
            top: rem(-4.5);
            right: 0;
            width: rem(1);
            height: rem(9);
            background-color: $color-york-yellow;
          }
        }
      }
    }

    .text-container {
      @include margin(rem(55) null null);

      @include mq($bp768) {
        @include margin(rem(75) null null);
      }

      @include mq($bp1024) {
        @include margin(0 null null);
        @include padding(rem(175) null null);
        flex: 0 0 cols(16, 40);
      }

      .f--section-title {
        h2 {
          @extend %font-source-serif-regular-italic;
          @include font-size(22, 26);
          letter-spacing: rem(8.5);
          text-transform: uppercase;

          @include mq($bp768) {
            @include font-size(26, 30);
            letter-spacing: rem(10);
          }
        }
      }

      .f--description {
        @include margin(rem(5) null null);

        p {
          @include font-size(16, 30);
          letter-spacing: rem(0.2);

          @include mq($bp768) {
            @include font-size(18, 34);
            letter-spacing: rem(0.25);
          }

          &:not(:last-child) {
            @include margin(null null rem(10));
          }
        }
      }

      .f--link {
        @include margin(rem(15) null null);
        display: inline-block;
        position: relative;

        @include mq($bp768) {
          @include margin(rem(20) null null);
        }

        &::before {
          @include mq($bp1024) {
            @include triangle(right, $color-raleigh-red, 8px);
            @include centered(y);
            left: calc(100% + #{rem(25)});
          }
        }

        a {
          @include button(
            $outline: true,
            $hover-bg-color: $color-randolph-burgundy
          );
        }
      }
    }
  }
}

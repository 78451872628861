.cc--chapter {
  .c--chapter {
    .chaptered-nav-anchor {
      @include margin(rem(-15) null null);
      @include padding(rem(15) null null);
      display: block;

      @include mq($bp768) {
        @include margin(rem(-25) null null);
        @include padding(rem(25) null null);
      }

      @include mq($bp1024) {
        @include margin(rem(-95) null null);
        @include padding(rem(95) null null);
      }
    }

    > .f--section-title {
      @include margin(null cols(4) null);
      @include padding(null rem(15) null null);
      position: relative;

      @include mq($bp768) {
        @include padding(null rem(25) null null);
      }

      @include mq($bp1024) {
        @include margin(null cols(3) null);
      }

      &::after {
        content: "";
        position: absolute;
        top: rem(15);
        right: 0;
        width: 100%;
        height: rem(1);
        background-color: $color-york-yellow;
      }

      &::before {
        content: "";
        position: absolute;
        top: rem(11.5);
        right: rem(-0.5);
        width: rem(1);
        height: rem(8);
        background-color: $color-york-yellow;
      }

      h2 {
        @extend %font-source-serif-regular-italic;
        @include font-size(22, 26);
        @include padding(null rem(15) null null);
        display: inline-block;
        position: relative;
        z-index: 1;
        background-color: $color-white;
        color: $color-ludwell-black;
        letter-spacing: rem(8.5);
        text-transform: uppercase;
  
        @include mq($bp768) {
          @include font-size(26, 30);
          @include padding(null rem(25) null null);
          letter-spacing: rem(10);
        }
      }
    }

    .cc--rich-text {
      @include margin(0 null);
    }
  }
}

.lity {
  background: rgba($color-black, 0.9);

  .lity-wrap {
    @include padding(rem(75) null rem(75));
    overflow: auto;

    @include mq($bp768) {
      @include padding(null cols(4));
    }

    .lity-container {
      position: relative;
      width: 100%;
      max-width: $max-width;

      .lity-close {
        top: rem(25);
        right: rem(25);

        &:focus {
          outline: rem(3) solid $color-focus;
        }
      }
    }
  }

  &.lity-youtube,
  &.lity-vimeo {
    .lity-content {
      @include margin(null auto);
    }
  }
}

.lity-active body {
  height: 100vw;
  overflow: hidden;
}

.cc--accordions {
  @include maxwidth;
  @include margin(rem(35) null);

  @include mq($bp768) {
    @include margin(rem(50) null);
  }

  .c--accordions {
    @include padding(null cols(4));

    @include mq($bp1024) {
      @include padding(null cols(9));
    }

    .header-container {
      .f--description {
        p {
          @include mq($bp768) {
            @include font-size(18, 34);
          }
        }
      }
    }

    .global-cta {
      @include margin(rem(15) null null);

      @include mq($bp768) {
        @include margin(rem(20) null null);
      }

      a {
        @include button($outline: true);
      }
    }

    > ul {
      @include no-bullet;
      @include margin(rem(25) null null);

      @include mq($bp768) {
        @include margin(rem(35) null null);
      }

      > li {
        @include margin(null null rem(15));

        @include mq($bp768) {
          @include margin(null null rem(25));
        }

        a.accordion-trigger {
          @include padding(null cols(4, 20) rem(13) 0);
          display: block;
          position: relative;
          width: 100%;
          border: 0;
          border-bottom: rem(1) solid $color-nicholson-gray;
          border-radius: 0;
          background: none;
          color: $color-black;
          text-align: left;
          cursor: pointer;

          @include mq($bp768) {
            @include padding(null cols(4, 40) rem(20) 0);
          }

          @include mq($bp1024) {
            @include padding(null cols(2, 32) null null);
          }

          .item-title {
            @extend %font-source-serif-regular;
            @include font-size(22, 32);

            @include mq($bp768) {
              @include font-size(26, 36);
            }
          }

          .offset {
            content: '';
            display: block;
            position: absolute;
            top: rem(-75);
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: rem(5);
            right: 0;
            width: rem(25);
            height: rem(25);
            background-image: url('../images/icons/icon-arrow-down.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;


            @include mq($bp768) {
              width: rem(30);
              height: rem(30);
            }
          }

          &.open {
            @include padding(null null 0 null);
            border-bottom: 0;

            &::after {
              background-image: url('../images/icons/icon-arrow-up.svg');
            }
          }
        }

        .f--wysiwyg {
          p {
            &:last-child {
              @include margin(null null 0);
            }
          }
        }
      }
    }
  }

  .accordion-panel {
    display: none;

    .cc--rich-text {
      @include margin(0);
      @include padding(rem(10) 0 rem(25));
      border-bottom: rem(1) solid $color-nicholson-gray;

      .c--rich-text {
        @include padding(0);

        @include mq($bp768) {
          @include padding(rem(15) 0 0);
        }

        .inner-wrapper {
          @include padding(0);
        }

        iframe,
        .video-embed,
        img,
        .align-center {
          @include margin(null auto);
          max-width: 100%;
        }

        .align-center {
          img {
            width: 100%;
            height: auto;
          }
        }

        .align-left {
          @include margin(null null null 0);
          img {
            height: auto;
          }
        }

        .align-right {
          @include margin(null 0 null null);
          img {
            height: auto;
          }
        }

        ul {
          list-style-type: disc;

          ul {
            list-style-type: circle;

            ul {
              list-style-type: square;
            }
          }

          ol {
            ul {
              list-style-type: square;
            }
          }
        }

        ol {
          ul {
            list-style-type: circle;

            ul {
              list-style-type: square;
            }
          }
        }
      }
    }
  }
}

.cc--rich-text {
  + .cc--accordions {
    .c--accordions {
      @include padding(0 null null);
    }
  }
}

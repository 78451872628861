// style the tippy tooltip indicator
[data-tippy] {
  @include padding(0);
  @include margin(0);
  border: 0;
  background: transparent;
  color: $color-raleigh-red;
  vertical-align: super;
  cursor: pointer;
}

// style the tippy tooltip box
[data-tippy-root] {
  @include component-box-shadow;
  background-color: $color-white;

  .tippy-box {
    background-color: $color-white;

    .tippy-content {
      @extend %font-source-sans-regular;
      @include padding(rem(20) rem(25));
      @include font-size(14, 22);
      background-color: $color-white;
      color: $color-ludwell-black;
      letter-spacing: normal;
      text-transform: none;
    }
  }
}

.cc--map {
  .c--map {
    @include mq($bp768max) {
      display: flex;
      flex-direction: column-reverse;
    }

    .map-wrapper {
      position: relative;
    }

    .direction {
      @include mq($bp768) {
        ~ .location-placeholder {
          display: none;
        }
      }
    }

    .location-placeholder {
      @extend %font-source-sans-regular;
      @include padding(rem(18) rem(25));
      @include font-size(18, 28);
      position: absolute;
      top: rem(100);
      left: rem(50);
      width: rem(350);
      height: rem(64);
      border-bottom-right-radius: rem(5);
      border-bottom-left-radius: rem(5);
      background-color: $color-white;

      @include mq($bp1800) {
        left: calc(calc(100vw - 1800px) / 2) !important;
      }

      @include mq($bp768max) {
        @include padding(rem(11) null);
        position: relative;
        top: 0;
        left: 0;
        width: cols(40);
        height: rem(50);
        margin: 0 auto;

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: rem(5);
          box-shadow: 0 0 rem(20) 0 rgba($color-black, 0.2);
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: rem(25);
        left: rem(25);
        height: 1px;
        background-color: $color-nicholson-gray;
      }
    }

    .cc--card {
      position: relative;
      width: rem(350);

      @include mq($bp768) {
        height: rem(525);
      }

      @include mq($bp768max) {
        @include margin(null auto);
        width: cols(40);
      }

      .f--image {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: $color-randolph-burgundy
            url('../images/placeholders/placeholder_2x3.png') no-repeat center
            center / cover;

          + .image-sm,
          + .placeholder {
            position: absolute;
            z-index: -2;
            top: 0;
            left: 0;
          }

          &.image-sm {
            z-index: -1;
          }
        }
      }

      .card {
        &--close {
          position: absolute;
          z-index: 1000001;
          right: rem(4);
          bottom: 100%;
          width: rem(46);
          height: rem(46);
          transform: translateY(-2px);
          border: 0;
          background: $color-white url('../images/icons/icon-close.svg')
            no-repeat center;
          box-shadow: none;
          appearance: none;
          cursor: pointer;
        }

        .tabpanel {
          .card-description {
            .links-container {
              @include padding(0);
            }
          }
        }

        .tabs.first {
          background-image: url('../images/interface/line-with-inset-arrows-yellow-sm.svg');
        }
      }
    }

    .error {
      h2 {
        color: $color-raleigh-red;
      }
    }

    #map {
      @include mq($bp768max) {
        height: rem(360) !important;
      }
    }

    #info-window {
      @include mq($bp1800) {
        left: calc(calc(100vw - 1800px) / 2) !important;
      }

      @include mq($bp768max) {
        position: static !important;

        &[style*='block'] {
          + .location-placeholder {
            display: none;
          }
        }
      }
    }

    .gm-bundled-control-on-bottom {
      right: rem(80) !important;

      @include mq($bp768max) {
        right: rem(60) !important;
        bottom: rem(140) !important;
      }
    }

    div:has(> .gm-style-cc),
    div:has(a > div > img) {
      @include mq($bp768max) {
        top: 0 !important;
        bottom: auto !important;
      }
    }

    .gm-style {
      .gm-style-iw-c {
        max-height: none !important;
        padding: 0;
      }

      .gm-style-iw-tc,
      &-iw-chr {
        display: none;
      }

      .gm-style-iw-c {
        border-radius: 0;
      }

      .gm-style-iw-d {
        display: none !important;
      }

      &-mtc-bbw {
        @include padding(rem(10) rem(10));
        width: rem(350);
        height: rem(114);
        margin: rem(50) 0 0 rem(50) !important;
        border-radius: rem(5);
        background: $color-white;
        box-shadow: 0 0 rem(20) 0 rgba($color-black, 0.2);
        font-size: rem(14) !important;
        line-height: 1.57;

        @include mq($bp1800) {
          left: calc(calc(100vw - 1800px) / 2) !important;
          margin-left: 0 !important;
        }

        @include mq($bp768max) {
          top: 100% !important;
          right: 0 !important;
          left: 0 !important;
          width: cols(40);
          height: rem(50);
          margin: 0 auto !important;
          transform: translateY(-100%);
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }

        ~ .direction {
          z-index: 1000001;
        }

        .modal-opened & {
          border-bottom-right-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }

        button {
          height: auto !important;
          padding: rem(5) rem(15) rem(4) !important;
          border: 0 !important;
          background: none !important;
          box-shadow: none !important;
          font-family: "Source Sans 3", sans-serif !important;
          font-size: rem(14) !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: 1.57 !important;

          &[aria-checked="true"] {
            font-weight: 700 !important;
          }
        }

        ul {
          display: none !important;
        }

        .gm-style-mtc {
          align-self: flex-start;

          &:first-of-type {
            border-right: 1px solid $color-nicholson-gray;
          }
        }
      }
    }

    #map {
      position: relative;

      .loader {
        @include centered;
        position: absolute;
        z-index: 2;
        width: rem(90);
        height: rem(90);

        &__wrapper {
          height: 100%;

          .error {
            @include centered;
            z-index: 2;
            text-align: center;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($color-black, 0.1);
          }
        }
      }

      .direction {
        @include margin(rem(50) null null rem(-50));
        @include padding(rem(10) rem(25));
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(50);
        transform: translateX(-100%);

        @include hover {
          cursor: pointer;
        }

        @include mq($bp1800) {
          left: calc(calc(100vw - 1800px) / 2) !important;
          margin-left: rem(300) !important;
        }

        @include mq($bp768max) {
          @include margin(0 0 0 rem(-40));
          top: auto !important;
          bottom: 0;
          left: cols(44) !important;
          width: rem(70);
        }
      }

      .marker-content {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: rem(10);
          left: 50%;
          width: rem(10);
          height: rem(10);
          margin-left: rem(-5);
          border-radius: 50%;
          background: $color-white;
          pointer-events: none;
        }

        &__active {
          z-index: 2 !important;

          .custom-marker path {
            fill: $color-york-yellow;
          }
        }

        .custom-marker {
          @include hover {
            + span {
              display: block;
            }
          }
        }

        span {
          @extend %font-source-sans-semibold;
          @include margin(rem(40) auto null);
          @include centered('x');
          display: none;
          position: absolute;
          top: 0;
          width: rem(140);
          color: $color-white;
          text-align: center;
          text-shadow: -1px -1px 0 $color-ludwell-black,
            1px -1px 0 $color-ludwell-black, -1px 1px 0 $color-ludwell-black,
            1px 1px 0 $color-ludwell-black;
        }
      }
    }
  }

  &.modal-opened {
    .c--map {
      .gm-style-mtc-bbw {
        height: rem(50);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .preloader-1,
  .preloader-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    border: none;
    opacity: 0;
    color: transparent;
  }
}

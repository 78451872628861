.no_display {
  display: none;
}

.b2c {
  @include padding(rem(35) cols(4) 0);
  text-align: center;

  @include mq($bp768) {
    @include padding(rem(75) null null);
  }

  @include mq($bp1024) {
    @include padding(null cols(14));
  }

  .b2c {
    @include padding(0);
  }

  img {
    @include maxwidth(rem(350));
    width: auto;
  }

  .headline-1 {
    @extend %font-source-sans-regular;
    @include font-size(18, 24);
    @include margin(null null rem(15));
    letter-spacing: rem(4);
    text-transform: uppercase;
  }

  .intro {
    @include margin(null null rem(10));

    h2 {
      @extend %font-source-serif-regular;
      @include font-size(22, 32);
      @include margin(null null rem(25));

      @include mq($bp768) {
        @include font-size(28, 36);
      }
    }
  }

  .attr {
    ul {
      @include no-bullet;
    }

    .error,
    .helpLink {
      display: none;
    }
  }

  .TextBox {
    display: flex;
    flex-direction: column;

    .attrEntry {
      @include margin(null null rem(25));

      label {
        @extend %font-source-sans-regular;
        @include font-size(12, 14);
        @include margin(null null rem(5));
        display: block;
        letter-spacing: rem(1.5);
        text-align: left;
        text-transform: uppercase;

        @include mq($bp768) {
          @include font-size(14, 18);
        }
      }

      input {
        @extend %font-source-sans-regular;
        @include padding(null rem(40) null rem(15));
        @include font-size(14, 20);
        position: relative;
        width: 100%;
        height: rem(50);
        border: 0;
        background-color: $color-franklin-white;
        color: $color-ludwell-black;
        appearance: none;

        @include mq($bp1024) {
          @include font-size(16, 24);
        }

        &:focus-visible {
          outline: rem(3) solid $color-focus;
        }

        @include placeholder {
          @include font-size(14, 22);
          color: $color-ludwell-black;
        }
      }

      .error {
        display: none;
      }
    }

    .verificationInfoText {
      display: none !important;
    }
  }

  .buttons {
    @include margin(rem(25) null null);
    display: flex;

    button {
      @include button(
        $btn-height-desktop: rem(50),
        $btn-height-tablet: rem(50)
      );
      align-items: center;
      width: 100%;
      border: 0;
      cursor: pointer;

      &:nth-child(2) {
        @include button(
          $outline: true,
          $btn-height-desktop: rem(50),
          $btn-height-tablet: rem(50)
        );
        @include margin(null null null rem(10));
        background-color: $color-white;

        @include hover {
          background-color: $color-white;
        }
      }
    }
  }

  img:last-of-type,
  .divider {
    display: none;
  }
}

.t5 {
  @include padding(0 cols(4));
  text-align: center;

  @include mq($bp1024) {
    @include padding(null cols(14));
  }

  .t5 {
    @include padding(0);
  }

  p {
    @extend %font-source-sans-regular;
    @include font-size(16, 28);
    @include margin(null null rem(10));

    a {
      color: $color-raleigh-red;

      @include hover {
        text-decoration: none;
      }
    }
  }
}

.b2c-api-container {
  @include margin(rem(35) null null);
  @include padding(null null rem(35));

  @include mq($bp768) {
    @include padding(null null rem(100));
  }
}

.cc--hero-special-offer {
  position: relative;

  .c--hero-special-offer {
    @include mq($bp768) {
      @include padding(null null rem(25));
    }

    @include mq($bp1024) {
      @include padding(null null 0);
    }

    .image-container {
      position: relative;

      @include mq($bp1024) {
        display: flex;
      }

      .f--image {
        position: relative;
        height: 100%;

        @include mq($bp1024) {
          width: 100%;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: 5;
          bottom: rem(-1);
          left: cols(1);
          width: rem(65);
          height: rem(12);
          background-image: url('../images/interface/interface-insetarrow-up.svg');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;

          @include mq($bp768) {
            left: cols(3);
          }

          @include mq($bp1024) {
            width: rem(100);
            height: rem(18);
          }

          @include mq($bp1800) {
            left: calc(((100vw - 1800px) / 2) + #{rem(112.5)});
          }
        }

        img {
          height: 100%;
          object-fit: cover;
          aspect-ratio: 3 / 2;

          @include mq($bp1024) {
            aspect-ratio: 16 / 9;
            height: auto;
          }

          @include mq($bp1800) {
            max-height: rem(1013);
          }
        }
      }

      .f--description {
        @include padding(null cols(4));
        @include margin(rem(10) null null);

        @include mq($bp1024) {
          @include padding(0);
          @include margin(0);
          position: absolute;
          z-index: 2;
          right: cols(3);
          bottom: rem(15);
          flex: 0 0 cols(38);
        }

        @include mq($bp1800) {
          right: calc(((100vw - 1800px) / 2) + #{rem(112.5)});
        }

        p {
          @include font-size(12, 22);

          @include mq($bp768) {
            @include font-size(14, 24);
          }

          @include mq($bp1024) {
            color: $color-white;
            text-align: right;
          }
        }

        a {
          @extend %font-source-sans-regular;
          color: $color-raleigh-red;
          text-decoration: underline;

          @include mq($bp1024) {
            color: $color-white;

            &:focus {
              outline-color: $color-white;
            }
          }

          @include hover {
            text-decoration: none;
          }
        }
      }

      &::after {
        @include mq($bp1024) {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 30%;
          opacity: 0.65;
          background: linear-gradient(to top, $color-black 0%, transparent 100%);
        }
      }
    }

    .text-container-wrapper {
      @include maxwidth;
    }

    .text-container-inner {
      position: relative;

      @include mq($bp1024) {
        @include padding(rem(25) cols(3) null);
        display: flex;
        justify-content: space-between;

        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: cols(3);
          width: calc(100% - #{cols(6)});
          height: rem(1);
          background-color: $color-nicholson-gray;
        }
      }
    }

    .text-container {
      @include padding(rem(25) cols(4) rem(25));
      display: flex;
      flex-direction: column;
      background-color: $color-white;
      color: $color-ludwell-black;

      @include mq($bp768) {
        @include padding(rem(35) null null);
      }

      @include mq($bp1024) {
        @include padding(rem(20) 0 rem(35));
        flex: 0 0 cols(31, 42);
      }
    }

    .f--eyebrow {
      @include mq($bp768) {
        @include margin(null null rem(20));
      }
    }

    .promo {
      @include margin(rem(10) null null);

      span {
        @extend %font-source-sans-regular;
        @include font-size(18, 28);
        color: $color-ludwell-black;
        letter-spacing: rem(4);
        text-decoration: none;
        text-transform: uppercase;

        @include mq($bp768) {
          @include font-size(22, 32);
          letter-spacing: rem(5);
        }
      }

      .promo-info {
        span {
          &.end-date::before {
            @include margin(null rem(5));
            content: '\2014 ';
            display: inline-block;
          }
        }
      }
    }

    .extra-data {
      @include padding(null cols(4));
      position: relative;

      @include mq($bp1024) {
        @include padding(0 0 rem(50) cols(2, 42));
        display: block;
        flex: 0 0 cols(9, 42);
        border-left: rem(1) solid $color-nicholson-gray;
      }

      &::after,
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: rem(1);
        background-color: $color-nicholson-gray;
      }

      &::before {
        @include mq($bp1024) {
          display: none;
        }
      }

      &::after {
        @include margin(rem(25) null null);

        @include mq($bp768) {
          display: none;
        }
      }

      .copy-url {
        button {
          @extend %font-source-sans-semibold;
          @include padding(0);
          @include font-size(14, 20);
          display: flex;
          align-items: center;
          border: 0;
          background-color: transparent;
          color: $color-raleigh-red;
          text-decoration: none;
          cursor: pointer;

          svg {
            @include margin(null rem(5) null null);
            width: rem(25);
            height: rem(25);

            path {
              fill: $color-raleigh-red;
            }
          }
        }
      }

      span {
        @extend %font-source-sans-regular;
        @include font-size(12, 22);
        @include margin(rem(25) null rem(5));
        display: block;
        color: $color-ludwell-black;
        letter-spacing: rem(1.5);
        text-decoration: none;
        text-transform: uppercase;

        @include mq($bp768) {
          @include font-size(14, 18);
          letter-spacing: rem(1.75);
        }
      }
    }
  }
}

.cc--footer-menu-utility {
  .c--footer-menu-utility {
    ul {
      @include no-bullet;
      display: flex;
      column-gap: rem(20);

      li {
        a {
          @extend %font-source-sans-regular;
          @include font-size(14, 20);
          color: $color-white;
          text-decoration: underline;

          @include hover {
            text-decoration: none;
          }

          &:focus {
            @include outline($color: $color-focus-light);
          }
        }
      }
    }
  }
}

.f--page-title {
  h1 {
    @extend %font-source-serif-semibold;
    @include font-size(40, 48);
    color: $color-ludwell-black;

    @include mq($bp768) {
      @include font-size(64, 70);
    }

    a {
      color: inherit;
      text-decoration: none;

      @include hover {
        text-decoration: underline;
      }
    }
  }
}

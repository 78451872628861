.cc--promo-banner {
  @include maxwidth;

  .c--promo-banner {
    @include padding(rem(25) cols(4));

    @include mq($bp768) {
      @include padding(rem(50) cols(3));
    }

    @include mq($bp1024) {
      @include padding(null cols(2));
    }

    .inner-wrapper {
      @include padding(rem(25) cols(4, 40));
      @include component-box-shadow;

      @include mq($bp768) {
        @include padding(rem(50) cols(3, 42));
      }

      @include mq($bp1024) {
        @include padding(rem(35) cols(4, 44));
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
      }

      &::after {
        @include mq($bp1024) {
          content: '';
          position: absolute;
          top: rem(35);
          left: 50%;
          width: rem(1);
          height: calc(100% - #{rem(70)});
          background-color: $color-nicholson-gray;
        }
      }

      .title-description {
        @include padding(null null rem(20) null);

        @include mq($bp768) {
          @include padding(null null rem(25) null);
        }

        @include mq($bp1024) {
          @include padding(null null 0);
          flex: 0 0 cols(14, 36);
        }

        .f--section-title {
          @include margin(null null rem(5) null);

          @include mq($bp768) {
            @include margin(null null rem(10) null);
          }
        }

        .f--description {
          text-align: center;

          p {
            @include font-size(14, 22);
          }
        }
      }

      .logo-link {
        @include padding(rem(20) null null);

        @include mq($bp768) {
          @include padding(rem(25) null null);
        }

        @include mq($bp1024max) {
          border-top: rem(1) solid $color-nicholson-gray;
        }

        @include mq($bp1024) {
          @include padding(0 null null);
          flex: 0 0 cols(14, 36);
        }

        .f--image {
          img {
            @include margin(0 auto rem(15));

            @include mq($bp768) {
              @include margin(0 auto rem(20));
            }
          }
        }

        .links-container {
          text-align: center;

          .f--link {
            a {
              @include button($outline: false);
            }
          }
        }
      }
    }
  }
}

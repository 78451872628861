.cc--feature-ambient-video {
  @include maxwidth;
  position: relative;
  z-index: 3;

  .c--feature-ambient-video {
    .inner-wrapper {
      @include mq($bp1024) {
        @include padding(null cols(3) null null);
        display: flex;
        column-gap: cols(3, 45);
      }
    }

    .title-container {
      @include padding(null cols(4) rem(30));
      text-align: center;

      @include mq($bp768) {
        @include padding(null 0 rem(25));
      }

      @include mq($bp1024) {
        display: none;
      }

      .f--section-title {
        h2 {
          @include font-size(40, 48);
          color: $color-ludwell-black;

          @include mq($bp768) {
            @include font-size(60, 70);
          }
        }
      }

      .f--sub-title {
        @include margin(rem(5) null null);

        h3 {
          color: $color-ludwell-black;
        }
      }
    }

    .left {
      position: relative;

      @include mq($bp1024) {
        flex: 0 0 cols(24, 45);
      }

      .image-container {
        @include mq($bp1024) {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }

        .f--ambient-video {
          display: none;

          @include mq($bp1024) {
            display: block;
            height: 100%;

            + .f--image {
              display: none;
            }
          }

          video {
            @include mq($bp1024) {
              aspect-ratio: 3 / 4;
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .video-controls {
          top: 0;
          right: 0;
          bottom: auto;
          left: auto;

          .video-button {
            &:focus {
              outline-color: $color-white;
            }
          }
        }

        .f--image {
          aspect-ratio: 3 / 4;
          width: 100%;
          height: 100%;
          background-color: $color-nicholson-gray;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        .accent {
          @include centered(x);
          bottom: rem(-15);
          width: rem(1);
          height: rem(50);
          background-color: $color-york-yellow;

          @include mq($bp768) {
            bottom: rem(-25);
            height: rem(75);
          }

          @include mq($bp1024) {
            display: none;
          }

          &::before {
            @include centered(x);
            content: '';
            bottom: rem(50);
            width: rem(9);
            height: rem(1);
            background-color: $color-york-yellow;

            @include mq($bp768) {
              bottom: rem(75);
            }

            @include mq($bp1024) {
              bottom: rem(20);
              left: 0;
              width: rem(1);
              height: rem(8);
            }
          }

          &::after {
            @include centered(x);
            content: '';
            bottom: rem(-10);
            width: rem(10);
            height: rem(10);
            border-radius: 50%;
            background-color: $color-york-yellow;

            @include mq($bp1024) {
              right: rem(-10);
              bottom: 0;
              left: auto;
            }
          }
        }
      }
    }

    .right {
      @include padding(null cols(4) null);

      @include mq($bp768) {
        @include padding(null 0 null);
      }

      @include mq($bp1024) {
        @include padding(null null rem(150));
        flex: 0 0 cols(18, 45);
        height: 100%;
      }

      .title-container {
        display: none;

        @include mq($bp1024) {
          @include padding(rem(75) null 0);
          display: block;
          position: relative;
        }

        .accent {
          @include mq($bp1024) {
            position: absolute;
            bottom: rem(-75);
            left: cols(-5, 18);
            width: cols(14, 18);
            height: rem(25);
            transform: translateX(0);
            border-top: rem(1) solid $color-york-yellow;
            border-right: rem(1) solid $color-york-yellow;
            background-color: transparent;
          }

          &::before {
            @include centered(x);
            content: '';
            bottom: rem(50);
            width: rem(9);
            height: rem(1);
            background-color: $color-york-yellow;

            @include mq($bp768) {
              bottom: rem(75);
            }

            @include mq($bp1024) {
              bottom: rem(20);
              left: 0;
              width: rem(1);
              height: rem(8);
            }
          }

          &::after {
            @include centered(x);
            content: '';
            bottom: rem(-10);
            width: rem(10);
            height: rem(10);
            border-radius: 50%;
            background-color: $color-york-yellow;

            @include mq($bp1024) {
              right: rem(-10);
              bottom: 0;
              left: auto;
            }
          }
        }
      }

      .text-container {
        @include padding(rem(35) null null);

        @include mq($bp768) {
          @include padding(rem(50) null null);
        }

        @include mq($bp1024) {
          @include padding(rem(100) cols(1, 18) null);
        }

        .f--cta-title {
          h3 {
            @extend %font-source-serif-regular-italic;
            @include font-size(22, 26);
            letter-spacing: rem(8.5);
            text-transform: uppercase;

            @include mq($bp768) {
              @include font-size(26, 30);
              letter-spacing: rem(10);
            }
          }
        }

        .f--description {
          @include margin(rem(5) null null);

          p {
            @include font-size(16, 30);
            letter-spacing: rem(0.2);

            @include mq($bp768) {
              @include font-size(18, 34);
              letter-spacing: rem(0.25);
            }

            &:not(:last-child) {
              @include margin(null null rem(10));
            }
          }
        }

        .f--link {
          @include margin(rem(15) null null);
          display: inline-block;
          position: relative;

          @include mq($bp768) {
            @include margin(rem(25) null null);
          }

          &::before {
            @include mq($bp1024) {
              @include triangle(left, $color-raleigh-red, 8px);
              @include centered(y);
              right: calc(100% + #{rem(25)});
            }
          }

          a {
            @include button(
              $outline: true,
              $hover-bg-color: $color-randolph-burgundy
            );
          }
        }
      }
    }

    .decoration-image {
      position: absolute;
      z-index: -1;
      top: rem(50);
      right: cols(-4);
      width: rem(200);
      height: rem(200);

      @include mq($bp768) {
        top: rem(150);
        width: rem(400);
        height: rem(400);
      }

      @include mq($bp1024) {
        right: cols(-2, 40);
      }
    }
  }

  &.short {
    position: static;

    .c--feature-ambient-video {
      @include padding(null cols(4) null);

      @include mq($bp768) {
        @include padding(null null null 0);
      }

      @include mq($bp1024) {
        @include padding(null 0 null null);
      }

      > .title-container {
        display: none;
      }

      .inner-wrapper {
        position: relative;
        z-index: 2;

        @include mq($bp768) {
          display: flex;
          column-gap: cols(2, 44);
        }

        @include mq($bp1024) {
          @include padding(null 0 null null);
          column-gap: cols(4, 40);
        }

        .left {
          @include mq($bp768) {
            flex: 0 0 cols(23, 44);
          }

          @include mq($bp1024) {
            flex: 0 0 cols(16, 40);
          }

          .image-container {
            .f--image {
              aspect-ratio: 2 / 3;
            }

            video {
              @include mq($bp1024) {
                aspect-ratio: 2 / 3;
              }
            }
          }

          .accent {
            @include mq($bp768) {
              top: rem(35);
              left: cols(21, 23);
              width: rem(100);
              height: rem(1);
              transform: translateX(0);
            }

            @include mq($bp1024) {
              display: block;
              top: rem(75);
              left: calc(100% - #{rem(35)});
              width: cols(15, 16);
              height: rem(25);
              border-top: rem(1) solid $color-york-yellow;
              border-right: rem(1) solid $color-york-yellow;
              background-color: transparent;
            }

            &::before {
              @include mq($bp768) {
                top: rem(-4);
                left: 0;
                width: rem(1);
                height: rem(9);
              }

              @include mq($bp1024) {
                top: rem(-4.5);
              }
            }

            &::after {
              @include mq($bp768) {
                top: rem(-5);
                right: rem(-5);
                left: auto;
              }

              @include mq($bp1024) {
                top: auto;
                right: rem(-10);
                bottom: 0;
              }
            }
          }
        }

        .right {
          @include padding(null 0 null);

          @include mq($bp768) {
            flex: 0 0 cols(19, 44);
          }

          @include mq($bp1024) {
            flex: 0 0 cols(20, 40);
          }

          .title-container {
            @include mq($bp1024) {
              display: none;
            }
          }

          .text-container {
            @include mq($bp768) {
              @include padding(rem(75) null null);
            }

            @include mq($bp1024) {
              @include padding(rem(140) 0 null);
            }
          }
        }
      }
    }
  }
}

@mixin button(
  $outline: false,
  $background-color-default: $color-raleigh-red,
  $text-color: $color-ludwell-black,
  $outline-color: $color-raleigh-red,
  $focus-color: $color-focus,
  $hover-bg-color: null,
  $btn-height-mobile: rem(50),
  $btn-height-desktop: rem(60),
  $btn-height-tablet: rem(60)
) {
  @extend %font-source-serif-semibold-italic;
  @include padding(null rem(30));
  @include font-size(14);
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  min-height: $btn-height-mobile;
  color: inherit;
  letter-spacing: rem(2);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  @content;

  @include mq($bp768) {
    min-height: $btn-height-tablet;
    @include font-size(16);
  }

  @include mq($bp1024) {
    @include padding(null rem(40));
    min-height: $btn-height-desktop;
  }

  @if $outline == true {
    box-shadow: 0 0 0 rem(1.5) inset $outline-color;
    color: $text-color;
    @content;

    @include hover {
      box-shadow: 0 0 0 rem(3) inset $outline-color;
      color: $outline-color;
    }

    &:focus {
      outline: rem(3) solid $focus-color;
      box-shadow: 0 0 0 rem(3) inset $outline-color;
      color: $outline-color;

      @if $hover-bg-color {
        background-color: $hover-bg-color;
        box-shadow: 0 0 0 rem(3) inset $hover-bg-color;
        color: $color-focus-light;

        @include hover {
          background-color: $hover-bg-color;
          box-shadow: 0 0 0 rem(3) inset $hover-bg-color;
          color: $color-focus-light;
        }
      }
    }

  } @else {

    background-color: $background-color-default;
    box-shadow: none;
    color: $color-white;

    &:focus {
      outline: rem(3) solid $focus-color;
      background-color: $color-randolph-burgundy;
      color: $color-white;
    }

    @include hover {
      background-color: $color-randolph-burgundy;
      box-shadow: none;
      color: $color-white;
    }
  }
}


// button cta with the arrow
@mixin button-cta (
  $text-color: $color-ludwell-black,
  $hover-color: $color-raleigh-red,
  $outline-color: $color-ludwell-black,
  $svg-color: $color-raleigh-red
) {
  @extend %font-source-serif-semibold-italic;
  @include font-size(14, 14);
  display: inline-flex;
  align-items: center;
  color: $text-color;
  letter-spacing: rem(1.75);
  text-decoration: none;
  text-transform: uppercase;

  @include mq($bp768) {
    @include font-size(16, 16);
    letter-spacing: rem(2);
  }

  svg {
    @include margin(null null null rem(10));
    width: rem(15);
    height: rem(15);

    @include mq($bp768) {
      width: rem(20);
      height: rem(20);
    }

    path {
      fill: $svg-color;
    }
  }

  &:focus {
    outline: rem(2) solid $outline-color;
    color: $hover-color;
    text-decoration: underline;
  }

  @include hover {
    color: $hover-color;
    text-decoration: underline;
  }
}

// plain text cta
@mixin plain-text-cta () {
  @extend %font-source-sans-regular;
  @include font-size(15, 25);
  color: $color-raleigh-red;
  text-decoration: underline;

  @include mq($bp768) {
    @include font-size(16, 28);
    letter-spacing: rem(0.25);
  }

  :focus {
    text-decoration: none;
  }

  @include hover {
    text-decoration: none;
  }
}

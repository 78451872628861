.cc--footer-menu {
  .c--footer-menu {
    .menu-container {
      nav {
        ul {
          @include no-bullet;
        }
      }
    }
  }

  &.footer-menu {
    .c--footer-menu {
      .menu-container {
        nav {
          ul {

            @include mq($bp768max) {
              display: flex;
              flex-wrap: wrap;
              column-gap: cols(4, 40);
              row-gap: rem(15);
            }

            li {

              @include mq($bp768max) {
                flex: 0 0 cols(18, 40);
              }

              &:not(:last-child) {
                @include mq($bp768) {
                  @include margin(null null rem(15) null);
                }
              }

              a {
                @extend %font-source-sans-semibold;
                @include font-size(16, 28);
                color: $color-white;
                letter-spacing: rem(0.25);
                text-decoration: none;

                @include hover {
                  text-decoration: underline;
                }

                &:focus {
                  @include outline($color: $color-focus-light);
                }
              }

              &:first-child {

                @include mq($bp768max) {
                  flex: 0 0 100%;
                }

                a {
                  @extend %font-source-serif-regular;
                  @include font-size(16, 26);
                  display: flex;
                  align-items: center;

                  @include mq($bp768) {
                    @include font-size(18, 28);
                  }

                  @include hover {
                    color: $color-york-yellow;
                    text-decoration: none;
                  }

                  &::after {
                    @include margin(null null null auto);
                    content: '';
                    width: rem(20);
                    height: rem(20);
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIKCSB5PSIwcHgiIHdpZHRoPSIzNXB4IiBoZWlnaHQ9IjM1cHgiIHZpZXdCb3g9IjAgMCAzNSAzNSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMzUgMzUiIHhtbDpzcGFjZT0icHJlc2VydmUiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaW5ZTWluIHNsaWNlIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiNFM0MyNjgiIGQ9Ik0yNC43LDE3djFsLTEuMywwLjUKCWMtNC44LDItNyw2LTEwLjcsMTAuNUwxMiwyOC43YzAuOC0zLjMsNS4xLTguNSw3LjMtMTEuMlYxN2MtMi4xLTIuNy02LjUtNy40LTcuMy0xMC43TDEyLjgsNmMzLjcsNC40LDUuOSw4LjQsMTAuNywxMC41TDI0LjcsMTd6IgoJLz4KPC9zdmc+Cg==');
                    background-size: 100% 100%;
                    vertical-align: middle;
                  }

                  &:focus {
                    @include outline($color: $color-focus-light);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.footer-menu-quick-links {
    .c--footer-menu {
      .menu-container {
        nav {
          ul {

            @include mq($bp768max) {
              display: flex;
              flex-wrap: wrap;
              column-gap: cols(4, 40);
              row-gap: rem(10);
            }

            li {
              @include mq($bp768max) {
                flex: 0 0 cols(18, 40);
              }

              &:not(:last-child) {
                @include mq($bp768) {
                  @include margin(null null rem(10) null);
                }
              }

              a {
                @extend %font-source-sans-regular;
                @include font-size(14, 22);
                color: $color-white;
                text-decoration: none;

                @include hover {
                  text-decoration: underline;
                }

                &:focus {
                  @include outline($color: $color-focus-light);
                }
              }
            }
          }
        }
      }
    }
  }
}

%input-style {
  @extend %font-source-sans-regular;
  @include padding(null rem(40) null rem(25));
  @include font-size(14, 20);
  position: relative;
  width: 100%;
  height: rem(50);
  border: 0;
  background-color: $color-franklin-white;
  color: $color-ludwell-black;
  appearance: none;

  @include mq($bp1024) {
    @include font-size(16, 24);
    height: rem(65);
  }

  &:focus-visible {
    outline: rem(3) solid $color-focus;
  }

  @include placeholder {
    color: $color-ludwell-black;
  }
}

.cc--card-event {
  @include maxwidth;
  @include margin(rem(25) null rem(25) null);

  .c--card-event {
    @include padding(rem(25) cols(4));

    @include mq($bp768) {
      @include padding(rem(35) cols(4));
    }

    @include mq($bp1024) {
      @include padding(rem(25) cols(3));
    }

    .inner-wrapper {
      @include padding(rem(25) null rem(50) cols(4, 40));
      display: flex;
      position: relative;
      flex-wrap: wrap;
      border-left: rem(1) solid $color-york-yellow;

      @include mq($bp768) {
        @include padding(null null rem(35) cols(2, 40));
      }

      @include mq($bp1024) {
        @include padding(rem(30) null rem(50) cols(1, 42));
        border-bottom: rem(1) solid $color-york-yellow;
      }

      &::before {
        @include mq($bp1024max) {
          content: '';
          position: absolute;
          top: rem(-4);
          left: 0;
          width: cols(30, 40);
          height: rem(8);
          border-right: rem(1) solid $color-york-yellow;
          background-image: linear-gradient(to bottom, transparent 0, transparent rem(4), $color-york-yellow rem(4), $color-york-yellow rem(5), transparent rem(5), transparent rem(8));
        }

        @include mq($bp768max) {
          width: cols(22, 40);
        }
      }

      &::before {
        @include mq($bp1024) {
          content: '';
          position: absolute;
          top: rem(0);
          left: rem(-4);
          width: rem(8);
          height: rem(1);
          background-color: $color-york-yellow;
        }
      }

      &::after {
        @include mq($bp1024max) {
          content: '';
          position: absolute;
          bottom: rem(-4);
          left: 0;
          width: 100%;
          height: rem(8);
          border-right: rem(1) solid $color-york-yellow;
          background-image: linear-gradient(to top, transparent 0, transparent rem(4), $color-york-yellow rem(4), $color-york-yellow rem(5), transparent rem(5), transparent rem(8));
        }
      }

      .date-container {
        @include padding(null null rem(25) null);
        flex: 0 0 cols(18, 36);
        align-content: flex-end;
        order: 1;

        @include mq($bp768) {
          @include padding(null null 0 null);
          flex: 0 0 cols(28, 38);
          align-content: flex-start;
        }

        @include mq($bp1024) {
          @include padding(rem(6) null null null);
          flex: 0 0 cols(6, 41);
          order: 1;
        }

        .date {
          @extend %font-source-serif-regular-italic;
          @include font-size(42, 53);

          @include mq($bp768) {
           @include font-size(52, 65);
          }

          @include mq($bp1024) {
            text-align: center;
          }
        }

        .month {
          @extend %font-source-serif-semibold-italic;
          @include font-size(14, 16);
          letter-spacing: rem(1.75);
          text-transform: uppercase;

          @include mq($bp768) {
           @include font-size(16, 20);
          }

          @include mq($bp1024) {
            text-align: center;
          }
        }
      }

      .text-container {
        @include margin(rem(10) null null null);
        flex: 0 0 100%;
        order: 3;

        @include mq($bp768) {
          @include margin(rem(-24) null null null);
          @include padding(null cols(8, 38) null null);
          position: relative;
        }

        @include mq($bp1024) {
          @include margin(0 null null cols(1, 41));
          @include padding(null 0 null null);
          flex: 0 0 cols(23, 41);
          order: 2;
        }

        .f--eyebrow {
          @include margin(null null rem(10) null);
        }

        .f--cta-title {

          h3 {
            @extend %font-source-serif-regular;
            @include font-size(22, 32);
            color: $color-black;

            @include mq($bp768) {
              @include font-size(26, 36);
            }

            a {
              color: inherit;
              font-family: inherit;
              font-size: inherit;
              font-style: inherit;
              font-weight: inherit;
              font-optical-sizing: inherit;
            }
          }
        }

        .f--description {
          @include margin(rem(5) null null null);

          p {
            @include mq($bp768) {
              @include font-size(16, 28);
              letter-spacing: rem(0.25)
            }
          }
        }

        .meta {
          @include margin(rem(15) null null null);
          display: flex;
          flex-wrap: wrap;
          column-gap: rem(20);
          row-gap: rem(15);

          @include mq($bp768) {
            @include margin(rem(20) null null null);
          }

          @include mq($bp1024) {
            @include margin(rem(25) null null null);
          }

          > div {
            @extend %font-source-sans-semibold;
            @include font-size(12, 16);
            color: $color-ludwell-black;

            svg {
              @include margin(null rem(5) null null);
              width: rem(25);
              height: rem(25);
              vertical-align: middle;
            }
          }
        }

        .tours {
          @include margin(rem(25) null null null);

          h4 {
            @extend %font-source-serif-semibold-italic;
            @include font-size(14, 16);
            color: $color-ludwell-black;
            letter-spacing: rem(2);
            text-transform: uppercase;

            @include mq($bp768) {
              @include font-size(16, 20);

            }
          }

          .links-container {
            @include margin(rem(10) null null null);
            display: flex;
            flex-wrap: wrap;
            column-gap: rem(5);
            row-gap: rem(5);

            .f--link {
              a {
                @include button(
                  $outline: true,
                  $btn-height-mobile: rem(35),
                  $btn-height-desktop: rem(35),
                  $btn-height-tablet: rem(35)
                ) {
                  @extend %font-source-sans-semibold;
                  box-shadow: 0 0 0 rem(1) inset $color-raleigh-red;
                  letter-spacing: 0;
                  text-transform: none;
                };

                @include font-size(12, 16);

                @include mq($bp768) {
                  @include font-size(14);
                }
              }
            }
          }
        }
      }

      .image-container {
        @include margin(rem(-50) null null null);
        @include padding(null null null cols(4, 40));
        flex: 0 0 cols(18, 36);
        order: 2;

        @include mq($bp768) {
          @include padding(null null null cols(2, 38));
          flex: 0 0 cols(10, 38);
        }

        @include mq($bp1024) {
          @include margin(rem(-55) null rem(-75) cols(2, 41));
          @include padding(null null null 0);
          flex: 0 0 cols(9, 41);
          order: 3;
        }

        &.has-add-link {
          @include mq($bp1024) {
            position: relative;
          }

          .add-link-desktop {
            @include mq($bp1024) {
              position: absolute;
              z-index: 2;
              bottom: 0;
              width: 100%;
            }
          }
        }

        .f--image {
          height: 100%;

          img {
            height: 100%;
            object-fit: cover;
            aspect-ratio: 2 / 3;
          }
        }

        .add-link-desktop {

          @include mq($bp1024max) {
            display: none;
          }

          .f--link {
            a {
              @include button(
                $outline: false,
                $btn-height-mobile: rem(50),
                $btn-height-desktop: rem(50),
                $btn-height-tablet: rem(50)
              );
              width: 100%;

              @include mq($bp1024) {
                width: 100%;
              }
            }
          }
        }
      }

      .add-link-mobile-tablet {
        position: absolute;
        z-index: 2;
        bottom: rem(-25);
        left: cols(2, 40);
        width: calc(100% - #{cols(1, 10)});

        @include mq($bp768) {
          width: auto;
        }

        @include mq($bp1024) {
          display: none;
        }

        .f--link {
          a {
            @include button(
              $outline: false,
              $btn-height-mobile: rem(50),
              $btn-height-desktop: rem(50),
              $btn-height-tablet: rem(50)
            );
            width: 100%;

            @include mq($bp768) {
              width: auto;
            }
          }
        }
      }
    }
  }

  &.add-link {
    .c--card-event {
      .inner-wrapper {
        @include mq($bp768-1024) {
          @include padding(null null rem(50) cols(2, 40));
        }
      }
    }
  }

  &.featured {
    .c--card-event {
      .inner-wrapper {
        @include component-box-shadow;
        border: 0;

        @include mq($bp1024) {
          @include padding(null null rem(25) null);
        }

        &::before,
        &::after {
          display: none;
        }

        .text-container {
          @include mq($bp1024max) {
            @include padding(null cols(8, 38) null null);
          }

          @include mq($bp768max) {
            @include padding(null cols(4, 36) null null);
          }
        }

        .image-container {
          @include margin(rem(-25) null null null);

          @include mq($bp1024) {
            @include margin(rem(-30) null rem(-25) null);
          }
        }

      }
    }
  }
}
